import {
    CREATE_REPORTED_ISSUE_REQUEST, 
    CREATE_REPORTED_ISSUE_SUCCESS, 
    CREATE_REPORTED_ISSUE_FAILURE, 
    CREATE_REPORTED_ISSUE_RESET,

} from './SupportTypes'
import axios from 'axios'
export const createReportedIssueReset = () => {
    return {
        type: CREATE_REPORTED_ISSUE_RESET,
    }
}

const createReportedIssueRequest = () => {
    return {
        type: CREATE_REPORTED_ISSUE_REQUEST,
    }
}

const createReportedIssueSuccess = success => {
    return {
        type: CREATE_REPORTED_ISSUE_SUCCESS,
        payload: success
    }
}

const createReportedIssueFailure = error => {
    return {
        type: CREATE_REPORTED_ISSUE_FAILURE,
        payload : error
    }
}

export const createReportedIssue = (data) => {

    return (dispatch) => {

        dispatch(createReportedIssueRequest());
        
        
        
        axios.post('/storeReportedIssue', data)
        .then(response => {
            const reportedissue = response.data
            dispatch(createReportedIssueSuccess(reportedissue))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createReportedIssueFailure(errorMsg))
        })
        
    }

}
