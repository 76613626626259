import React, { useEffect, useRef, useState } from 'react'
import {
    Card,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Modal, 
    ModalBody,
    ModalFooter,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Form,
    Button,
    Badge
  } from "reactstrap";

  
import SchoolCard from 'components/Card/SchoolSearch/SchoolCard';

import { useDispatch } from 'react-redux';
import { fetchSchoolSearch } from 'redux/SchoolSearch/SchoolSearchActions';

import { connect } from 'react-redux';
import Nouislider from "nouislider-react";
import LazyLoad from 'react-lazyload';
import Swal from 'sweetalert2'
import  Select  from 'react-select';
import {customDropdownWithBorder} from "util/reactCustomStyles"
import moment from 'moment';
import Plans from './Plans';
const SchoolSearch = (props) => {

  const dispatch = useDispatch();

  const [modalPrerequisites , setModalPrerequisites] = useState(false);
  


  const togglePrerequisitesModal = () =>{

    setModalPrerequisites(!modalPrerequisites);
    
  } 

  const [modalProgramDetails , setModalProgramDetails] = useState(false);
  
  const toggleProgramDetailsModal = () =>{

    setModalProgramDetails(!modalProgramDetails);
    
  } 

  const [medicalSchoolList, setMedicalSchoolList] = useState([]);

  const [medicalSchoolStateList, setMedicalSchoolStateList] = useState([]);


  const [stateProvinceFilter, setStateProvinceFilter] = useState("");
  const [inStateAnnualTuitionFilter, setInStateAnnualTuitionFilter] = useState("");
  const [tuitionFilter, setTuitionFilter] = useState("in-state");
  const [accreditationFilter, setAccreditationFilter] = useState("")


  const [overallGpaFilter, setOverallGpaFilter] = useState({min:0, max:4});
  const [scienceGpaFilter, setScienceGpaFilter] = useState({min:0, max:4});
 
  const [searchSchoolName, setSearchSchoolName] = useState("");
  

  const [pceHoursFilter, setPceHoursFilter] = useState("");
  const [paShadowFilter, setPaShadowFilter] = useState("");
  const [classStartMonthFilter, setClassStartMonthFilter] = useState("")
  const [applicationDeadlineFilter, setApplicationDeadlineFilter] = useState("")

  const [prerequisitesCreditFilter, setPrerequisitesCreditFilter] = useState({
    "General Chemistry" : 0,
    "Organic Chemistry" : 0,
    "Biochem" : 0,
    "General Biology" : 0,
    "Upper Level Biology" : 0,
    "General Psychology" : 0,
    "Behavioral Science" : 0,
    "English Composition" : 0,
    "Social Science" : 0,
    "Humanities" : 0,
    "Organic or Biochem" : 0,
  })

  const [programDetailFilter, setProgramDetailFilter] = useState([]);


  const [requireCasperFilter, setRequireCasperFilter] = useState(false);
  const [requireGreFilter, setRequireGreFilter] = useState(false);
  const [requirePaCat, setRequirePaCat] = useState(false);
  
  const notAccredited = [
    // "AT-Still-University",
    // "AldersonBroaddusUniversity",
    // "AnneArundelCommunityCollege",
    // "Arcadia-University-Delaware",
    // "Assumption-University",
    // "FrancisMarionUniversity",
    // "HeritageUniversity",
    // "Interservice",
    // "Jefferson-University-New-Jersey",
    // "JeffersonCollegeofHealthSciences(RadfordU)",
    // "KeiserUniversity",
    // "Lenoir-RhyneUniversity",
    // "Oakland-University",
    // "South-College-Atlanta",
    // "TouroCollege-BayShore",
    // "Touro-University-Nassau-University-Medical-Center",
    // "University-of-Washington-Medex-Northwest-Anchorage",
    // "University-of-Washington-Medex-Northwest-Kailua-Kona",
    // "Widener-University"
  ]
  useEffect(() => {

    dispatch(fetchSchoolSearch({userId:props.userDetails.userId}));

  }, []);

  useEffect(() => {
      

    const medicalSchoolListData = props.fetchSchoolSearch.schoolsearch.filter(e => !notAccredited.includes(e.schoolSearchId));
    setMedicalSchoolList(medicalSchoolListData);

    let state = [];

    props.fetchSchoolSearch.schoolsearch.map((value, key)=>{
      state.push(value.overview.state);
    });

    state.sort();
    state = [...new Set(state)];
    // state = Array.from(new Set(state.map((s)=> {return {value:s.name, label: s.name}})));
    let states = [...new Set(state.map((value,index) => {return {value:value, label: value}}))];
    
    states.unshift({value:"" , label : "ALL"})
    setMedicalSchoolStateList(states);
    
  }, [props.fetchSchoolSearch])

  useEffect(async () => {

   
    filterStateProvince(props.fetchSchoolSearch.schoolsearch, stateProvinceFilter)
    
    
  }, [stateProvinceFilter, tuitionFilter, inStateAnnualTuitionFilter, accreditationFilter, pceHoursFilter, paShadowFilter, classStartMonthFilter, applicationDeadlineFilter,    overallGpaFilter, scienceGpaFilter,  searchSchoolName]);
  
  

  
  useEffect(async () => {

   
    filterStateProvince(props.fetchSchoolSearch.schoolsearch, stateProvinceFilter)
    
    
    
  }, [ requireCasperFilter, requireGreFilter, requirePaCat]);

  useEffect(async () => {

   
    filterStateProvince(props.fetchSchoolSearch.schoolsearch, stateProvinceFilter)
    
  }, [ prerequisitesCreditFilter]);
  
  
  const filterStateProvince = (data, filter) => {
    let medicalSchoolListData = data;

    if(filter != ""){

    
      if(filter){
        
        medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.state == filter);
      }
      else{
        medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.state != filter);
      }
    }

    
    filterInStateAnnualTuition(medicalSchoolListData, inStateAnnualTuitionFilter)

    

  }

  const filterInStateAnnualTuition = (data, filter) => {
    let medicalSchoolListData = data;
    
    if(filter != ""){
      if(filter){
        filter = parseInt(filter)
        if(filter == 150001){
          medicalSchoolListData = medicalSchoolListData.filter(e => tuitionFilter == "in-state" ? e.overview.tuitionAndFeesRes >= (filter-1) : typeof e.tuition != "undefined" ? e.tuition.outOfState >= (filter-1) : false);
        }
        else{
          
          if(filter == 50000){
          
            medicalSchoolListData = medicalSchoolListData.filter(e => tuitionFilter == "in-state" ? e.overview.tuitionAndFeesRes < filter : typeof e.tuition != "undefined" ? e.tuition.outOfState < filter : false);
            
          }
          else{
            medicalSchoolListData = medicalSchoolListData.filter(e => (tuitionFilter == "in-state" ? e.overview.tuitionAndFeesRes <= filter : typeof e.tuition != "undefined" ? e.tuition.outOfState <= filter : false) && (tuitionFilter == "in-state" ? e.overview.tuitionAndFeesRes >= (filter - 50000) : typeof e.tuition != "undefined" ? e.tuition.outOfState >= (filter - 50000) : false));
          }
        }
        
      }
    }
    
    
    filterAccreditationStatus(medicalSchoolListData,accreditationFilter);

  }

  const filterAccreditationStatus = (data, filter) => {

    let medicalSchoolListData = data;

    if(filter){
      
      medicalSchoolListData = medicalSchoolListData.filter(e => e.accreditationStatus == filter);
    }
    else{
      medicalSchoolListData = medicalSchoolListData.filter(e => e.accreditationStatus != filter);
    }

    
    filterPrerequisites(medicalSchoolListData, prerequisitesFilter)

  }

  const [prerequisiteFilterFinal , setPrerequisiteFilterFinal] = useState([])
  const filterPrerequisites = (data, filter) => {
    
    
    for (let key in prerequisitesCreditFilter) {
      if (prerequisitesCreditFilter.hasOwnProperty(key)) {
        

        if(prerequisitesCreditFilter[key] >  0) {
          
          filter.push(key)
        }
        else{
          filter = filter.filter(item => item != key)
        }
      }
    }

    
    filter = [...new Set(filter)]
   
    setPrerequisiteFilterFinal(filter)
    // let medicalSchoolListData = data;

    // if(filter.length != 0){ 
    //   // medicalSchoolListData = medicalSchoolListData.filter(e => e.prerequisites.requiredPrerequisites.courses.every(value => filter.includes(value)));
      
      
    //   medicalSchoolListData = medicalSchoolListData.filter(e => filter.every(value => {
          
          
    //       if(doesKeyExist(prerequisitesCreditFilter,value)){

    //         let courseInfo = findObjectByCourseName(e.prerequisites.requiredPrerequisites.coursesInfo, value)

    //         if(typeof courseInfo != "undefined"){

    //             if(typeof courseInfo.credits != "undefined"){

    //               return courseInfo.credits < prerequisitesCreditFilter[value]
  
    //             } 
    //             else{
    //               return 4 < prerequisitesCreditFilter[value]
    //             }
    //         } 
    //         else{
    //             return 4 < prerequisitesCreditFilter[value]
    //         }

    //       }
    //       else{
            
    //         return true;
    //       }

    //   }));

    // }

    

    // filterPCEHours(medicalSchoolListData, pceHoursFilter);

    let medicalSchoolListData = data;
    console.log(selectedRadioOption)
    if (selectedRadioOption == "option1") {
      
        if (filter.length !== 0) {
            medicalSchoolListData = medicalSchoolListData.filter(entry => {
                return filter.every(course => {
                    if (doesKeyExist(prerequisitesCreditFilter, course)) {
                        let courseInfo = findObjectByCourseName(entry.prerequisites.requiredPrerequisites.coursesInfo, course);
                        if (typeof courseInfo !== "undefined") {
                            if (typeof courseInfo.credits !== "undefined") {
                                return courseInfo.credits < prerequisitesCreditFilter[course];
                            } else {
                                return 4 < prerequisitesCreditFilter[course];
                            }
                        } else {
                            return 4 < prerequisitesCreditFilter[course];
                        }
                    } else {
                        return true;
                    }
                });
            });
        }
    } else if (selectedRadioOption == "option2") {
      
        if (filter.length !== 0) {
            medicalSchoolListData = medicalSchoolListData.filter(entry => {
                return !filter.some(course => {
                    if (doesKeyExist(prerequisitesCreditFilter, course)) {
                        let courseInfo = findObjectByCourseName(entry.prerequisites.requiredPrerequisites.coursesInfo, course);
                        if (typeof courseInfo !== "undefined" && typeof courseInfo.credits !== "undefined") {
                            return courseInfo.credits < prerequisitesCreditFilter[course];
                        } else {
                            return 4 < prerequisitesCreditFilter[course];
                        }
                    } else {
                        return false;
                    }
                });
            });
        }
    }
    else{
        if(filter.length != 0){ 
      
          medicalSchoolListData = medicalSchoolListData.filter(e => filter.every(value => {
            
            
            if(doesKeyExist(prerequisitesCreditFilter,value)){

              let courseInfo = findObjectByCourseName(e.prerequisites.requiredPrerequisites.coursesInfo, value)

              if(typeof courseInfo != "undefined"){

                  if(typeof courseInfo.credits != "undefined"){

                    return courseInfo.credits < prerequisitesCreditFilter[value]
    
                  } 
                  else{
                    return 4 < prerequisitesCreditFilter[value]
                  }
              } 
              else{
                  return 4 < prerequisitesCreditFilter[value]
              }

            }
            else{
              
              return true;
            }

          }));

        }
    }

    filterPCEHours(medicalSchoolListData, pceHoursFilter);

    
  }

  function doesKeyExist(arrayOfObjects,key) {
    return arrayOfObjects.hasOwnProperty(key);
  }

  function findObjectByCourseName(arrayOfObjects,courseName) {

    return arrayOfObjects.find(obj => obj.course === courseName);

  }

  const filterPCEHours = (data, filter) => {

    let medicalSchoolListData = data;

    if(filter != ""){
      medicalSchoolListData = medicalSchoolListData.filter(e => e.experienceHours.required <= filter);
    }
    
    
    filterPaShadowingHours(medicalSchoolListData, paShadowFilter);
  }

  const filterPaShadowingHours = (data, filter) => {

    let medicalSchoolListData = data;

    if(filter != ""){
      medicalSchoolListData = medicalSchoolListData.filter(e => e.paShadowing.required <= filter);
    }
    
    filterProgramDetails(medicalSchoolListData, programDetailFilter);
  }


  
  const filterProgramDetails = (data, filter) => {

    let medicalSchoolListData = data;

    if(filter.length != 0){ 
      medicalSchoolListData = medicalSchoolListData.filter(e => filter.some(value => e.programDetails.otherDetails.includes(value)));
    }
    

    filterClassStartMonth(medicalSchoolListData, classStartMonthFilter);
  }

  const filterClassStartMonth = (data, filter) => {

    let medicalSchoolListData = data;
 
    if(filter.length != ""){ 
      medicalSchoolListData = medicalSchoolListData.filter(e => e.programDetails.classStartMonth == filter);
    }
    

    filterApplicationDeadline(medicalSchoolListData, applicationDeadlineFilter);
  }

  const filterApplicationDeadline = (data, filter) => {

    let medicalSchoolListData = data;

    if(filter.length != ""){ 
      medicalSchoolListData = medicalSchoolListData.filter(e => moment(e.applicationInformation["Deadline Date"], 'MM/DD/YYYY').format('MMMM DD, YYYY').includes(filter));
    }

    filterRequireCasper(medicalSchoolListData, requireCasperFilter);
  }

  const filterRequireCasper = (data, filter) => {
    let medicalSchoolListData = data;

    if(filter) {
      
      medicalSchoolListData = medicalSchoolListData.filter(e =>{

        
        if(typeof e.casper != "undefined"){
            if(typeof e.casper.required != "undefined"){
              if(e.casper.required){
                return true
              }
              else{
                return false
              }
            }
            else{
              return false
            }
            
        }
        else{
          return false
        }

      });
    }

    filterRequireGre(medicalSchoolListData, requireGreFilter)
    
  }

  const filterRequireGre = (data, filter) => {
    let medicalSchoolListData = data;

    if(filter) {
      
      medicalSchoolListData = medicalSchoolListData.filter(e =>{

        
        if(typeof e.gre != "undefined"){
            if(typeof e.gre.required != "undefined"){
              if(e.gre.required){
                return true
              }
              else{
                return false
              }
            }
            else{
              return false
            }
            
        }
        else{
          return false
        }

      });
    }

    filterOverallGpa(medicalSchoolListData, overallGpaFilter)
    
  }

  

  const filterOverallGpa = (data, filter) => {
    let medicalSchoolListData = data;
    

    medicalSchoolListData = medicalSchoolListData.filter(e => 
      {
        let gpa = 0;

      if(typeof e.gpa.minimumGpa.overall != "undefined"){
        gpa = e.gpa.minimumGpa.overall
      }

        return parseFloat(gpa) >= parseFloat(filter.min) && parseFloat(gpa) <= parseFloat(filter.max)
    
    });
    
    filterScienceGpa(medicalSchoolListData, scienceGpaFilter)
    
  }

  const filterScienceGpa = (data, filter) => {
    let medicalSchoolListData = data;
    

    medicalSchoolListData = medicalSchoolListData.filter(e => 
      {
        let gpa = 0;

      if(typeof e.gpa.minimumGpa.science != "undefined"){
        gpa = e.gpa.minimumGpa.science
      }

        return parseFloat(gpa) >= parseFloat(filter.min) && parseFloat(gpa) <= parseFloat(filter.max)
    
    });
      

    
    filterRequirePaCat(medicalSchoolListData, requirePaCat)
    
  }

  const filterRequirePaCat = (data, filter) => {
    let medicalSchoolListData = data;

    if(filter) {
      
      medicalSchoolListData = medicalSchoolListData.filter(e =>{

        
        if(typeof e.paCat != "undefined"){
            if(typeof e.paCat.required != "undefined"){
              if(e.paCat.required){
                return true
              }
              else{
                return false
              }
            }
            else{
              return false
            }
            
        }
        else{
          return false
        }

      });
    }

    searchNameOfSchool(medicalSchoolListData, searchSchoolName)
    
  }

  const searchNameOfSchool = (data, filter) => {
    let medicalSchoolListData = data;
    
    
    if(filter){
      medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.schoolName.toLowerCase().includes(filter.toLowerCase()));
    }
    
    medicalSchoolListData = medicalSchoolListData.filter(e => !notAccredited.includes(e.schoolSearchId));
    setMedicalSchoolList(medicalSchoolListData);
  }
  

  

  const getSearchSchoolName = (data) => {
    
    setSearchSchoolName(data.target.value)
  }

  const getStateProvince = (data) => {
    
    setStateProvinceFilter(data.value)
  }

  const getAccreditation = (data) => {
    
    setAccreditationFilter(data.value)
  }


  const getInStateAnnualTuition = (data) => {
    
    setInStateAnnualTuitionFilter(data.value)
  }

  const getTuition = (data) => {
    
    setTuitionFilter(data.value)
  }


  const getClassStartMonth = (data) => {
    
    setClassStartMonthFilter(data.value)
  }

  const getApplicationDeadline = (data) => {
    
    setApplicationDeadlineFilter(data.value)
  }


  const getRequireCasper = (data) => {

    setRequireCasperFilter(!requireCasperFilter)
  

  }

  const getRequirGre = (data) => {

    setRequireGreFilter(!requireGreFilter)
  

  }

  const getRequirPaCat = (data) => {

    setRequirePaCat(!requirePaCat)

  }

  const getOverallGpa = (data) => {
    
    setOverallGpaFilter({min:parseFloat(data[0]),max:parseFloat(data[1])})
  }

  const getScienceGpa = (data) => {
    setScienceGpaFilter({min:parseInt(data[0]),max:parseInt(data[1])})
  }




  useEffect(() => {
    if(props.fetchSchoolSearch.loading){
      
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });
    }
    else{
      Swal.close()
    }
    

  }, [props.fetchSchoolSearch])
  

  const [prerequisitesTabs, setPrerequisitesTabs] = useState(1);
  const [prerequisitesFilter, setPrerequisitesFilter] = useState([]);
  

  const prerequisites = [
    // { id: 1, title: "Math", items: ["Statistics", "Calculus 1", "Algebra", "Precalculus", "Biostatistics"] },
    { id: 1, title: "Math", items: ["Calculus 1"] },


    { id: 2, title: "Biology/Zoology", items: ["General Biology", "Upper Level Biology", "Genetics", "Microbiology", "Anatomy", "Physiology", "Biology 1", "Biology 2", "Pathophysiology", "Immunology",  "Cellular Biology"] },

    { id: 3, title: "English", items: ["English Composition", "Medical Terminology", "English Composition 1", "English 1", "English 2", "English Composition 2", "Literature", "Writing", "Speech Communication"] },

    { id: 4, title: "Biochemistry", items: ["Biochem", "Biochemistry 1"] },

    { id: 5, title: "Physics", items: ["Physics 1"] },

    { id: 6, title: "Inorganic Chemistry", items: ["Chemistry 1", "Chemistry 2"] },

    { id: 7, title: "Organic Chemistry", items: ["Organic Chemistry", "Organic Chemistry 1", "Organic Chemistry 2"] },

    { id: 8, title: "Social/Behavioral Science", items: ["General Psychology", "Behavioral Science", "Social Science", "Developmental Psychology","Abnormal Psychology", "Sociology", "Life Span Development"] },

    // { id: 9, title: "General Chemistry", items: ["General Chemistry"] },
    { id: 9, title: "Humanities", items: ["Humanities"] },

    // { id: 10, title: "Other Science", items: ["Nutrition", "Health"] },

    // { id: 111, title: "Other Non-Science", items: ["Spanish 1", "Spanish 2", "Computers and Computing", "Research Design", "Introduction to Healthcare", "Communications", "American History", "Foreign Languages", "Public Speaking", "Medical Ethics", "Experimental Design", "Ethics"] },
    { id: 111, title: "Other Non-Science", items: ["Ethics"] },

    
  ];

  const programDetails = [
    "Rolling Admissions",
    "Pre-PA Curriculum",
    "High School Entry",
    "Cadaver Lab",
    "Faith Based Learning",
    "Part Time Option Available",
    "Online Program",
    "On Campus Housing Available",
    "International Students Accepted",
    "Military Personal Only",
    "Non-Rolling Admissions"
  ];

  const togglePrerequisitesNavs = (e, index) => {
    e.preventDefault();
    setPrerequisitesTabs(index);
  };

  const hidePrerequisite = ["General Chemistry",
  "Organic Chemistry",
  "Biochem",
  "General Biology",
  "Upper Level Biology",
  "Behavioral Science",
  "English Composition",
  "Social Science",
  "Calculus",
  "English 1", 
  "Writing", 
  "Speech Communication", 
  "Literature",
  "Medical Terminology", 
  "English 2",
  "Organic or Biochem",
  "Physics"
]

const [selectedRadioOption, setSelectedRadioOption] = useState('option1'); // State to hold the selected radio button value

// Function to handle radio button change
const handleRadioChange = (event) => {
  setSelectedRadioOption(event.target.value);

  filterStateProvince(props.fetchSchoolSearch.schoolsearch, stateProvinceFilter)

};

  const handleCheckboxPrerequisitesChange = (prerequisite) => {
    
 

    if (prerequisitesFilter.includes(prerequisite)) {
      



      
      if(prerequisite == "English Composition 1"){
        prerequisite = ["English Composition 1","English 1", "Writing", "Speech Communication", "Literature", "English Composition"]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "English Composition": prevState["English Composition"] - 5
        }))


      }
      else if(prerequisite == "Biochemistry 1"){
        prerequisite = [prerequisite, "Biochem"]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "Biochem": prevState["Biochem"] - 10,
          "Organic or Biochem" : prevState["Organic or Biochem"] - 5
        }))


      }

      else if(prerequisite == "Calculus 1"){
        prerequisite = [prerequisite, "Calculus"]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

      }

      else if(prerequisite == "Physics 1"){
        prerequisite = [prerequisite, "Physics"]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

      }

      else if(prerequisite == "English Composition 2"){
        prerequisite = ["English Composition 2","Medical Terminology", "English 2", "English Composition"]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "English Composition": prevState["English Composition"] - 5
        }))

      }
      else if(prerequisite == "Organic Chemistry 1" ||  prerequisite == "Organic Chemistry 2"){
        
        prerequisite = [prerequisite, "Organic Chemistry"]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "Organic Chemistry": prevState["Organic Chemistry"] - 5,
          "Organic or Biochem" : prevState["Organic or Biochem"] - 5
        }))

      }
      else if(prerequisite == "Chemistry 1" ||  prerequisite == "Chemistry 1"){
        
        prerequisite = [prerequisite, "General Chemistry"]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "General Chemistry": prevState["General Chemistry"] - 5
        }))

      }
      else if(prerequisite == "Biology 1" ||  prerequisite == "Biology 2"){
        
        prerequisite = [prerequisite, "General Biology"]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "General Biology": prevState["General Biology"] - 5
        }))

      }
      else if(prerequisite == "Genetics" ||  prerequisite == "Microbiology"||  prerequisite == "Anatomy"||  prerequisite == "Physiology"||  prerequisite == "Pathophysiology"||  prerequisite == "Immunology"||  prerequisite == "Cellular Biology"){
        
        prerequisite = [prerequisite, "Upper Level Biology"]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "Upper Level Biology": prevState["Upper Level Biology"] - 5
        }))

      }

      else if(prerequisite == "Developmental Psychology" ||  prerequisite == "Sociology"||  prerequisite == "Abnormal Psychology"||  prerequisite == "Life Span Development"){
        
        prerequisite = [prerequisite, "Behavioral Science", "Social Science"]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "Behavioral Science": prevState["Behavioral Science"] - 5,
          "Social Science": prevState["Social Science"] - 5
        }))

      }

      else if(prerequisite == "General Psychology"){
        
        prerequisite = [prerequisite, "General Psychology"]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "General Psychology": prevState["General Psychology"] - 10
        }))

      }

      else if(prerequisite == "Humanities"){
        
        prerequisite = [prerequisite]
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => !prerequisite.includes(item))
        );

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "Humanities": prevState["Humanities"] - 10
        }))

      }


      else{
        setPrerequisitesFilter((prevSelected) =>
          prevSelected.filter((item) => item !== prerequisite)
        );
      }







    } else {





      if(prerequisite == "English Composition 1"){
        prerequisite = ["English Composition 1","English 1", "Writing", "Speech Communication", "Literature", "English Composition"]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "English Composition": prevState["English Composition"] + 5
        }))

      }
      else if(prerequisite == "Biochemistry 1"){
        prerequisite = [prerequisite, "Biochem"]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "Biochem": prevState["Biochem"] + 10,
          "Organic or Biochem" : prevState["Organic or Biochem"] + 5
        }))

      }
      else if(prerequisite == "Calculus 1"){
        prerequisite = [prerequisite, "Calculus"]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

      }
      else if(prerequisite == "Physics 1"){
        prerequisite = [prerequisite, "Physics"]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

      }
      else if(prerequisite == "English Composition 2"){
        prerequisite = ["English Composition 2","Medical Terminology", "English 2", "English Composition"]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "English Composition": prevState["English Composition"] + 5
        }))

      }
      else if(prerequisite == "Organic Chemistry 1" ||  prerequisite == "Organic Chemistry 2"){

        prerequisite = [prerequisite, "Organic Chemistry"]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "Organic Chemistry": prevState["Organic Chemistry"] + 5,
          "Organic or Biochem" : prevState["Organic or Biochem"] + 5
        }))
        
      }
      else if(prerequisite == "Chemistry 1" ||  prerequisite == "Chemistry 2"){

        prerequisite = [prerequisite, "General Chemistry"]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "General Chemistry": prevState["General Chemistry"] + 5
        }))
        
      }
      else if(prerequisite == "Biology 1" ||  prerequisite == "Biology 2"){

        prerequisite = [prerequisite, "General Biology"]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "General Biology": prevState["General Biology"] + 5
        }))
        
      }

      else if(prerequisite == "Genetics" ||  prerequisite == "Microbiology"||  prerequisite == "Anatomy"||  prerequisite == "Physiology"||  prerequisite == "Pathophysiology"||  prerequisite == "Immunology"||  prerequisite == "Cellular Biology"){

        prerequisite = [prerequisite, "Upper Level Biology"]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "Upper Level Biology": prevState["Upper Level Biology"] + 5
        }))
        
      }

      else if(prerequisite == "Developmental Psychology" ||  prerequisite == "Sociology"||  prerequisite == "Abnormal Psychology"||  prerequisite == "Life Span Development"){

        prerequisite = [prerequisite, "Behavioral Science", "Social Science"]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "Social Science": prevState["Social Science"] + 5,
          "Behavioral Science": prevState["Behavioral Science"] + 5
        }))
        
      }

      else if(prerequisite == "General Psychology" ){

        prerequisite = [prerequisite, "General Psychology"]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "General Psychology": prevState["General Psychology"] + 10
        }))
        
      }

      else if(prerequisite == "Humanities" ){

        prerequisite = [prerequisite]
        setPrerequisitesFilter((prevSelected) => prevSelected.concat(prerequisite));

        setPrerequisitesCreditFilter(prevState => ({
          ...prevState,  
          "Humanities": prevState["Humanities"] + 10
        }))
        
      }


      else{
        
        setPrerequisitesFilter((prevSelected) => [...prevSelected, prerequisite]);
      }
      




      
    }

    
    
    
  };

  const handleCheckboxProgramDetailsChange = (programDetail) => {
    
    if (programDetailFilter.includes(programDetail)) {
      
      setProgramDetailFilter((prevSelected) =>
        prevSelected.filter((item) => item !== programDetail)
      );
    } else {
      
      setProgramDetailFilter((prevSelected) => [...prevSelected, programDetail]);
    }
    
    
    
  };

  useEffect(() => {
      
    filterStateProvince(props.fetchSchoolSearch.schoolsearch, stateProvinceFilter)

  }, [prerequisitesFilter])

  useEffect(() => {
      
    filterStateProvince(props.fetchSchoolSearch.schoolsearch, stateProvinceFilter)
    
  }, [programDetailFilter])


  const months = [
    {label : "ALL" , value : ""},
    {label : "January" , value : "January"},
    {label : "February" , value : "February"},
    {label : "March" , value : "March"},
    {label : "April" , value : "April"},
    {label : "May" , value : "May"},
    {label : "June" , value : "June"},
    {label : "July" , value : "July"},
    {label : "August" , value : "August"},
    {label : "September" , value : "September"},
    {label : "October" , value : "October"},
    {label : "November" , value : "November"},
    {label : "December" , value : "December"}
    
  ]

  const arraysContainCommonValue = (arr1, arr2) => {
    
    for (let i = 0; i < arr1.length; i++) {
        if (arr2.includes(arr1[i])) {
            return true; 
        }
    }
    
    return false; 

  }

  return (
    <>
    <Plans></Plans>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              
              <Card className="card-profile shadow mt-4 p-4">
                
                  <Row>
                    
                    <Col md="6"><FormGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Enter Institution Name" type="text" onChange={data => getSearchSchoolName(data)}/>
                        </InputGroup>
                      </FormGroup></Col>
                    <Col md="6">
                      
                    </Col>

                    <Col md="12">
                      <h2 className="mb-2 text-primary">
                        FILTERS
                      </h2>
                    </Col>
                  </Row>

                  
                  <Row>
                  
                    <Col md="12" lg="6" xl="3">
                      <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label text-default" htmlFor="customCheck1">
                              STATE OR PROVINCE
                            </label>

                            <Select 
                              placeholder="Select State or Province" 
                              options={medicalSchoolStateList} 
                              styles={customDropdownWithBorder }  
                              onChange={getStateProvince}
                              menuPortalTarget={document.body}
                              value={medicalSchoolStateList.find((option) => option.value === stateProvinceFilter)}
                            />
                          </FormGroup>
                        </Col>

                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label text-default" htmlFor="customCheck1">
                              TUITION
                            </label>
      

                            <Select 
                              menuPortalTarget={document.body}
                              className='mb-2'
                              placeholder="Select tuition" 
                              options={[
                                { value: "in-state", label: 'IN STATE' },
                                { value: "out-state", label: 'OUT OF STATE' },
                              ]} 
                              styles={customDropdownWithBorder }  
                              onChange={getTuition}
                              value={[
                                { value: "in-state", label: 'IN STATE' },
                                { value: "out-state", label: 'OUT OF STATE' },
                              ].find((option) => option.value === tuitionFilter)}
                            />
                            <Select 
                              menuPortalTarget={document.body}
                              placeholder="Select annual tuition" 
                              options={[
                                { value: '', label: 'ALL' },
                                { value: 50000, label: '< $50,000' },
                                { value: 100000, label: '$50,000 - $100,000' },
                                { value: 150000, label: '$100,000 - $150,000' },
                                { value: 150001, label: '> $150,000' },
                             
                              ]} 
                              styles={customDropdownWithBorder }  
                              onChange={getInStateAnnualTuition}
                              value={[
                                { value: '', label: 'ALL' },
                                { value: 50000, label: '< $50,000' },
                                { value: 100000, label: '$50,000 - $100,000' },
                                { value: 150000, label: '$100,000 - $150,000' },
                                { value: 150001, label: '> $150,000' },
                            
                              ].find((option) => option.value === inStateAnnualTuitionFilter)}
                            />

                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label text-default" htmlFor="customCheck1">
                              ACCREDITATION STATUS
                            </label>
          

                            <Select 
                              menuPortalTarget={document.body}
                              placeholder="Select Accreditation Status" 
                              options={[
                                { value: '', label: 'ALL' },
                                { value: 'Continued', label: 'Continued' },
                                { value: 'Provisional', label: 'Provisional' },
                                { value: 'Probationary', label: 'Probationary' },
                                { value: 'Administrative Probation', label: 'Administrative Probation' },
                                { value: 'Developing-Not Accredited', label: 'Developing-Not Accredited' },
                              ]} 
                              styles={customDropdownWithBorder }  
                              onChange={getAccreditation}
                              value={[
                                { value: '', label: 'ALL' },
                                { value: 'Continued', label: 'Continued' },
                                { value: 'Provisional', label: 'Provisional' },
                                { value: 'Probationary', label: 'Probationary' },
                                { value: 'Administrative Probation', label: 'Administrative Probation' },
                                { value: 'Developing-Not Accredited', label: 'Developing-Not Accredited' },
                              ].find((option) => option.value === accreditationFilter)}
                            />

                          </FormGroup>
                        </Col>
                        
                      </Row>
                      </div>
                    </Col>

                    <Col md="12" lg="6" xl="3">
                      <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label text-default" htmlFor="customCheck1">
                              PREREQUISITES
                            </label>

                          <button className='btn btn-info form-control' onClick={togglePrerequisitesModal}>SELECT</button>

                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label text-default" htmlFor="customCheck1">
                              PCE HOURS
                            </label>

                            <Input
                              className="form-control"
                              id="pceHours"
                              name="pceHours"
                              onChange={e => setPceHoursFilter(e.target.value)}
                              type="number"
                            />

                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label text-default" htmlFor="customCheck1">
                              PA SHADOWING HOURS
                            </label>

                            <Input
                              className="form-control"
                              id="paShadow"
                              name="paShadow"
                              onChange={e => setPaShadowFilter(e.target.value)}
                              type="number"
                            />    

                          </FormGroup>
                        </Col>
                        
                        
                        
                      </Row>
                      </div>
                    </Col>

                    <Col md="12" lg="6" xl="3">
                      <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label text-default" htmlFor="customCheck1">
                              APPLICATION DEADLINE
                            </label>

                            <Select 
                              menuPortalTarget={document.body}
                              placeholder="Select In-state annual tuition" 
                              options={months} 
                              styles={customDropdownWithBorder }  
                              onChange={getApplicationDeadline}
                              value={months.find((option) => option.value === applicationDeadlineFilter)}
                            />

                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label text-default" htmlFor="customCheck1">
                              CLASS START MONTH
                            </label>

                            <Select 
                              menuPortalTarget={document.body}
                              placeholder="Select Class Start Month" 
                              options={months} 
                              styles={customDropdownWithBorder }  
                              onChange={getClassStartMonth}
                              value={months.find((option) => option.value === classStartMonthFilter)}
                            />

                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label text-default" htmlFor="customCheck1">
                              PROGRAM DETAILS
                            </label>

                            <button className='btn btn-info form-control' onClick={toggleProgramDetailsModal}>SELECT</button>

                          </FormGroup>
                        </Col>

                      </Row>
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label text-default"
                            htmlFor="median-gpa"
                          >
                            OVERALL GPA <span className='text-warning'>&nbsp;{parseFloat(overallGpaFilter.min)} - {parseFloat(overallGpaFilter.max)}</span>
                          </label>
                          <Nouislider range={{ min: 0, max: 4 }} start={[0, 4]} connect  onSlide={(data) => getOverallGpa(data)}/>
                        </Col>
                        <Col md="12">
                          <label
                            className="form-control-label text-default"
                            htmlFor="median-mcat"
                          >
                            SCIENCE GPA <span className='text-warning'>&nbsp;{parseInt(scienceGpaFilter.min)} - {parseInt(scienceGpaFilter.max)}</span>
                          </label>
                          <Nouislider range={{ min: 0, max: 4 }} start={[0, 4]} connect onSlide={(data) => getScienceGpa(data)} />
                        </Col>

                        <Col lg="12">
                          <label
                              className="form-control-label text-default"
                              htmlFor="median-gpa"
                            >
                              EXAMS
                            </label>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="gre-require"
                                type="checkbox"
                                value="Y"
                                onChange={(data) => getRequirGre(data)}
                              />
                              <label className="custom-control-label form-control-label" htmlFor="gre-require">
                                GRE
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="pa-cat-require"
                                type="checkbox"
                                value="Y"
                                onChange={(data) => getRequirPaCat(data)}
                              />
                              <label className="custom-control-label form-control-label" htmlFor="pa-cat-require">
                                PA-CAT
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="casper-require"
                                type="checkbox"
                                value="Y"
                                onChange={(data) => getRequireCasper(data)}
                              />
                              <label className="custom-control-label form-control-label" htmlFor="casper-require">
                                CASPER
                              </label>
                            </div>
                        </Col>
                      </Row>
                      </div>
                    </Col>
                  </Row>
                
              </Card>
            </div>
          </Container>
          <Container fluid className='mt-3 text-center'>
            <h2 className='text-secondary'>PA Schools - {medicalSchoolList.length} results</h2>
            <Row>
              <Col md="3"></Col>
              <Col md="6">
                
                  {prerequisiteFilterFinal.length ?
                    <h2 className='text-secondary'>Selected Prerequisites : {[...new Set(prerequisitesFilter)].map((value, index) => (
                        hidePrerequisite.includes(value) ? "" : <Badge color="primary" className='mr-1 text-md' key={index}>{value}&nbsp;&nbsp;<i class="fas fa-times text-danger" role="button" onClick={e => handleCheckboxPrerequisitesChange(value)}></i></Badge>
                      
                    ))}</h2> : ""
                  }
              </Col>
              <Col md="3"></Col>
              
              <Col md="3"></Col>
              <Col md="6">
                  {programDetailFilter.length ?
                    <h2 className='text-secondary'>Selected Program Details : {programDetailFilter.map((value, index) => (
                      <Badge color="primary" className='mr-1' key={index}>{value}&nbsp;&nbsp;<i class="fas fa-times text-danger" role="button" onClick={e => handleCheckboxProgramDetailsChange(value)}></i></Badge>
                    ))}</h2> : ""
                  }
              </Col>
              <Col md="3"></Col>

            </Row>
            {/* <h3 className='text-secondary'>Click on a school name to view more details.</h3> */}
          </Container>
          
        </div>
        
        <Container className="mt--7" fluid>
        
            {
              medicalSchoolList.sort((a, b) => {
                const nameA = a.overview.schoolName.toLowerCase();
                const nameB = b.overview.schoolName.toLowerCase();
              
                if (nameA < nameB) {
                  return -1; // a should be placed before b
                }
                if (nameA > nameB) {
                  return 1; // a should be placed after b
                }
                return 0; // names are equal, no change in order
              })
              .map((value, key)=>{
                return (<LazyLoad key={key} height={100} >
                  <SchoolCard key={key+"1"} medicalSchoolDetails={value} placeholder={<h4>Loading...</h4>}></SchoolCard>
                </LazyLoad>)
                // return 
              })
            }
           
        </Container>

        <Modal className="modal-dialog-centered" size="lg" isOpen={modalPrerequisites}  toggle={togglePrerequisitesModal}>
                
            <ModalBody className='modal-body p-0'>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">SELECT PREREQUISITES</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            
                        </Col>
                        </Row>
                    </CardHeader>
                    <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                        
                        <div>
                            <Row>
                                <Col lg="12">
                                <Row>
                                  <Col lg="4">
                                    <div className="custom-control custom-radio mb-3">
                                      <input
                                        className="custom-control-input"
                                        id="prerequisitesRadioFilter1"
                                        name="custom-radio"
                                        type="radio"
                                        value="option1"
                                        checked={selectedRadioOption === "option1"}
                                        onChange={handleRadioChange}
                                      />
                                      <label className="custom-control-label d-flex align-items-center" htmlFor="prerequisitesRadioFilter1">
                                        Include Courses
                                      </label>
                                    </div>
                                  </Col>
                                  <Col lg="4">
                                    <div className="custom-control custom-radio mb-3">
                                      <input
                                        className="custom-control-input"
                                        id="prerequisitesRadioFilter2"
                                        name="custom-radio"
                                        type="radio"
                                        value="option2"
                                        checked={selectedRadioOption === "option2"}
                                        onChange={handleRadioChange}
                                      />
                                      <label className="custom-control-label d-flex align-items-center" htmlFor="prerequisitesRadioFilter2">
                                        Exclude Courses
                                      </label>
                                    </div>
                                  </Col>
                                  <Col lg="4">
                                    {/* <div className="custom-control custom-radio mb-3">
                                      <input
                                        className="custom-control-input"
                                        id="prerequisitesRadioFilter3"
                                        name="custom-radio"
                                        type="radio"
                                        value="option3"
                                        checked={selectedRadioOption === "option3"}
                                        onChange={handleRadioChange}
                                      />
                                      <label className="custom-control-label d-flex align-items-center" htmlFor="prerequisitesRadioFilter3">
                                        None
                                      </label>
                                    </div> */}
                                  </Col>
                                </Row>
                                  
                                </Col>
                                <Col lg="12">
                                <div className="nav-wrapper">
                                  <Nav
                                    className="nav-fill  row"
                                    id="tabs-icons-text"
                                    pills
                                    role="tablist"
                                  >
                                    {prerequisites.map((prerequisite) => (
                                      <div key={prerequisite.id} className='col-6'>
                                        <NavItem  className='pr-0'>
                                          <NavLink
                                            aria-selected={prerequisitesTabs === prerequisite.id}
                                            className={`mb-3  ${prerequisitesTabs === prerequisite.id ? 'active' : arraysContainCommonValue(prerequisite.items,prerequisitesFilter) ? "bg-warning text-white":""}`}
                                            onClick={(e) => togglePrerequisitesNavs(e, prerequisite.id)}
                                            href="#pablo"
                                            role="tab"
                                          >
                                            
                                            {prerequisite.title}
                                          </NavLink>
                                        </NavItem>
                                      </div>
                                    ))}
                                  </Nav>
                                </div>
                                <Card className="shadow">
                                  <CardBody>
                                    <TabContent activeTab={`tabs${prerequisitesTabs}`}>
                                      {prerequisites.map((prerequisite, prerequisitesIndex) => (
                                        <TabPane key={prerequisite.id} tabId={`tabs${prerequisite.id}`}>
                                          <Form>
                                            <Row>
                                              {/* {prerequisite.title == "General Chemistry" && 
                                                <>
                                                  <Col md="6" sm="6"  className='d-flex align-items-center justify-content-center'>
                                                      <div className="custom-control custom-checkbox mb-3">
                                                        <input
                                                          id = {"General Chemistry"}
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          checked={prerequisitesFilter.includes("General Chemistry") || false}
                                                          onChange={() => handleCheckboxPrerequisitesChange("General Chemistry")}
                                                        />
                                                        <label className="custom-control-label form-control-label" htmlFor={"General Chemistry"}>
                                                          {"General Chemistry"}
                                                        </label>
                                                      </div>

                                                    
                                                  </Col>
                                                  <Col md="6" sm="6" >
                                                    <div className="form-group">
                                                      <input
                                                        id = {"General Chemistry"}
                                                        placeholder='General Chemistry Total Credits'
                                                        className="form-control"
                                                        type="number"
                                                        defaultValue={prerequisitesCreditFilter["General Chemistry"]}
                                                        onChange={(e) => setPrerequisitesCreditFilter(prevState => ({
                                                          ...prevState,  
                                                          "General Chemistry": e.target.value
                                                        }))}
                                                      />
                                                    </div>
                                                  </Col>
                                                  
                                                </>
                                              } */}

                                              {/* {prerequisite.title == "Organic Chemistry" && 
                                                <>
                                                  <Col md="6" sm="6"  className='d-flex align-items-center'>
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          id = {"Organic Chemistry"}
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          
                                                          checked={prerequisitesFilter.includes("Organic Chemistry") || false}
                                                          onChange={() => handleCheckboxPrerequisitesChange("Organic Chemistry")}
                                                        />
                                                        <label className="custom-control-label form-control-label" htmlFor={"Organic Chemistry"}>
                                                          {"Organic Chemistry"}
                                                        </label>
                                                      </div>

                                                    
                                                  </Col>
                                                  <Col md="6" sm="6" >
                                                    <div className="form-group mb-0">
                                                      <input
                                                        id = {"Organic Chemistry"}
                                                        placeholder='Organic Chemistry Total Credits'
                                                        className="form-control"
                                                        type="number"
                                                        defaultValue={prerequisitesCreditFilter["Organic Chemistry"]}
                                                        onChange={(e) => setPrerequisitesCreditFilter(prevState => ({
                                                          ...prevState,  
                                                          "Organic Chemistry": e.target.value
                                                        }))}
                                                      />
                                                    </div>
                                                    
                                                  </Col>
                                                  <Col md="12 mt-1">
                                                    <p className='text-sm font-weight-bold mb-0 text-danger'>Note : Uncheck subjects below that belong to the categories checked above.</p>
                                                  </Col>
                                                  <Col md="12">
                                                    <hr className='my-4'/>
                                                  </Col>
                                                </>
                                              } */}

                                              {/* {prerequisite.title == "Biochemistry" && 
                                                <>
                                                  <Col md="6" sm="6"  className='d-flex align-items-center'>
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          id = {"Biochemistry"}
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          
                                                          checked={prerequisitesFilter.includes("Biochem") || false}
                                                          onChange={() => handleCheckboxPrerequisitesChange("Biochem")}
                                                        />
                                                        <label className="custom-control-label form-control-label" htmlFor={"Biochemistry"}>
                                                          {"Biochemistry"}
                                                        </label>
                                                      </div>

                                                    
                                                  </Col>
                                                  <Col md="6" sm="6" >
                                                    <div className="form-group mb-0">
                                                      <input
                                                        id = {"Biochemistry"}
                                                        placeholder='Biochemistry Total Credits'
                                                        className="form-control"
                                                        type="number"
                                                        defaultValue={prerequisitesCreditFilter["Biochem"]}
                                                        onChange={(e) => setPrerequisitesCreditFilter(prevState => ({
                                                          ...prevState,  
                                                          "Biochem": e.target.value
                                                        }))}
                                                      />
                                                    </div>
                                                    
                                                  </Col>
                                                  <Col md="12 mt-1">
                                                    <p className='text-sm font-weight-bold mb-0 text-danger'>Note : Uncheck subjects below that belong to the categories checked above.</p>
                                                  </Col>
                                                  <Col md="12">
                                                    <hr className='my-4'/>
                                                  </Col>
                                                </>
                                              } */}
                                              
                                              {/* {prerequisite.title == "English" && 
                                                <>

                                                  <Col md="6" sm="6"  className='d-flex align-items-center'>
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          id = {"English Composition"}
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          
                                                          checked={prerequisitesFilter.includes("English Composition") || false}
                                                          onChange={() => handleCheckboxPrerequisitesChange("English Composition")}
                                                        />
                                                        <label className="custom-control-label form-control-label" htmlFor={"English Composition"}>
                                                          {"English Composition"}
                                                        </label>
                                                      </div>

                                                    
                                                  </Col>
                                                  <Col md="6" sm="6" >
                                                    <div className="form-group mb-0 ">
                                                      <input
                                                        id = {"English Composition"}
                                                        placeholder='English Composition Total Credits'
                                                        className="form-control"
                                                        type="number"
                                                        defaultValue={prerequisitesCreditFilter["English Composition"]}
                                                        onChange={(e) => setPrerequisitesCreditFilter(prevState => ({
                                                          ...prevState,  
                                                          "English Composition": e.target.value
                                                        }))}
                                                      />
                                                    </div>
                                                    
                                                  </Col>
                                                  <Col md="12 mt-1">
                                                    <p className='text-sm font-weight-bold mb-0 text-danger'>Note : Uncheck subjects below that belong to the categories checked above.</p>
                                                  </Col>

                                                  <Col md="12">
                                                    <hr className='my-4'/>
                                                  </Col>
                                                  
                                                  

                                                </>
                                              } */}

                                              {/* {prerequisite.title == "Humanities" && 
                                                <>
                                                 
                                                  <Col md="6" sm="6"  className='d-flex align-items-center justify-content-center'>
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          id = {"Humanities"}
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          
                                                          checked={prerequisitesFilter.includes("Humanities") || false}
                                                          onChange={() => handleCheckboxPrerequisitesChange("Humanities")}
                                                        />
                                                        <label className="custom-control-label form-control-label" htmlFor={"Humanities"}>
                                                          {"Humanities"}
                                                        </label>
                                                      </div>

                                                    
                                                  </Col>
                                                  <Col md="6" sm="6" >
                                                    <div className="form-group mb-0 ">
                                                      <input
                                                        id = {"Humanities"}
                                                        placeholder='Humanities Total Credits'
                                                        className="form-control"
                                                        type="number"
                                                        defaultValue={prerequisitesCreditFilter["Humanities"]}
                                                        onChange={(e) => setPrerequisitesCreditFilter(prevState => ({
                                                          ...prevState,  
                                                          "Humanities": e.target.value
                                                        }))}
                                                      />
                                                    </div>
                                                    
                                                  </Col>

                                                                    

                                                 
                                                </>
                                              } */}

                                              {/* {prerequisite.title == "Social/Behavioral Science" && 
                                                <>

                                                  <Col md="6" sm="6"  className='d-flex align-items-center'>
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          id = {"Behavioral Science"}
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          checked={prerequisitesFilter.includes("Behavioral Science") || false}
                                                          onChange={() => handleCheckboxPrerequisitesChange("Behavioral Science")}
                                                        />
                                                        <label className="custom-control-label form-control-label" htmlFor={"Behavioral Science"}>
                                                          {"Behavioral Science"}
                                                        </label>
                                                      </div>

                                                    
                                                  </Col>
                                                  <Col md="6" sm="6" className='mb-2' >
                                                    <div className="form-group mb-0 ">
                                                      <input
                                                        id = {"Behavioral Science"}
                                                        placeholder='Behavioral Science Total Credits'
                                                        className="form-control"
                                                        type="number"
                                                        defaultValue={prerequisitesCreditFilter["Behavioral Science"]}
                                                        onChange={(e) => setPrerequisitesCreditFilter(prevState => ({
                                                          ...prevState,  
                                                          "Behavioral Science": e.target.value
                                                        }))}
                                                      />
                                                    </div>
                                                    
                                                  </Col>

                                                  <Col md="6" sm="6"  className='d-flex align-items-center'>
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          id = {"Social Science"}
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          checked={prerequisitesFilter.includes("Social Science") || false}
                                                          onChange={() => handleCheckboxPrerequisitesChange("Social Science")}
                                                        />
                                                        <label className="custom-control-label form-control-label" htmlFor={"Social Science"}>
                                                          {"Social Science"}
                                                        </label>
                                                      </div>

                                                    
                                                  </Col>
                                                  <Col md="6" sm="6" className='mb-2'>
                                                    <div className="form-group mb-0">
                                                      <input
                                                        id = {"Social Science"}
                                                        placeholder='Social Science Total Credits'
                                                        className="form-control"
                                                        type="number"
                                                        defaultValue={prerequisitesCreditFilter["Social Science"]}
                                                        onChange={(e) => setPrerequisitesCreditFilter(prevState => ({
                                                          ...prevState,  
                                                          "Social Science": e.target.value
                                                        }))}
                                                      />
                                                    </div>
                                                    
                                                  </Col>


                                                  <Col md="6" sm="6"  className='d-flex align-items-center'>
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          id = {"General Psychology"}
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          checked={prerequisitesFilter.includes("General Psychology") || false}
                                                          onChange={() => handleCheckboxPrerequisitesChange("General Psychology")}
                                                        />
                                                        <label className="custom-control-label form-control-label" htmlFor={"General Psychology"}>
                                                          {"General Psychology"}
                                                        </label>
                                                      </div>

                                                    
                                                  </Col>
                                                  <Col md="6" sm="6" >
                                                    <div className="form-group mb-0">
                                                      <input
                                                        id = {"General Psychology"}
                                                        placeholder='General Psychology Total Credits'
                                                        className="form-control"
                                                        type="number"
                                                        defaultValue={prerequisitesCreditFilter["General Psychology"]}
                                                        onChange={(e) => setPrerequisitesCreditFilter(prevState => ({
                                                          ...prevState,  
                                                          "General Psychology": e.target.value
                                                        }))}
                                                      />
                                                    </div>
                                                    
                                                  </Col>
                                                  
                                                  <Col md="12 mt-1">
                                                    <p className='text-sm font-weight-bold mb-0 text-danger'>Note : Uncheck subjects below that belong to the categories checked above.</p>
                                                  </Col>
                                                  
                                                  <Col md="12">
                                                    <hr className='my-4'/>
                                                  </Col>
                                                </>
                                              } */}
                                              
                                              
                                              {/* {prerequisite.title == "Biology/Zoology" && 
                                                <>
                                                  <Col md="6" sm="6"  className='d-flex align-items-center'>
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          id = {"General Biology"}
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          checked={prerequisitesFilter.includes("General Biology") || false}
                                                          onChange={() => handleCheckboxPrerequisitesChange("General Biology")}
                                                        />
                                                        <label className="custom-control-label form-control-label" htmlFor={"General Biology"}>
                                                          {"General Biology"}
                                                        </label>
                                                      </div>

                                                    
                                                  </Col>
                                                  <Col md="6" sm="6" className='mb-2'>
                                                    <div className="form-group mb-0">
                                                      <input
                                                        id = {"General Biology"}
                                                        placeholder='General Biology Total Credits'
                                                        className="form-control"
                                                        type="number"
                                                        defaultValue={prerequisitesCreditFilter["General Biology"]}
                                                        onChange={(e) => setPrerequisitesCreditFilter(prevState => ({
                                                          ...prevState,  
                                                          "General Biology": e.target.value
                                                        }))}
                                                      />
                                                    </div>
                                                    
                                                  </Col>

                                                  
                                                  <Col md="6" sm="6"  className='d-flex align-items-center'>
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          id = {"Upper Level Biology"}
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          checked={prerequisitesFilter.includes("Upper Level Biology") || false}
                                                          onChange={() => handleCheckboxPrerequisitesChange("Upper Level Biology")}
                                                        />
                                                        <label className="custom-control-label form-control-label" htmlFor={"Upper Level Biology"}>
                                                          {"Upper Level Biology"}
                                                        </label>
                                                      </div>

                                                    
                                                  </Col>
                                                  <Col md="6" sm="6">
                                                    <div className="form-group mb-0">
                                                      <input
                                                        id = {"Upper Level Biology"}
                                                        placeholder='Upper Level Biology Total Credits'
                                                        className="form-control"
                                                        type="number"
                                                        defaultValue={prerequisitesCreditFilter["Upper Level Biology"]}
                                                        onChange={(e) => setPrerequisitesCreditFilter(prevState => ({
                                                          ...prevState,  
                                                          "Upper Level Biology": e.target.value
                                                        }))}
                                                      />
                                                    </div>
                                                    
                                                  </Col>

                                                  <Col md="12 mt-1">
                                                    <p className='text-sm font-weight-bold mb-0 text-danger'>Note : Uncheck subjects below that belong to the categories checked above.</p>
                                                  </Col>
                                                        
                                                  <Col md="12">
                                                    <hr className='my-4'/>
                                                  </Col>
                                                </>
                                              } */}

                                              {prerequisite.items.map((item, itemIndex) => (
                                                <>
                                                  {
                                                    hidePrerequisite.includes(item)
                                                    
                                                    ? "" :
                                                    <Col md="6" sm="6" key={itemIndex}>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                          <input
                                                            id = {item}
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            checked={prerequisitesFilter.includes(item) || false}
                                                            onChange={() => handleCheckboxPrerequisitesChange(item)}
                                                          />
                                                          <label className="custom-control-label form-control-label" htmlFor={item}>
                                                            {item == "Anatomy" || item == "Physiology" ? "Human " + item : item}
                                                          </label>
                                                        </div>

                                                    </Col>
                                                  }
                                                </>
                                              ))}
                                            </Row>
                                          </Form>
                                        </TabPane>
                                      ))}
                                    </TabContent>
                                  </CardBody>
                                </Card>
                              
                                </Col>
                            </Row>
                        </div>  
                      
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
              <Button color="info" onClick={togglePrerequisitesModal}>
                Done
              </Button>
            </ModalFooter>
            
        </Modal>

        <Modal className="modal-dialog-centered" size="lg" isOpen={modalProgramDetails}  toggle={toggleProgramDetailsModal}>
                
            <ModalBody className='modal-body p-0'>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">SELECT PROGRAM DETAILS</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            
                        </Col>
                        </Row>
                    </CardHeader>
                    <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                        <div>
                            <Row>
                                <Col lg="12">
                                  <Card className="shadow">
                                    <CardBody>
                                      <Row>
                                        {programDetails.map((item, itemIndex) => (

                                          
                                            <Col md="6" key={itemIndex}>
                                                
                                                <div className="custom-control custom-checkbox mb-3">
                                                  <input
                                                    id = {item}
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    checked={programDetailFilter.includes(item) || false}
                                                    onChange={() => handleCheckboxProgramDetailsChange(item)}
                                                  />
                                                  <label className="custom-control-label form-control-label" htmlFor={item}>
                                                    {item}
                                                  </label>
                                                </div>

                                            </Col>
                                          
                                        
                                        ))}
                                      </Row>
                                    </CardBody>
                                  </Card>                           
                                </Col>
                            </Row>
                        </div>  
                      
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
              <Button color="info" onClick={toggleProgramDetailsModal}>
                Done
              </Button>
            </ModalFooter>
            
        </Modal>
        
    </>
  )
}

const mapStateToProps = state  => {
  return {
      fetchSchoolSearch : state.fetchSchoolSearch,
      userDetails : state.loginAuth.userDetails,
      
  }
    
}

export default connect(mapStateToProps)(SchoolSearch);
