
import { FETCH_GRADE_RESET, FETCH_GRADE_REQUEST, FETCH_GRADE_SUCCESS, FETCH_GRADE_FAILURE } from "./GradesTypes"
const initialFetchGradeState = {
    loading: false,
    grade: [],
}

export const fetchGradeReducer = (state = initialFetchGradeState, action) => {
    switch (action.type) {
        case FETCH_GRADE_RESET:
            return {
                loading: false,
                grade: [],
            }
        case FETCH_GRADE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GRADE_SUCCESS:
            return {
                loading: false,
                grade: [action.payload]
            }
        case FETCH_GRADE_FAILURE:
            return {
                loading: false,
                grade: [],
                error: action.payload
            }
        default: return state;
    }
}