import { OPEN_FORGOT_PASSWORD_MODAL, CLOSE_FORGOT_PASSWORD_MODAL, FORGOT_PASSWORD_RESET, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE } from "./ForgotPasswordTypes"
import axios from "axios";

const openCourseModal = status => {
    return {
        type: OPEN_FORGOT_PASSWORD_MODAL,
        payload : status
    }
}

const closeCourseModal = status => {
    return {
        type: CLOSE_FORGOT_PASSWORD_MODAL,
        payload : status
    }
}



const forgotPasswordRequest = () => {
    return {
        type: FORGOT_PASSWORD_REQUEST,
    }
}

export const forgotPassword = (userData) => (dispatch) => {

    dispatch(forgotPasswordRequest());
    axios.post('/forgotPassword', userData)
        .then(res => {
            dispatch({
                type: FORGOT_PASSWORD_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: FORGOT_PASSWORD_FAILURE,
                payload: err.response.data
            })
        })
}


export const forgotPasswordReset = () => {
    return {
        type: FORGOT_PASSWORD_RESET,
    }
}

export const updateForgotPassword = (data) => {

    return (dispatch) => {
        if (data) {

            dispatch(openCourseModal(data))

        } 
        else{

            dispatch(closeCourseModal(data))

        } 
    }

}