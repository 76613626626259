import React, {useEffect, useState, useRef} from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Modal, 
    ModalBody,
    ModalFooter,
    Badge
} from "reactstrap";
import Swal from 'sweetalert2'; 
import {useForm} from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { editTutorRequest,createStrUpload,createStrUploadReset } from 'redux/ManageTutorRequests/ManageTutorRequestsActions';


const UploadTutorVideoFileModal = (props) => {

    const dispatch = useDispatch();
    const fileInputRef = useRef(null)

    const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();

    let modalUploadTutorVideoFile = props.modalUploadTutorVideoFile;
    let toggleUploadTutorVideoFile = props.toggleUploadTutorVideoFile;

    const [meetingId, setMeetingId] = useState('');
    const [strDetails, setStrDetails] = useState('');

    useEffect(() => {

        if (props.editTutorRequest.status) { 
            const requestDetails = findRequest(props.fetchStudentRequestTutor, props.editTutorRequest.meetingId);
              console.log(requestDetails)
            setMeetingId(props.editTutorRequest.meetingId);
            setStrDetails(requestDetails)

            // setValue("video", requestDetails.video)
        }
    })

    useEffect(() => {
        if(props.createStrUpload.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
        
        }
        else{

            if (props.createStrUpload.strUpload.length && typeof props.createStrUpload.error === "undefined" && props.createStrUpload.done) {
                
                console.log(props.createStrUpload.strUpload)
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully saved response!',
                    icon : 'success',
                    timer: 3000
                    
                    }
                ).then((result) => {  
                    console.log(result)
                    strDetails['video'] = props.createStrUpload.strUpload[0].filename;
                    // const updatedArray = testUserItems.map((item, key) => {
                    //     // console.log(item)
                    //     if (key === dataIndex) {
                    //         return { 
                    //             filename : props.createStrUpload.testFileUpload[0].filename,
                    //             currentSchool: item.currentSchool,
                    //             handle: item.handle,
                    //             id: item.id,
                    //             video : '',
                    //         }  
                    //     }
                    //     return item;
                        

                    // })
                    
                    // Update the state with the new array
                    dispatch(createStrUploadReset());
                    setStrDetails(strDetails);
                    
                })
            
            }
        
            else if(typeof props.createStrUpload.error !== "undefined"){
    
                Swal.fire({
                    title:'Failed!',
                    text:'Failed to save response!',
                    icon :'error',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    dispatch(createStrUploadReset());
                })    
                    
            
            }
        }
    }, [props.createStrUpload, dispatch])

    const findRequest = (arr, meetingId) => {
        const details = arr.fetchstudentrequesttutor
        //  console.log(details[0])
        return details[0].find(x => x.meetingId === meetingId);
    }

     //Click input file
    const clickInputFile = (e) => {
        e.preventDefault()
        fileInputRef.current.click();
    }
      //Upload Video
    const handleVideoChange = (event) => {
        Swal.fire({
            title: 'Do you want to upload the changes?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                
                    strDetails['file'] = event.target.files[0];
                    console.log(strDetails)
                 
                    // Check the file size
                    const fileSizeInMB = strDetails['file'].size / (1024 * 1024);
                    const maxSizeInMB = 10;
                    if (fileSizeInMB > maxSizeInMB) {
                        Swal.fire({
                            title: 'Failed!',
                            text: 'File size exceeds the maximum limit of 10MB.',
                            icon : 'error',
                            timer: 3000
                            
                            }
                        )
                        return;
                    }
            
                    // Check if the file type is video
                    const allowedTypes = ['video/mp4', 'video/mpeg', 'video/quicktime'];
                    if (!allowedTypes.includes(strDetails['file'].type)) {
                        Swal.fire({
                            title: 'Failed!',
                            text: 'Only video files are allowed.',
                            icon : 'error',
                            timer: 3000
                            
                            }
                        )

                        return;
                    }

                    // setDataIndex(index)
                    // // settestUserItems(updatedItems); 

                    const formData = new FormData();
                    formData.append('file', strDetails['file']);
                    formData.append('video', strDetails.video);
                    formData.append('handle', strDetails.handle);
                    formData.append('meetingId', strDetails.meetingId);
                    formData.append('docId', strDetails.docId);
            
                    dispatch(createStrUpload(formData))
                   
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
        
    };
    

    return (
    <div>
        
            <Modal className="modal-dialog-centered" size="lg" isOpen={modalUploadTutorVideoFile}  toggle={toggleUploadTutorVideoFile}>
                
                <ModalBody className='modal-body p-0'>
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                            <Col xs="8">
                                    <h3 className="mb-0">{ 'Meeting Id: #'+ strDetails.meetingId }</h3>
                            </Col>
                            <Col className="text-right" >
                                
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{maxHeight:"650px", overflowY: 'auto', textAlign: 'center'}}>
                            
                            <div className="p-3">
                                <Row>
                                    <Col lg="12">
                                        {strDetails.video ? 
                                            <div className='player-wrapper'>
                                                <ReactPlayer
                                                url={'https://storage.googleapis.com/pre-pa-tracker.appspot.com/schedule-tutor-requests/' + strDetails.video}
                                                className='react-player'
                                                controls
                                                width='100%'
                                                height='100%'
                                                />
                                            </div>

                                        : <img
                                                alt="Sample"
                                                src="https://picsum.photos/300/200"
                                            />
                                        }
                        
                                        <input
                                            type="file"
                                            accept="video/*"
                                            onChange={(event) => handleVideoChange(event)}
                                            ref={fileInputRef}
                                            style={{ display: "none" }}
                                        
                                            />
                                    
                                    </Col>
                                </Row>
                            </div>  
                          
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                   <Button color="secondary" onClick={toggleUploadTutorVideoFile}>
                        Cancel
                    </Button>{' '}
                    <Button color="success"  onClick={(event) => clickInputFile(event)}>
                        {strDetails.video ? 'Update Video' : 'Upload Video'}
                    </Button>
                </ModalFooter>
                
            </Modal>
        </div>
  )
}
const mapStateToProps = state => {
    return {
        fetchStudentRequestTutor: state.fetchStudentRequestTutor,
        editTutorRequest: state.editTutorRequest,
        createStrUpload: state.createStrUpload
    }
}

export default connect(mapStateToProps)(UploadTutorVideoFileModal)
