export const CREATE_COACH_TUTOR_AVAILABILITY_REQUEST = 'CREATE_COACH_TUTOR_AVAILABILITY_REQUEST';
export const CREATE_COACH_TUTOR_AVAILABILITY_SUCCESS = 'CREATE_COACH_TUTOR_AVAILABILITY_SUCCESS';
export const CREATE_COACH_TUTOR_AVAILABILITY_FAILURE = 'CREATE_COACH_TUTOR_AVAILABILITY_FAILURE';
export const CREATE_COACH_TUTOR_AVAILABILITY_RESET = 'CREATE_COACH_TUTOR_AVAILABILITY_RESET';


export const DELETE_COACH_TUTOR_AVAILABILITY_REQUEST = 'DELETE_COACH_TUTOR_AVAILABILITY_REQUEST';
export const DELETE_COACH_TUTOR_AVAILABILITY_SUCCESS = 'DELETE_COACH_TUTOR_AVAILABILITY_SUCCESS';
export const DELETE_COACH_TUTOR_AVAILABILITY_FAILURE = 'DELETE_COACH_TUTOR_AVAILABILITY_FAILURE';
export const DELETE_COACH_TUTOR_AVAILABILITY_RESET = 'DELETE_COACH_TUTOR_AVAILABILITY_RESET';


export const FETCH_COACH_TUTOR_AVAILABILITY_REQUEST = 'FETCH_COACH_TUTOR_AVAILABILITY_REQUEST';
export const FETCH_COACH_TUTOR_AVAILABILITY_SUCCESS = 'FETCH_COACH_TUTOR_AVAILABILITY_SUCCESS';
export const FETCH_COACH_TUTOR_AVAILABILITY_FAILURE = 'FETCH_COACH_TUTOR_AVAILABILITY_FAILURE';
export const FETCH_COACH_TUTOR_AVAILABILITY_RESET = 'FETCH_COACH_TUTOR_AVAILABILITY_RESET';

export const FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_REQUEST = 'FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_REQUEST';
export const FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_SUCCESS = 'FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_SUCCESS';
export const FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_FAILURE = 'FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_FAILURE';
export const FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_RESET = 'FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_RESET';