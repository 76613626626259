import { 
    CREATE_COACH_TUTOR_AVAILABILITY_FAILURE,
    CREATE_COACH_TUTOR_AVAILABILITY_REQUEST,
    CREATE_COACH_TUTOR_AVAILABILITY_RESET,
    CREATE_COACH_TUTOR_AVAILABILITY_SUCCESS,

    DELETE_COACH_TUTOR_AVAILABILITY_FAILURE,
    DELETE_COACH_TUTOR_AVAILABILITY_REQUEST,
    DELETE_COACH_TUTOR_AVAILABILITY_RESET,
    DELETE_COACH_TUTOR_AVAILABILITY_SUCCESS,

    FETCH_COACH_TUTOR_AVAILABILITY_FAILURE,
    FETCH_COACH_TUTOR_AVAILABILITY_REQUEST,
    FETCH_COACH_TUTOR_AVAILABILITY_RESET,
    FETCH_COACH_TUTOR_AVAILABILITY_SUCCESS,

    FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_FAILURE,
    FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_REQUEST,
    FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_RESET,
    FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_SUCCESS
} from "./ManageCoachTutorAvailabilityTypes"


import axios from "axios";

export const createCoachTutorAvailabilityReset = () => {
    return {
        type: CREATE_COACH_TUTOR_AVAILABILITY_RESET,
    }
}

const createCoachTutorAvailabilityRequest = () => {
    return {
        type: CREATE_COACH_TUTOR_AVAILABILITY_REQUEST,
    }
}

const createCoachTutorAvailabilitySuccess = success => {
    return {
        type: CREATE_COACH_TUTOR_AVAILABILITY_SUCCESS,
        payload: success
    }
}

const createCoachTutorAvailabilityFailure = error => {
    return {
        type: CREATE_COACH_TUTOR_AVAILABILITY_FAILURE,
        payload : error
    }
}

export const createCoachTutorAvailability = (data) => {

    return (dispatch) => {

        dispatch(createCoachTutorAvailabilityRequest());
        
        
        
        axios.post('/createCoachTutorAvailability', data)
        .then(response => {
            const createCoachTutorAvailability = response.data
            dispatch(createCoachTutorAvailabilitySuccess(createCoachTutorAvailability))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createCoachTutorAvailabilityFailure(errorMsg))
        })
        
    }

}


export const deleteCoachTutorAvailabilityReset = () => {
    return {
        type: DELETE_COACH_TUTOR_AVAILABILITY_RESET,
    }
}

const deleteCoachTutorAvailabilityRequest = () => {
    return {
        type: DELETE_COACH_TUTOR_AVAILABILITY_REQUEST,
    }
}

const deleteCoachTutorAvailabilitySuccess = success => {
    return {
        type: DELETE_COACH_TUTOR_AVAILABILITY_SUCCESS,
        payload: success
    }
}

const deleteCoachTutorAvailabilityFailure = error => {
    return {
        type: DELETE_COACH_TUTOR_AVAILABILITY_FAILURE,
        payload : error
    }
}

export const deleteCoachTutorAvailability = (data) => {

    return (dispatch) => {

        dispatch(deleteCoachTutorAvailabilityRequest());
        
        
        
        axios.post('/deleteCoachTutorAvailability', data)
        .then(response => {
            const deleteCoachTutorAvailability = response.data
            dispatch(deleteCoachTutorAvailabilitySuccess(deleteCoachTutorAvailability))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteCoachTutorAvailabilityFailure(errorMsg))
        })
        
    }

}


export const fetchCoachTutorAvailabilityReset = () => {
    return {
        type: FETCH_COACH_TUTOR_AVAILABILITY_RESET,
    }
}

const fetchCoachTutorAvailabilityRequest = () => {
    return {
        type: FETCH_COACH_TUTOR_AVAILABILITY_REQUEST,
    }
}

const fetchCoachTutorAvailabilitySuccess = success => {
    return {
        type: FETCH_COACH_TUTOR_AVAILABILITY_SUCCESS,
        payload: success
    }
}

const fetchCoachTutorAvailabilityFailure = error => {
    return {
        type: FETCH_COACH_TUTOR_AVAILABILITY_FAILURE,
        payload : error
    }
}

export const fetchCoachTutorAvailability = (data) => {

    return (dispatch) => {

        dispatch(fetchCoachTutorAvailabilityRequest());
        
        
        
        axios.post('/fetchCoachTutorAvailability', data)
        .then(response => {
            const fetchCoachTutorAvailability = response.data
            dispatch(fetchCoachTutorAvailabilitySuccess(fetchCoachTutorAvailability))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchCoachTutorAvailabilityFailure(errorMsg))
        })
        
    }

}


export const fetchHistoryCoachTutorAvailabilityReset = () => {
    return {
        type: FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_RESET,
    }
}

const fetchHistoryCoachTutorAvailabilityRequest = () => {
    return {
        type: FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_REQUEST,
    }
}

const fetchHistoryCoachTutorAvailabilitySuccess = success => {
    return {
        type: FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_SUCCESS,
        payload: success
    }
}

const fetchHistoryCoachTutorAvailabilityFailure = error => {
    return {
        type: FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_FAILURE,
        payload : error
    }
}

export const fetchHistoryCoachTutorAvailability = (data) => {

    return (dispatch) => {

        dispatch(fetchHistoryCoachTutorAvailabilityRequest());
        
        
        
        axios.post('/fetchHistoryCoachTutorAvailability', data)
        .then(response => {
            const fetchHistoryCoachTutorAvailability = response.data
            dispatch(fetchHistoryCoachTutorAvailabilitySuccess(fetchHistoryCoachTutorAvailability))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchHistoryCoachTutorAvailabilityFailure(errorMsg))
        })
        
    }

}