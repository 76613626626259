import { useState, useEffect } from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";

import CoursesWidget from "components/Widgets/CoursesWidget";
import McatScoresWidget from "components/Widgets/McatScoresWidget";
import GoalHoursWidget from "../../../components/Widgets/GoalHoursWidget";
import PremedRequirements from "../../../components/Widgets/PremedRequirements";
import TwitterWidget from "../../../components/Widgets/TwitterWidget";
import PremedRequirementsPercentage from "components/Widgets/PremedRequirementsPercentage";
import TutoringHoursAvailable from "components/Widgets/TutoringHoursAvailable";
import TotalWorkActivities from "components/Widgets/TotalWorkActivities";
import TotalHoursPerActivity from "components/Widgets/TotalHoursPerActivity";

import { fetchDetailsSubscription } from "redux/Payments/PaymentsActions";
import { useDispatch } from "react-redux";

import { connect } from "react-redux";

import Swal from 'sweetalert2'
import { updateUserPhoneNumber, updateUserPhoneNumberReset } from "redux/Users/UsersActions";

import { getUserData } from "redux/UserAuthentication/LoginActions";
const ClientDashboard = (props) => {

    const [activeNav, setActiveNav] = useState(1);
    const [chartExample1Data, setChartExample1Data] = useState("data1");

    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    const toggleNavs = (e, index) => {
        e.preventDefault();
        setActiveNav(index);
        setChartExample1Data("data" + index);
    };

    const dispatch = useDispatch()
    
    useEffect(() => {
      if(typeof props.userDetails.subsId != "undefined"){
        dispatch(fetchDetailsSubscription({ subsId : props.userDetails.subsId }))
      }
      
    }, [])
    
    let phoneNumberPrompt = false;
    useEffect(() => {
      if(typeof props.userDetails.email != "undefined"){
        if(!phoneNumberPrompt){
          
          if(props.userDetails.phoneNumber == "" || typeof props.userDetails.phoneNumber == "undefined"){
            phoneNumberPrompt = true
            Swal.fire({
              title: 'Required: Phone number.',
              icon: 'warning',
              html:'Please provide your phone number.',
              input: 'text',
              showCancelButton: false,
              confirmButtonText: 'Save',
              showLoaderOnConfirm: true,
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                phoneNumberPrompt = false

                dispatch(getUserData({ onLogin: true }));
                dispatch(updateUserPhoneNumber({handle:props.userDetails.handle , phoneNumber : result.value}))  
              }
              else {
                phoneNumberPrompt = false
              }
            })
            
          }
        }
      }
    }, [props.userDetails])
    

    useEffect(() => {
      if(props.updateUserPhoneNumber.userphonenumber.length){
        Swal.fire({
          title: 'Success!',
          text: 'Phone number updated successfully!',
          icon : 'success',
          timer: 3000
          
        }
        ).then(function (result) {
            
          dispatch(updateUserPhoneNumberReset());

          // history.push("/auth/login");
        
        });
      }

    }, [props.updateUserPhoneNumber])
  //   useEffect(() => {
  //     // Your Chatbase Config
  //     window.chatbaseConfig = {
  //       chatbotId: "2a96NizDXhvKZcqFDKxGd",
  //     };
  
  //     // Create the script element
  //     const script = document.createElement('script');
  //     script.src = 'https://www.chatbase.co/embed.min.js';
  //     script.id = '2a96NizDXhvKZcqFDKxGd';
  //     script.defer = true;
  
  //     // Append the script to the document's head
  //     document.head.appendChild(script);
  
  //     // Cleanup function to remove the script when the component unmounts
  //     return () => {
  //       document.head.removeChild(script);
  //     };
  // }, []);

    return (
    <>
      
      <div className="header bg-gradient-info  pb-sm-7 pb-xl-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <CoursesWidget></CoursesWidget>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt-sm--7 pb-4" fluid>
          <div className="header-body">
            <TotalHoursPerActivity></TotalHoursPerActivity>
          </div>
      </Container>
      {/* <Container className="pb-4" fluid>
          <div className="header-body">
            <Row>
                <Col className='mb-4 mb-xl-0 d-flex' xl="6">
                    <PremedRequirementsPercentage></PremedRequirementsPercentage>
                </Col>
                <Col xl="6 d-flex">
                    <TutoringHoursAvailable></TutoringHoursAvailable>
                </Col>
                <Col xl="4 d-flex"> */}
                  {/* <TotalWorkActivities></TotalWorkActivities> */}
                {/* </Col>
            </Row>
          </div>
      </Container> */}
      <Container className="pb-8" fluid>
          <div className="header-body">
            <Row>
                <Col className='mb-4 mb-xl-0' xl="8">
                    {/* <McatScoresWidget></McatScoresWidget> */}
                    <PremedRequirements></PremedRequirements>
                </Col>
                <Col xl="4">
                    <GoalHoursWidget></GoalHoursWidget>
                </Col>
            </Row>
          </div>
      </Container>

      
      {/* <Container className="mt--7 pb-8" fluid>
          <div className="header-body">
            <Row>
                <Col className='mb-4 mb-xl-0' xl="8">
                    <PremedRequirements></PremedRequirements>
                </Col>
                <Col xl="4"> */}
                    {/* <TwitterWidget></TwitterWidget> */}
                {/* </Col>
            </Row>
          </div>
      </Container> */}
      {/* <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Sales value</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                
                <div className="chart">
                  <Line
                    data={chartExample1[chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Total orders</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                
                <div className="chart">
                  <Bar
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Page visits</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Page name</th>
                    <th scope="col">Visitors</th>
                    <th scope="col">Unique users</th>
                    <th scope="col">Bounce rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">/argon/</th>
                    <td>4,569</td>
                    <td>340</td>
                    <td>
                      <i className="fas fa-arrow-up text-success mr-3" /> 46,53%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/index.html</th>
                    <td>3,985</td>
                    <td>319</td>
                    <td>
                      <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                      46,53%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/charts.html</th>
                    <td>3,513</td>
                    <td>294</td>
                    <td>
                      <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                      36,49%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/tables.html</th>
                    <td>2,050</td>
                    <td>147</td>
                    <td>
                      <i className="fas fa-arrow-up text-success mr-3" /> 50,87%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/profile.html</th>
                    <td>1,795</td>
                    <td>190</td>
                    <td>
                      <i className="fas fa-arrow-down text-danger mr-3" />{" "}
                      46,53%
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Social traffic</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Referral</th>
                    <th scope="col">Visitors</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>1,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">60%</span>
                        <div>
                          <Progress
                            max="100"
                            value="60"
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>5,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">70%</span>
                        <div>
                          <Progress
                            max="100"
                            value="70"
                            barClassName="bg-gradient-success"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Google</th>
                    <td>4,807</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">80%</span>
                        <div>
                          <Progress max="100" value="80" />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Instagram</th>
                    <td>3,678</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">75%</span>
                        <div>
                          <Progress
                            max="100"
                            value="75"
                            barClassName="bg-gradient-info"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">twitter</th>
                    <td>2,645</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">30%</span>
                        <div>
                          <Progress
                            max="100"
                            value="30"
                            barClassName="bg-gradient-warning"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container> */}</>
  )
}

const mapStateToProps = state => {
  return {
    userDetails : state.loginAuth.userDetails,
    updateUserPhoneNumber : state.updateUserPhoneNumber
  }
}


export default connect(mapStateToProps)(ClientDashboard)