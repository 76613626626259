export const aamcChemScore = {
    0 : [0,0],
    1 : [118,1],
    2 : [118,1],
    3 : [118,1],
    4 : [118,1],
    5 : [118,1],
    6 : [118,1],
    7 : [118,1],
    8 : [118,1],
    9 : [118,1],
    10 : [118,1],
    11 : [118,1],
    12 : [118,1],
    13 : [118,1],
    14 : [118,1],
    15 : [118,1],
    16 : [119,3],
    17 : [119,3],
    18 : [120,7],
    19 : [120,7],
    20 : [120,7],
    21 : [121,12],
    22 : [121,12],
    23 : [121,12],
    24 : [122,21],
    25 : [122,21],
    26 : [122,21],
    27 : [122,21],
    28 : [123,32],
    29 : [123,32],
    30 : [123,32],
    31 : [124,45],
    32 : [124,45],
    33 : [124,45],
    34 : [124,45],
    35 : [124,45],
    36 : [125,55],
    37 : [125,55],
    38 : [125,55],
    39 : [126,67],
    40 : [126,67],
    41 : [126,67],
    42 : [126,67],
    43 : [127,79],
    44 : [127,79],
    45 : [127,79],
    46 : [127,79],
    47 : [128,87],
    48 : [128,87],
    49 : [128,87],
    50 : [129,93],
    51 : [129,93],
    52 : [129,93],
    53 : [130,97],
    54 : [130,97],
    55 : [130,97],
    56 : [131,99],
    57 : [131,99],
    58 : [132,100],
    59 : [132,100]
}

export const aamcCarsScore = {
    0 : [0,0],
    1 : [118,1],
    2 : [118,1],
    3 : [118,1],
    4 : [118,1],
    5 : [118,1],
    6 : [118,1],
    7 : [118,1],
    8 : [118,1],
    9 : [118,1],
    10 : [118,1],
    11 : [118,1],
    12 : [118,1],
    13 : [118,1],
    14 : [118,1],
    15 : [118,1],
    16 : [119,3],
    17 : [119,3],
    18 : [119,3],
    19 : [119,3],
    20 : [120,8],
    21 : [120,8],
    22 : [120,8],
    23 : [120,8],
    24 : [121,14],
    25 : [121,14],
    26 : [121,14],
    27 : [122,22],
    28 : [122,22],
    29 : [122,22],
    30 : [123,33],
    31 : [123,33],
    32 : [123,33],
    33 : [124,44],
    34 : [124,44],
    35 : [124,44],
    36 : [125,58],
    37 : [125,58],
    38 : [125,58],
    39 : [126,70],
    40 : [126,70],
    41 : [127,81],
    42 : [127,81],
    43 : [128,87],
    44 : [128,87],
    45 : [128,87],
    46 : [129,93],
    47 : [130,97],
    48 : [130,97],
    49 : [131,99],
    50 : [131,99],
    51 : [132,100],
    52 : [132,100],
    53 : [132,100],
}


export const aamcBioChemScore = {
    0 : [0,0],
    1 : [118,1],
    2 : [118,1],
    3 : [118,1],
    4 : [118,1],
    5 : [118,1],
    6 : [118,1],
    7 : [118,1],
    8 : [118,1],
    9 : [118,1],
    10 : [118,1],
    11 : [118,1],
    12 : [118,1],
    13 : [118,1],
    14 : [118,1],
    15 : [118,1],
    16 : [118,1],
    17 : [119,3],
    18 : [119,3],
    19 : [119,3],
    20 : [120,7],
    21 : [120,7],
    22 : [120,7],
    23 : [120,7],
    24 : [121,14],
    25 : [121,14],
    26 : [121,14],
    27 : [121,14],
    28 : [122,21],
    29 : [122,21],
    30 : [122,21],
    31 : [123,32],
    32 : [123,32],
    33 : [123,32],
    34 : [123,32],
    35 : [124,44],
    36 : [124,44],
    37 : [124,44],
    38 : [125,55],
    39 : [125,55],
    40 : [125,55],
    41 : [126,67],
    42 : [126,67],
    43 : [126,67],
    44 : [127,77],
    45 : [127,77],
    46 : [127,77],
    47 : [128,87],
    48 : [128,87],
    49 : [128,87],
    50 : [129,93],
    51 : [129,93],
    52 : [130,97],
    53 : [130,97],
    54 : [130,97],
    55 : [131,99],
    56 : [131,99],
    57 : [132,100],
    58 : [132,100],
    59 : [132,100]
}


export const aamcPsychSocScore = {
    0 : [0,0],
    1 : [118,1],
    2 : [118,1],
    3 : [118,1],
    4 : [118,1],
    5 : [118,1],
    6 : [118,1],
    7 : [118,1],
    8 : [118,1],
    9 : [118,1],
    10 : [118,1],
    11 : [118,1],
    12 : [118,1],
    13 : [118,1],
    14 : [118,1],
    15 : [118,1],
    16 : [118,1],
    17 : [118,1],
    18 : [119,3],
    19 : [119,3],
    20 : [119,3],
    21 : [120,7],
    22 : [120,7],
    23 : [120,7],
    24 : [121,12],
    25 : [121,12],
    26 : [121,12],
    27 : [121,12],
    28 : [122,22],
    29 : [122,22],
    30 : [122,22],
    31 : [122,22],
    32 : [123,32],
    33 : [123,32],
    34 : [123,32],
    35 : [124,43],
    36 : [124,43],
    37 : [124,43],
    38 : [124,43],
    39 : [125,55],
    40 : [125,55],
    41 : [125,55],
    42 : [126,66],
    43 : [126,66],
    44 : [126,66],
    45 : [127,75],
    46 : [127,75],
    47 : [127,75],
    48 : [127,75],
    49 : [128,86],
    50 : [128,86],
    51 : [129,93],
    52 : [129,93],
    53 : [129,93],
    54 : [130,97],
    55 : [130,97],
    56 : [131,98],
    57 : [132,100],
    58 : [132,100],
    59 : [132,100]
}


export const aamcTotalScore = {
    0 : 0,
    472 : 0,
    473 : 0,
    474 : 0,
    475 : 1,
    476 : 1,
    477 : 1,
    478 : 2,
    479 : 3,
    480 : 4,
    481 : 5,
    482 : 6,
    483 : 7,
    484 : 8,
    485 : 10,
    486 : 11,
    487 : 13,
    488 : 15,
    489 : 18,
    490 : 20,
    491 : 22,
    492 : 25,
    493 : 28,
    494 : 31,
    495 : 34,
    496 : 37,
    497 : 40,
    498 : 43,
    499 : 47,
    500 : 51,
    501 : 54,
    502 : 57,
    503 : 61,
    504 : 64,
    505 : 68,
    506 : 71,
    507 : 74,
    508 : 77,
    509 : 80,
    510 : 83,
    511 : 85,
    512 : 87,
    513 : 89,
    514 : 91,
    515 : 93,
    516 : 95,
    517 : 96,
    518 : 97,
    519 : 98,
    520 : 98,
    521 : 99,
    522 : 99,
    523 : 100,
    524 : 100,
    525 : 100,
    526 : 100,
    527 : 100,
    528 : 100,
    529 : 100,
    530 : 100,
    531 : 100,
    532 : 100,

}