/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index";
import Profile from "views/examples/Profile";
import Maps from "views/examples/Maps";
import Register from "views/pages/Register";
import Login from "views/pages/Login";
import Tables from "views/examples/Tables";
import Icons from "views/examples/Icons";


import WorkActivities from "views/pages/WorkActivities";
import ForgotPassword from "views/pages/ForgotPassword";

var routes = [
//   {
//     path: "/index",
//     name: "Dashboard",
//     icon: "ni ni-tv-2 text-primary",
//     component: Index,
//     layout: "/admin"
//   },
//   {
//     path: "/work-activities",
//     name: "Work/Activities",
//     icon: "ni ni-planet text-blue",
//     component: WorkActivities,
//     layout: "/admin"
//   },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
//   {
//     path: "/user-profile",
//     name: "User Profile",
//     icon: "ni ni-single-02 text-yellow",
//     component: Profile,
//     layout: "/admin"
//   },
//   {
//     path: "/tables",
//     name: "Secondary Essays",
//     icon: "ni ni-bullet-list-67 text-red",
//     component: Tables,
//     layout: "/admin"
//   },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  }
  ,
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    icon: "ni ni-circle-08 text-pink",
    component: ForgotPassword,
    layout: "/auth"
  }
];
export default routes;
