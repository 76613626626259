
import axios from "axios";
import { SET_LOGIN_AUTHENTICATED_ERRORS,
    CLEAR_LOGIN_AUTHENTICATED_ERRORS,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    AUTHENTICATED_REQUEST,
    SET_ADMIN, 
    RENEW_TOKEN_SUCCESS,
    RENEW_TOKEN_REQUEST,
    RENEW_TOKEN_FAILURE
} from "./LoginTypes";
    

export const loginUser = (userData) => (dispatch) => {
    
    dispatch(loginUserRequest());
    axios.post('/login', userData)
        .then(res => {

            
            const FBIdToken = `Bearer ${res.data.token}`
            const FBRefreshToken = res.data.refreshToken

            localStorage.setItem('FBIdToken', FBIdToken)
            localStorage.setItem('FBRefreshToken', FBRefreshToken)

            axios.defaults.headers.common['Authorization'] = FBIdToken

            dispatch(clearloginAuthenticatedErrors());
            dispatch(setAuthenticated(res.data.userDetails));
        })
        .catch(err => {
            
            dispatch(loginUserFailed(err))

        })
}

export const logoutUser = (args) => (dispatch) => {
    delete axios.defaults.headers.common['Authorization']
    axios.post('/logout')
        .then(res => {
            localStorage.removeItem('FBIdToken');
            localStorage.removeItem('FBRefreshToken');

            dispatch(setUnauthenticated())
            window.location.href = '/auth/login'
        })
}

export const clearloginAuthenticatedErrors = () => {
    return {
        type: CLEAR_LOGIN_AUTHENTICATED_ERRORS
    }
}

const loginUserRequest = () => {
    return {
        type: AUTHENTICATED_REQUEST,
    }
}

const loginUserFailed = err => {
    return {
        type: SET_LOGIN_AUTHENTICATED_ERRORS,
        payload : err.response ? err.response.data : "Something went wrong, try again later."
    }
}

export const setAuthenticated = data => {
    return {
        type: SET_AUTHENTICATED,
        payload: data
    }
}

const setUnauthenticated = () => {
    return {
        type: SET_UNAUTHENTICATED
    }
}

export const getUserData = (args) => (dispatch) => {
    // dispatch({ type: LOADING_USER })

    const user = args && args.user ? args.user : undefined
    const onLogin = args && args.onLogin ? args.onLogin : undefined
    
    axios.post('/user', user)
        .then(res => {
            if (onLogin) {

                
                dispatch(setAuthenticated(res.data.credentials))
                
            }
            // if (!user && res.data.credentials.admin) {
            //     dispatch(getAllUsers())
            // }
            if (user) {

                dispatch(setAuthenticated(res.data.credentials))
              
                dispatch(adminUser())
            }
            // dispatch({
            //     type: SET_USER,
            //     payload: res.data
            // })
        })
        .catch(err => { console.log(err) })
}


const adminUser = () => {
    return {
        type: SET_ADMIN,
    }
}


const renewTokenRequest = () => {
    return {
        type: RENEW_TOKEN_REQUEST,
    }
}

const renewTokenSuccess = success => {
    return {
        type: RENEW_TOKEN_SUCCESS,
        payload: success
    }
}

const renewTokenFailure = error => {
    return {
        type: RENEW_TOKEN_FAILURE,
        payload : error
    }
}

export const renewToken = (data) => {
    
    return (dispatch) => {
        
        dispatch(renewTokenRequest());
        
        
        axios.post('/refreshUser' , data)
        .then(response => {
            const token = response.data

            const FBIdToken = `Bearer ${token.access_token}`
            const FBRefreshToken = token.refresh_token

            localStorage.setItem('FBIdToken', FBIdToken)
            localStorage.setItem('FBRefreshToken', FBRefreshToken)

            axios.defaults.headers.common['Authorization'] = FBIdToken
            
            dispatch(renewTokenSuccess(token))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(renewTokenFailure(errorMsg))
        })
        
    }

}