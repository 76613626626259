import React, { useEffect, useState } from 'react'
import {

    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
  } from "reactstrap";

import Swal from 'sweetalert2'
import '../../../assets/css/school-card-react.css'

import { connect, useDispatch } from 'react-redux';
import { createSelectSchoolForSecondaryEssays, createSelectSchoolForSecondaryEssaysReset } from 'redux/SecondaryEssays/SecondaryEssaysActions';
import moment from 'moment';

const SchoolCard = (props) => {

  // let [applicationDeadlineDay, setApplicationDeadlineDay] = useState(null);
  // let [applicationDeadlineMonth, setApplicationDeadlineMonth] = useState(null);
  // let [applicationDeadlineYear, setApplicationDeadlineYear] = useState(null);

  let [applicationDeadline, setApplicationDeadline] = useState(null);


  // let [classesBeginDay, setClassesBeginDay] = useState(null);
  // let [classesBeginMonth, setClassesBeginMonth] = useState(null);
  // let [classesBeginYear, setClassesBeginYear] = useState(null);

  let [selectedSecondaryEssaysClass, setSelectedSecondaryEssaysClass ] = useState("");
  let [selectedSecondaryEssaysText, setSelectedSecondaryEssaysText ] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {

    let applicationDeadlineDate = new Date(props.medicalSchoolDetails.overview.priApplLatestDtText);

    // setApplicationDeadlineDay(applicationDeadlineDate.getDate());
    // setApplicationDeadlineMonth(applicationDeadlineDate.toLocaleString('default', { month: 'long' }));
    // setApplicationDeadlineYear(applicationDeadlineDate.getFullYear());
    
    if(props.medicalSchoolDetails.applicationInformation["Deadline Date"]){
      setApplicationDeadline(moment(props.medicalSchoolDetails.applicationInformation["Deadline Date"], 'MM/DD/YYYY').format('MMMM DD, YYYY'));
    }
    else{
      setApplicationDeadline("Not Provided")
    }
    

    // let classesBeginDate = new Date(props.medicalSchoolDetails.medSchoolInformation.startMonthYear);

    // setClassesBeginDay(classesBeginDate.getDate());
    // setClassesBeginMonth(classesBeginDate.toLocaleString('default', { month: 'long' }));
    // setClassesBeginYear(classesBeginDate.getFullYear());


    setSelectedSecondaryEssaysClass(props.medicalSchoolDetails.selectedSecondaryEssays == "Y"? "btn btn-danger disabled" : props.medicalSchoolDetails.secondaryEssays != "Y" ? "btn btn-default disabled" : props.adminUser.admin ? "btn btn-primary disabled" : "btn btn-primary" );

    setSelectedSecondaryEssaysText(props.medicalSchoolDetails.selectedSecondaryEssays == "Y"? "ALREADY ADDED TO MY APPLICATION LIST" : props.medicalSchoolDetails.secondaryEssays == "Y" ?  "ADD TO MY APPLICATION LIST" : "NO AVAILABLE SECONDARY ESSAYS");


  }, [props.medicalSchoolDetails])


  const formatterUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  const schoolType = (data) => {
      if(data.countryCd == "CAN"){
        return "CANADIAN"
      }
      else if(data.schoolType == "DO"){
        return "DO"
      }
      else{
        return "PA"
      }
  }

  const onClickAddToMyApplication = (e, data) => {
    e.preventDefault();

    data['userId'] = props.userDetails.userId;
      
    dispatch(createSelectSchoolForSecondaryEssays(data));

    
    Swal.showLoading()

    setSelectedSecondaryEssaysClass("btn btn-danger disabled")
    setSelectedSecondaryEssaysText("ALREADY ADDED TO MY APPLICATION LIST")
    
  }

  

  useEffect(() => {
    
  
      if(props.createSelectSchoolForSecondaryEssays.selectschoolforsecondaryessays.length && typeof props.createSelectSchoolForSecondaryEssays.error === "undefined"){
        // dispatch(createSelectSchoolForSecondaryEssaysReset());
    

        Swal.fire({
            title: 'Success!',
            text: 'Successfully added to my application list!',
            icon : 'success',
            timer: 3000
            
          }
        ).then((result) => {
          dispatch(createSelectSchoolForSecondaryEssaysReset());
        })
        
        
        
      }
    
      else if(typeof props.createSelectSchoolForSecondaryEssays.error !== "undefined"){
        // dispatch(createSelectSchoolForSecondaryEssaysReset()); 
        Swal.fire({
            title:'Failed!',
            text:'Failed to add to my application list!',
            icon :'error',
            timer: 3000
            
          }
        ).then((result) => {
          dispatch(createSelectSchoolForSecondaryEssaysReset());
        })    
              
        
      }
    
  }, [props.createSelectSchoolForSecondaryEssays])
  
 

  
  return (
    <Row className="mb-5 ">
        <Col className="order-xl-2 mb-5 mb-xl-0" xl="12">
            <Card className="card-profile shadow">
              
              
              <CardHeader className='card-school-cover-img bg-primary' style={{backgroundImage: `url(https://storage.googleapis.com/pre-pa-tracker.appspot.com/school-search-images/`+props.medicalSchoolDetails.imageUrl+`)`}}>
              <Row className='pl-3 pr-3'>
                <Col className="order-lg-1" lg="3">
                  <div className="card-profile-div shadow">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <div className="school-card-img" style={{backgroundImage: "url(https://storage.googleapis.com/pre-pa-tracker.appspot.com/school-search-images/"+props.medicalSchoolDetails.crestUrl.replace(".jpeg",".jpg")+")"}}></div>
                      </a>
                    </div>
                  </div>
                </Col>
                <Col className="order-lg-2 text-center " lg="12">
                    <div className="border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                        <Row>
                            <Col lg="12">
                            <h1 className="text-secondary">{props.medicalSchoolDetails.overview.schoolName}</h1>
                            </Col>
                            <Col lg="12">
                            <h2 className="text-light"><i className="fa fa-map-marker-alt"></i> {props.medicalSchoolDetails.overview.city}, {props.medicalSchoolDetails.overview.state} | <span className="badge badge-pill badge-secondary" style={{fontSize:"1rem"}}>{schoolType(props.medicalSchoolDetails)} </span> | <a  href={props.medicalSchoolDetails.admUrl} className='text-info' target='_blank'><i className="fa fa-globe"></i> PA School Website</a></h2>
                            </Col>
                        </Row>                        
                        
                    </div>
                </Col>
              </Row>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
              
                  <Row className="pt-3">
                      <Col lg="1"></Col>
                      
                      <Col lg="5"><h3 className="font-weight-bolder">Application Deadline : <span className="font-weight-normal">{applicationDeadline}</span></h3></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">In-state annual tuition: <span className="font-weight-normal">{formatterUSD.format(props.medicalSchoolDetails.overview.tuitionAndFeesRes)}</span></h3></Col>
                      <Col lg="1"></Col>
                      <Col lg="1"></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">Overall GPA : <span className="font-weight-normal">{typeof props.medicalSchoolDetails.gpa.minimumGpa.overall != "undefined" ? parseFloat(props.medicalSchoolDetails.gpa.minimumGpa.overall).toFixed(2) : "Not Provided"}</span></h3></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">Science GPA : <span className="font-weight-normal">{typeof props.medicalSchoolDetails.gpa.minimumGpa.science != "undefined" ? parseFloat(props.medicalSchoolDetails.gpa.minimumGpa.science).toFixed(2) : "Not Provided"}</span></h3></Col>
                      <Col lg="1"></Col>
                      <Col lg="1"></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">Class Start Month : <span className="font-weight-normal">{props.medicalSchoolDetails.programDetails.classStartMonth}</span></h3></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">Require GRE : <span className="font-weight-normal">{props.medicalSchoolDetails.gre.required ? <label className='text-warning'>{"Yes"}</label> : "No"}</span></h3></Col>
                      <Col lg="1"></Col>
                      <Col lg="1"></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">Require PA-CAT : <span className="font-weight-normal">{typeof props.medicalSchoolDetails.paCat != "undefined" ? props.medicalSchoolDetails.paCat.required ? <label className='text-warning'>{"Yes"}</label> : "No" : "Not Provided"}</span></h3></Col>
                      <Col lg="5"><h3 className="font-weight-bolder">Require CASPer : <span className="font-weight-normal">{typeof props.medicalSchoolDetails.casper != "undefined" ? props.medicalSchoolDetails.casper.required ? <label className='text-warning'>{"Yes"}</label> : "No" : "Not Provided"}</span></h3></Col>
                      <Col lg="1"></Col>
                      <Col lg="1"></Col>
                      <Col lg="5"><h3 className="font-weight-bolder"><i className="fa fa-envelope"></i> {props.medicalSchoolDetails.campusList.email ? <a href={"mailto:"+props.medicalSchoolDetails.campusList.email} className='text-primary'> {props.medicalSchoolDetails.campusList.email}</a> : "Not Available"}</h3></Col>
                      <Col lg="5"><h3 className="font-weight-bolder"><i className="fa fa-phone"></i> {props.medicalSchoolDetails.campusList.phone ? <a href={"tel:+"+props.medicalSchoolDetails.campusList.phone} className='text-primary'> {formatPhoneNumber(props.medicalSchoolDetails.campusList.phone)}</a> : "Not Available"} </h3></Col>
                      <Col lg="1"></Col>
                      
                  </Row>
                  {/* <div className='text-center'>
                    <hr className="my-4" />
                    <a onClick={(e) => {onClickAddToMyApplication(e, { "schoolSearchId" : props.medicalSchoolDetails.schoolSearchId})}} className={selectedSecondaryEssaysClass}> <span>{selectedSecondaryEssaysText}</span></a>
                  </div> */}
              </CardBody>
            </Card>
          </Col>
    </Row>
  )
}

const mapStateToProps = state => {
  return {
    userDetails : state.loginAuth.userDetails,
    createSelectSchoolForSecondaryEssays : state.createSelectSchoolForSecondaryEssays,
    adminUser : state.adminUser

  }
}

export default connect(mapStateToProps)(SchoolCard)