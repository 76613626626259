export const CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST = 'CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST';
export const CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS = 'CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS';
export const CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE = 'CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE';
export const CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_RESET = 'CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_RESET';


export const FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST = 'FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST';
export const FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS = 'FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS';
export const FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE = 'FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE';


export const CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_REQUEST = 'CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_REQUEST';
export const CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_SUCCESS = 'CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_SUCCESS';
export const CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_FAILURE = 'CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_FAILURE';
export const CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_RESET = 'CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_RESET';

export const FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_REQUEST = 'FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_REQUEST';
export const FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_SUCCESS = 'FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_SUCCESS';
export const FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_FAILURE = 'FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_FAILURE';
export const FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_RESET = 'FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_RESET';

export const DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_REQUEST = 'DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_REQUEST';
export const DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_SUCCESS = 'DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_SUCCESS';
export const DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_FAILURE = 'DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_FAILURE';
export const DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_RESET = 'DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_RESET';


export const DELETE_RESPONSE_FOR_SECONDARY_ESSAY_REQUEST = 'DELETE_RESPONSE_FOR_SECONDARY_ESSAY_REQUEST';
export const DELETE_RESPONSE_FOR_SECONDARY_ESSAY_SUCCESS = 'DELETE_RESPONSE_FOR_SECONDARY_ESSAY_SUCCESS';
export const DELETE_RESPONSE_FOR_SECONDARY_ESSAY_FAILURE = 'DELETE_RESPONSE_FOR_SECONDARY_ESSAY_FAILURE';
export const DELETE_RESPONSE_FOR_SECONDARY_ESSAY_RESET = 'DELETE_RESPONSE_FOR_SECONDARY_ESSAY_RESET';


export const UPDATE_SELECTED_SECONDARY_STATUS_RESET = "UPDATE_SELECTED_SECONDARY_STATUS_RESET"
export const UPDATE_SELECTED_SECONDARY_STATUS_REQUEST = "UPDATE_SELECTED_SECONDARY_STATUS_REQUEST"
export const UPDATE_SELECTED_SECONDARY_STATUS_SUCCESS = "UPDATE_SELECTED_SECONDARY_STATUS_SUCCESS"
export const UPDATE_SELECTED_SECONDARY_STATUS_FAILURE = "UPDATE_SELECTED_SECONDARY_STATUS_FAILURE"

