import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import moment from 'moment-timezone';

import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Input,
    Image,
    Form,
} from "reactstrap";

import TextareaAutosize from 'react-textarea-autosize';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { createCommunityProMemberLikePost } from 'redux/Community/ProMemberAction';
import { app, db } from "../../firebase";
import { getDocs, getDoc, setDoc, deleteDoc, addDoc, collection, query, where, onSnapshot, doc, orderBy, limit, or } from "firebase/firestore";
const LikeTheProMemberPost = (props) => {
    const dispatch = useDispatch()
     
    const [likeDetails, setLikeDetails] = useState('')

    useEffect(() => {
        if (props.item && typeof props.userDetails.handle != "undefined") {
            const q = query(collection(db, "communityProMemberLikePosts"), where("postid", "==", props.item.postid), where("userHandle", "==", props.userDetails.handle));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
        
                querySnapshot.forEach((doc) => {
                    let likeInFo = doc.data()
                    if (likeInFo) {
                        likeInFo['docid'] = doc.id
                    }
                    setLikeDetails(likeInFo)
                });
            });
        
        }
    }, [props])


    //Like the post
    const likePost =  (e, data) => {
        if (data.likeid) {
            deleteDoc(doc(db, 'communityProMemberLikePosts', data.likeid));  
            setLikeDetails('')
        } else {
            const postLike = {
                userId: props.userDetails.userId,
                userHandle: props.userDetails.handle,
                postid: data.postid,
                createdAt:  new Date().toISOString(),
                updatedAt: new Date().toISOString()
            }
            const likePost =  doc(collection(db, "communityProMemberLikePosts"));
            setDoc(likePost, postLike);
        }
    }
         
    return (
         
        <>
            {
               
                likeDetails  ?
                    <Button color=""
                        onClick={e => likePost(e, {postid:likeDetails.postid, likeid: likeDetails.docid })}
                    >
                        <i className="text-primary fa fa-solid fa-heart d-md-none d-lg-none"></i>
                        <span className="d-none d-md-block d-lg-block text-primary"><i className="fa fa-solid fa-heart"></i> Like</span> 
                    </Button>
                :
                    <Button color=""
                        onClick={ e => likePost(e,{postid:props.item.postid})}
                    >
                    <i className="fa fa-regular fa-heart font-weight-normal d-md-none d-lg-none"></i>
                    <span className="d-none d-md-block d-lg-block"><i className="fa fa-regular fa-heart font-weight-normal"></i> Like</span>
                </Button>        
            }
        </>
    )
}

const mapStateToProps = state  => {
    return {
        userDetails: state.loginAuth.userDetails,
    }  
}

export default connect(mapStateToProps)(LikeTheProMemberPost)
