import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import { ImageGrid } from "react-fb-image-video-grid";
import { Input, Badge } from "reactstrap";

import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { app, db } from "../../firebase";
import {
  getDocs,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import LikeProMemberComment from "components/Community/LikeProMemberComment";
import ProMemberCommentLikes from "components/Community/ProMemberCommentLikes";
import InputReply from "components/Community/InputReplyProMember";
import Replies from "components/Community/ProMemberReplies";
import ProMemberActionComment from "components/Dropdown/ProMemberActionComment";

const ProMemberComment = (props) => {
  const dispatch = useDispatch();
  const [userArr, setUser] = useState({});
  const [imgs, setImgs] = useState([]);
  const [inputrply, setinputrply] = useState(false);

  useEffect(() => {
    setImgs(props.item.fileContent);
  }, [props.item.fileContent]);

  useEffect(() => {
    if (props.item) {
      const fetchUser = async () => {
        const userCollection = collection(db, "users");
        const getUser = query(
          userCollection,
          where("handle", "==", props.item.userHandle)
        );
        const querySnapshot = await getDocs(getUser);
        querySnapshot.forEach((doc) => {
          let user = doc.data();
          setUser(user);
        });
      };
      fetchUser();
    }
  }, [props.item.userHandle]);

  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const focusOnReply = (e, id) => {
    if (inputrply) {
      setinputrply(false);
    } else {
      setinputrply(true);
      //    document.getElementById("replyInput-"+id).focus();
    }
  };

  return (
    <>
      {!isLoading ? (
        props.item ? (
          <>
            <div className="d-flex flex-column mt-4 ml-3">
              <div className="comments d-flex  pr-2">
                <div className="d-flex position-relative m-2">
                  <div
                    className="d-inline-flex flex-row  flex-nowrap mb-1 mr-1"
                    style={{}}
                  >
                    {props.postDetails.anonymous == 1 &&
                    userArr.handle == props.postDetails.userHandle ? (
                      <div
                        className="rounded-circle profile-letter-all-users mr-2"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        {typeof userArr.anonymousName != "undefined"
                          ? userArr.anonymousName.trim()[0]
                          : "A"}
                      </div>
                    ) : typeof userArr.profilePicture != "undefined" ? (
                      <img
                        src={
                          "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
                          userArr.profilePicture
                        }
                        className="mr-1 rounded-circle"
                        alt="..."
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    ) : (
                      <div
                        className="rounded-circle profile-letter-all-users mr-2"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        {typeof userArr.name != "undefined"
                          ? userArr.name.trim()[0]
                          : ""}
                      </div>
                    )}
                    {/* <img  src={userArr.profilePicture ? 'https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/'+userArr.profilePicture : 'https://picsum.photos/300/200' } className="mr-1 rounded-circle" alt="..."  style={{
                                            width: '40px',
                                            height: '40px'
                                        }} /> */}
                    <div
                      className="media-body px-3 py-2"
                      style={{
                        backgroundColor: "#f4f4f5",
                        borderRadius: "17px",
                      }}
                    >
                      <h5 className="m-0">
                        {props.postDetails.anonymous == 1 &&
                        userArr.handle == props.postDetails.userHandle
                          ? typeof userArr.anonymousName != "undefined"
                            ? userArr.anonymousName
                            : "Anonymous"
                          : userArr.name
                          ? userArr.name
                          : ""}{" "}
                        {props.postDetails.anonymous == 1 &&
                        userArr.handle == props.postDetails.userHandle ? (
                          ""
                        ) : userArr.coach || userArr.coach == "true" ? (
                          <Badge className="ml-2 text-dark" color="info" pill>
                            Coach
                          </Badge>
                        ) : (
                          ""
                        )}
                      </h5>
                      <small className=" text-muted">
                        {moment(props.item.createdAt).fromNow()}
                      </small>
                      <p className="m-0 text-break">
                        {props.item.comment}
                        {imgs.length >= 2 ? (
                          <ImageGrid>
                            {imgs
                              .filter((arg, i) =>
                                i + 1 <= imgs.length ? true : false
                              )
                              .map((a, i) =>
                                a.file.mimeType == "video/mp4" ? (
                                  <video
                                    controls
                                    width="200"
                                    height="100"
                                    style={{ backgroundColor: "#000" }}
                                  >
                                    <source
                                      type={a.file.mimeType}
                                      src={
                                        "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                                        a.name
                                      }
                                    />
                                  </video>
                                ) : (
                                  <img
                                    src={
                                      "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                                      a.name
                                    }
                                    alt={i}
                                    width="200"
                                    height="150"
                                    key={Math.random(i)}
                                    style={{ border: "1px solid #ddd" }}
                                  />
                                )
                              )}
                          </ImageGrid>
                        ) : imgs.length == 1 ? (
                          imgs[0].file.mimeType == "video/mp4" ? (
                            <ImageGrid>
                              <video controls key={0}>
                                <source
                                  type={imgs[0].file.mimeType}
                                  src={
                                    "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                                    imgs[0].name
                                  }
                                />
                              </video>
                            </ImageGrid>
                          ) : (
                            <ImageGrid>
                              <img
                                className="img-fluid"
                                alt={imgs[0]}
                                src={
                                  "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                                  imgs[0].name
                                }
                                key={0}
                              />
                            </ImageGrid>
                          )
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                  {props.item.userHandle == props.userDetails.handle ? (
                    <ProMemberActionComment
                      item={props.item}
                      commentid={props.item.commentDocId}
                    ></ProMemberActionComment>
                  ) : (
                    ""
                  )}
                  <div
                    className="position-absolute"
                    style={{ right: "-20px", bottom: 0, marginRight: "10px" }}
                  >
                    <ProMemberCommentLikes
                      commentid={props.item.commentDocId}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row  mb-1 ml-6">
                <div>
                  <LikeProMemberComment
                    key={props.item.commentDocId}
                    item={props.item}
                  />
                  <a
                    onClick={(e) => focusOnReply(e, props.item.commentDocId)}
                    className="text-dark"
                    style={{ fontSize: "12px", cursor: "pointer" }}
                  >
                    {" "}
                    Reply
                  </a>
                </div>
              </div>
              {inputrply ? (
                <InputReply
                  key={props.item.commentDocId}
                  commentid={props.item.commentDocId}
                  postDetails={props.postDetails}
                />
              ) : (
                ""
              )}
            </div>
            <Replies
              key={props.item.commentDocId}
              item={props.item}
              postDetails={props.postDetails}
            />
          </>
        ) : (
          ""
        )
      ) : (
        <>
          <div className="d-flex flex-column mt-4 ml-3">
            <div className="comments d-flex  pr-2">
              <div className="d-flex position-relative">
                <div
                  className="d-inline-flex flex-row  flex-nowrap mb-1 mr-1"
                  style={{}}
                >
                  <img
                    src="https://picsum.photos/id/135/318/180?grayscale&blur=10"
                    className="mr-1 rounded-circle"
                    alt="..."
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                  <div
                    className="media-body px-3 py-2"
                    style={{
                      backgroundColor: "#f4f4f5",
                      borderRadius: "17px",
                    }}
                  >
                    <h5 className="m-0 placeholder-glow">
                      <span className=" text-muted  placeholder">
                        {props.userDetails.name}{" "}
                      </span>
                      <span className="ml-2 text-muted  placeholder">
                        {moment(props.item.createdAt).fromNow()}
                      </span>
                    </h5>
                    <p className="m-0  placeholder-glow ">
                      <span className=" text-muted  col-lg-6 placeholder "></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
  };
};

export default connect(mapStateToProps)(ProMemberComment);
