export const OPEN_COURSE_MODAL = 'OPEN_COURSE_MODAL'
export const CLOSE_COURSE_MODAL = 'CLOSE_COURSE_MODAL'

export const CREATE_COURSE_REQUEST = 'CREATE_COURSE_REQUEST';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const CREATE_COURSE_FAILURE = 'CREATE_COURSE_FAILURE';
export const CREATE_COURSE_RESET = 'CREATE_COURSE_RESET';

export const FETCH_COURSES_REQUEST = 'FETCH_COURSES_REQUEST';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAILURE = 'FETCH_COURSES_FAILURE';

export const UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const UPDATE_COURSE_FAILURE = 'UPDATE_COURSE_FAILURE';
export const UPDATE_COURSE_RESET = 'UPDATE_COURSE_RESET';

export const EDIT_COURSE = 'EDIT_COURSE';
export const NEW_COURSE = 'NEW_COURSE';


export const DELETE_COURSE_REQUEST = 'DELETE_COURSE_REQUEST';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_FAILURE = 'DELETE_COURSE_FAILURE';
export const DELETE_COURSE_RESET = 'DELETE_COURSE_RESET';