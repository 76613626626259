import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";

import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
  CardText,
  Button,
  Row,
  Col,
  Input,
  Image,
  Form,
} from "reactstrap";

import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { createCommunitySavePost } from "redux/Community/CommunityAction";
import { app, db } from "../../firebase";
import {
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  addDoc,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
  limit,
  or,
} from "firebase/firestore";
const SaveThePost = (props) => {
  const dispatch = useDispatch();
  const [saveDetails, setSaveDetails] = useState("");

  useEffect(() => {
    if (props.item && typeof props.userDetails.handle != "undefined") {
      const q = query(
        collection(db, "communitySavePosts"),
        where("postid", "==", props.item.postid),
        where("userHandle", "==", props.userDetails.handle)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let saveInfo = doc.data();
          if (saveInfo) {
            saveInfo["docid"] = doc.id;
          }
          setSaveDetails(saveInfo);
        });
      });
    }
  }, [props]);

  //Save the post
  const savePost = (e, data) => {
    if (data.saveid) {
      deleteDoc(doc(db, "communitySavePosts", data.saveid));
      setSaveDetails("");
    } else {
      const postSave = {
        userId: props.userDetails.userId,
        userHandle: props.userDetails.handle,
        postid: data.postid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      const savePost = doc(collection(db, "communitySavePosts"));
      setDoc(savePost, postSave);
    }
  };

  return (
    <>
      {saveDetails ? (
        <Button
          color=""
          className="px-5"
          onClick={(e) =>
            savePost(e, {
              postid: saveDetails.postid,
              saveid: saveDetails.docid,
            })
          }
          style={{
            fontSize: "16px",
          }}
        >
          <i className="text-primary fa fa-regular fa-bookmark font-weight-normal d-md-none d-lg-none"></i>
          <span className="d-none d-md-block d-lg-block">
            <i className="text-primary fa fa-regular fa-bookmark "></i> Save
          </span>
        </Button>
      ) : (
        <Button
          color=""
          className="px-5"
          onClick={(e) => savePost(e, { postid: props.item.postid })}
          style={{
            fontSize: "16px",
          }}
        >
          <i className="fa fa-regular fa-bookmark font-weight-normal d-md-none d-lg-none"></i>
          <span className="d-none d-md-block d-lg-block">
            <i className=" fa fa-regular fa-bookmark font-weight-normal "></i>{" "}
            Save
          </span>
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
  };
};

export default connect(mapStateToProps)(SaveThePost);
