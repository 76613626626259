import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import moment from 'moment-timezone';

import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Input,
    Image,
    Form,
} from "reactstrap";


import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { app, db } from "../../firebase";
import { getDocs, getDoc, setDoc, deleteDoc, addDoc, collection, query, where, onSnapshot, doc, orderBy, limit } from "firebase/firestore";
const LikeTheComment = (props) => {

    const dispatch = useDispatch()
 
    const [liked, setLike] = useState(0);
    const [likeid, setLikeId] =  useState('');
    

    useEffect(() => {
        if (props.item.length > 0 && props.item.commentDocId) {
            
            const getLike = async () => {
            const likeRef = collection(db, "communityLikeComment")
            const q = query(likeRef, where("commentid", "==", props.item.commentDocId));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {

                let likeInFo = doc.data()
                if (likeInFo.userHandle == props.userDetails.handle && likeInFo.status == 1) {
                    setLike(1)
                    setLikeId(doc.id)
                }
            });
                
            }
                getLike()
        }
     
    }, [])

    //Like the post
    const likeComment =  (e, data) => {
        setLike(data.status)
        if (!data.likeid) {
            if (data.status == 1) {
                  const param = {
                    userId: props.userDetails.userId,
                    userHandle: props.userDetails.handle,
                    commentid: data.commentid,
                    status: data.status,
                    createdAt:  new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                }
                const likecomment =  doc(collection(db, "communityLikeComment"));
                setDoc(likecomment, param);

                //Add docid field for added like 
                const createdLike = doc(db, 'communityLikeComment', likecomment.id);
                setDoc(createdLike, { docid: likecomment.id }, { merge: true });
                setLikeId(likecomment.id)
            }
        } else {
            const deleteItem = deleteDoc(doc(db, 'communityLikeComment', data.likeid));  
            setLikeId('')
        }
    }

    return (
         
        <>
            {
                liked == 1 ? <a  onClick={e => likeComment(e,{commentid:props.item.commentDocId, likeid: likeid, status: 0 })} className="text-primary mr-3" style={{ fontSize: '12px', cursor: 'pointer'}}>Like </a>
                    :
                    <a  onClick={ e => likeComment(e,{commentid:props.item.commentDocId, likeid: likeid, status: 1 })} className="text-dark mr-3" style={{ fontSize: '12px',cursor: 'pointer'  }}>Like </a> 
                  
            }
          
        </>

    )

}

const mapStateToProps = state  => {
    return {
        userDetails: state.loginAuth.userDetails,
    }  
}

export default connect(mapStateToProps)(LikeTheComment)
