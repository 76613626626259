import React, {useEffect, useState} from 'react'
import Header from 'components/Headers/Header'

import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    CardBody,
    FormGroup,
    Input,
    CardFooter,
    Button,
    Table,
    Badge
} from "reactstrap";
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import moment from 'moment';
import { fetchStudentRequestTutor,fetchStudentRequestTutorHistory, approveStudentRequestTutor, disapproveStudentRequestTutor,updateModalUploadTutorVideoFile,editTutorRequest } from 'redux/ManageTutorRequests/ManageTutorRequestsActions';
import UploadTutorVideoFileModal from "components/Modal/UploadTutorVideoFileModal";

const ManageTutorRequests = (props) => {

    const dispatch = useDispatch()

    useEffect(() => {
      
        dispatch(fetchStudentRequestTutor({ handle : props.userDetails.handle}))
        dispatch(fetchStudentRequestTutorHistory({ handle : props.userDetails.handle}))
        
    }, [])

    const modalUploadTutorVideoFile = props.updateModalUploadTutorVideoFileStatus.status;
    const toggleUploadTutorVideoFile = () => {
        dispatch(updateModalUploadTutorVideoFile(!props.updateModalUploadTutorVideoFileStatus.status));
    } 

    const [requestTutor, setRequestTutor] = useState([]);

    const [requestTutorHistory, setRequestTutorHistory] = useState([]);

    useEffect(() => {

        if(props.fetchStudentRequestTutor.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        }
        else{
            Swal.close()
        }

        if(props.fetchStudentRequestTutor.fetchstudentrequesttutor.length){
            setRequestTutor(props.fetchStudentRequestTutor.fetchstudentrequesttutor[0])
        }
    
    }, [props.fetchStudentRequestTutor])

    useEffect(() => {

        if(props.fetchStudentRequestTutorHistory.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        }
        else{
            Swal.close()
        }

        if(props.fetchStudentRequestTutorHistory.fetchstudentrequesttutorhistory.length){
            setRequestTutorHistory(props.fetchStudentRequestTutorHistory.fetchstudentrequesttutorhistory[0])
        }
    
    }, [props.fetchStudentRequestTutorHistory])


    const handleApproveRequestOnClick = (e, docId, mtr) => {
        e.preventDefault();

        dispatch(approveStudentRequestTutor({docId : docId, email : props.userDetails.email, mtr:mtr}))
    }

    const handleDisapproveRequestOnClick = (e, docId , coachTutorAvailabilityDocId, studentHandle, mtr) => {
        e.preventDefault();

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, disapprove it!'
          }).then((result) => {
            if (result.isConfirmed) {

                dispatch(disapproveStudentRequestTutor({docId : docId, coachTutorAvailabilityDocId : coachTutorAvailabilityDocId, studentHandle: studentHandle, mtr:mtr}))

            }
        })

        
    }


    useEffect(() => {

        if(props.approveStudentRequestTutor.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        
        }
        else{
            if(props.approveStudentRequestTutor.approvestudentrequesttutor.length){
            Swal.fire({
                title: 'Success!',
                text: 'Successfully approved request!',
                icon : 'success',
                timer: 3000
            }
            ).then(function (result) {

                dispatch(fetchStudentRequestTutor({ handle : props.userDetails.handle}))
                dispatch(fetchStudentRequestTutorHistory({ handle : props.userDetails.handle}))
                
            
            });
            }
            else if(typeof props.approveStudentRequestTutor.error !== "undefined"){

            Swal.fire({
                title: 'Failed!',
                text: 'Failed to approve request',
                icon : 'error',
                timer: 3000
            }
            ).then(function (result) {
                dispatch(fetchStudentRequestTutor({ handle : props.userDetails.handle}))
                dispatch(fetchStudentRequestTutorHistory({ handle : props.userDetails.handle}))
                

            
            });

            }
        }

    

    }, [props.approveStudentRequestTutor])

    useEffect(() => {

        if(props.disapproveStudentRequestTutor.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        
        }
        else{
            if(props.disapproveStudentRequestTutor.disapprovestudentrequesttutor.length){
            Swal.fire({
                title: 'Success!',
                text: 'Successfully disapproved request!',
                icon : 'success',
                timer: 3000
            }
            ).then(function (result) {

                dispatch(fetchStudentRequestTutor({ handle : props.userDetails.handle}))
                dispatch(fetchStudentRequestTutorHistory({ handle : props.userDetails.handle}))
                
            
            });
            }
            else if(typeof props.disapproveStudentRequestTutor.error !== "undefined"){

            Swal.fire({
                title: 'Failed!',
                text: 'Failed to disapprove request',
                icon : 'error',
                timer: 3000
            }
            ).then(function (result) {
                dispatch(fetchStudentRequestTutor({ handle : props.userDetails.handle}))
                dispatch(fetchStudentRequestTutorHistory({ handle : props.userDetails.handle}))
                

            
            });

            }
        }

    

    }, [props.disapproveStudentRequestTutor])

    const onClickEditTutorRequest = (e, meetingId) => {
        console.log(meetingId)
        e.preventDefault();
        dispatch(editTutorRequest({
            status : true,
            meetingId : meetingId
        }));

        dispatch(updateModalUploadTutorVideoFile(!props.updateModalUploadTutorVideoFileStatus.status));
    }

  return (
    <>
        <Header></Header>

        <Container className="mt--7" fluid>

        <Row className='mt-4'>
              <div className="col">
                  
                  <Card className="shadow">
                      <CardHeader className="border-1">
                          <div className='float-left'>
                              <h5 className="h3 mb-0">Manage Tutor Requests</h5>
                          </div>
                      </CardHeader>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Student</th>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestTutor.length ? 
                            
                                
                                    requestTutor.map((val, index) => {
                                   
                                    const ss = moment(val.time).utcOffset(0, false).add(1, 'hour').format('lll');
                                    const sss = moment(new Date()).utcOffset(0, false).format('lll')
                                     let sfrom =  moment(new Date(val.time)).utcOffset(0, false)
                                    return moment(val.time).utcOffset(0, false).format() > moment(new Date()).tz('America/New_York').format() ? <tr key={val.docId}>
                                        <td>{val.users.name}</td>
                                        <td>{moment(val.date).utcOffset(0, false).format("YYYY-MM-DD")}</td>
                                        <td>{sfrom.format("h:00 A") +'-'+ sfrom.add(1,'hours').format("h:00 A")}</td>
                                        <td>
                                            <Badge color="" className="badge-dot mr-4">
                                                <i className={val.status == "pending" ? "bg-warning" : "bg-success"} />
                                                {val.status}
                                            </Badge>
                                        </td>
                                        <td>
                                            {val.status  == "approved"
                                                ? 
                                                <>
                                                    
                                                   
                                                    {
                                                        !val.done ? <a href={val.hostMeetingLink} target='__blank' className='btn btn-primary'>Join Zoom Meeting</a> : ""

                                                    }
                                                         
                                                   
                                                    {
                                                        
                                                        
                                                        
                                                        val.hostMeetingLink != "" &&  moment(new Date()).utcOffset(0, false).format() >= moment(val.time).utcOffset(0, false).add(1, 'hour').format() 
                                                            ?
                                                               <Button color="success" onClick={(e) => onClickEditTutorRequest(e,val.meetingId)}>{val.video ?  "View Recorded ": "Done" }</Button>
                                                                    
                                                        : ""
                                                    }
                                                
                                                
                                                </>
                                                
                                            
                                               
                                                
                                                :
                                                <>
                                                    <button onClick={e => handleApproveRequestOnClick(e,val.docId, val)} className='btn btn-info'>Approve</button>
                                                    <button onClick={e => handleDisapproveRequestOnClick(e,val.docId,val.coachTutorAvailabilityDocId, val.studentHandle, val)} className='btn btn-danger'>Disapprove</button>
                                                </>
                                            
                                            }
                                            
                                        </td>
                                    </tr>
                                    : ""
                                })
                            
                            : 
                            <tr>
                                <td colspan="5" className='text-center'>No Requests</td>
                            </tr>
                            }
                        </tbody>
                      </Table>
                      <CardFooter >


                      </CardFooter>
                  </Card>

                  <Card className="shadow mt-4">
                      <CardHeader className="border-1">
                          <div className='float-left'>
                              <h5 className="h3 mb-0">History</h5>
                          </div>
                      </CardHeader>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Student</th>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestTutorHistory.length ? 
                            
                                
                                requestTutorHistory.map((val, index) => {
                                     let sfrom =  moment(new Date(val.time)).utcOffset(0, false)
                                    return  ( moment(val.time).utcOffset(0, false).format() < moment(new Date()).tz('America/New_York').format() || val.status.includes("disapproved") ) &&  !val.status.includes("canceled") ? <tr key={val.docId}>
                                        <td>{val.users.name}</td>
                                        <td>{moment(val.date).utcOffset(0, false).format("YYYY-MM-DD")}</td>
                                        <td>{sfrom.format("h:00 A") +'-'+ sfrom.add(1,'hours').format("h:00 A")}</td>
                                        <td>
                                            <Badge color="" className="badge-dot mr-4">
                                                <i className={val.status == "approved" ? "bg-success" : "bg-danger"} />
                                                {val.status == "pending" ? "expired" : val.status == "approved" ? "success" : val.status}
                                            </Badge>
                                        </td>

                                    </tr>
                                    : ""
                                })
                            
                            : 
                            <tr>
                                <td colspan="5" className='text-center'>No History</td>
                            </tr>
                            }
                        </tbody>
                      </Table>
                      <CardFooter >


                      </CardFooter>
                  </Card>
                  
              </div>
          </Row>

          </Container>
          <UploadTutorVideoFileModal modalUploadTutorVideoFile={modalUploadTutorVideoFile} toggleUploadTutorVideoFile={toggleUploadTutorVideoFile} />
    </>
  )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchStudentRequestTutor : state.fetchStudentRequestTutor,
        approveStudentRequestTutor : state.approveStudentRequestTutor,
        disapproveStudentRequestTutor : state.disapproveStudentRequestTutor,
        fetchStudentRequestTutorHistory: state.fetchStudentRequestTutorHistory,
        updateModalUploadTutorVideoFileStatus: state.updateModalUploadTutorVideoFileStatus,
        editTutorRequest: state.editTutorRequest
    }
      
}

export default connect(mapStateToProps)(ManageTutorRequests)