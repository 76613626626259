import React, {useState, useEffect} from 'react'
import Header from 'components/Headers/Header'
import {
    Card,
    CardTitle,
    CardText,
    CardHeader,
    Container,
    Row,
    Col,
    CardBody,
    Pagination,
    FormGroup,
    PaginationItem,
    PaginationLink,
    CardFooter,
    Button,
    Table,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Media
} from "reactstrap";
// import moment from 'moment';
import ReactDatetime from "react-datetime";
import { useDispatch } from 'react-redux';
import { fetchAvailableSchedule } from 'redux/ScheduleTutor/ScheduleTutorActions';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import { createRequestTutor, createRequestTutorReset, fetchRequestTutor, cancelRequestTutor, cancelRequestTutorReset, fetchRequestTutorHistory, fetchCoaches } from 'redux/ScheduleTutor/ScheduleTutorActions';
import {buyTutoringHours} from "redux/Payments/PaymentsActions"
import BuyTutoringHoursModal from 'components/Modal/BuyTutoringHoursModal';
import moment from 'moment-timezone';
import { getUserData } from 'redux/UserAuthentication/LoginActions';
import  Select  from 'react-select';
import {customDropdownWithBorder} from "util/reactCustomStyles"
const ScheduleTutor = (props) => {

    const dispatch = useDispatch()

    let twoDaysBefore = moment().add( 1, 'day' );
    
    let valid = function( current ){
        return current.isAfter( twoDaysBefore );
    };

    let weeknow = moment(new Date().toISOString()).add(1, 'week').format(); 


    const [time, setTime] = useState();
    const [date, setDate] = useState();
    const [modalRequestTutor, setModalRequestTutor] = useState(false);
    const [modalRequestTutorData, setmodalRequestTutorData] = useState(false);

    const [getUsedFreeHourDetails, setFreeHour] = useState([]);
    // const [list, setList] = useState([]);
    const [page, setPage] = useState(1);

    

    // const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const showNext = (e,item) => {
        e.preventDefault();
        
        if(availableSchedule.length !== 0) {
            dispatch(fetchAvailableSchedule({route:"Next" , item:item.item, currentDate : moment(new Date()).add( 2, 'day' ).startOf('day').utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss[Z]')}))
            setAvailableSchedule(props.fetchAvailableSchedule.fetchavailableschedule[0])
            setPage(page + 1)
        } 
    };

    const showPrevious = ( e,item ) => {
        e.preventDefault();
        if(availableSchedule.length !== 0) {
            dispatch(fetchAvailableSchedule({route:"Previous", item:item.item , currentDate : moment(new Date()).add( 2, 'day' ).startOf('day').utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss[Z]')}))
            setAvailableSchedule(props.fetchAvailableSchedule.fetchavailableschedule[0])
            setPage(page - 1)
        } 
    };


    const handleDateOnChange = (e) => {
        const paramDate = e.toJSON();
        
        const paramDateCon = new Date(paramDate)

        setDate(paramDateCon)
        setTime(paramDateCon)
    }

    const handleTimeOnChange = (e) => {
        const paramDate = e.toJSON();
        setTime(new Date(paramDate))
    
    }

    const handleRetrieveAvailableScheduleOnClick = (e) => {
        
        dispatch(fetchAvailableSchedule({ route:"", currentDate : moment(time).utcOffset(0, true).format()}))

    }

    const [availableSchedule, setAvailableSchedule] = useState([]);
    const [requestTutor, setRequestTutor] = useState([]);
    const [requestTutorHistory, setRequestTutorHistory] = useState([]);

    useEffect(() => {

        if(props.fetchAvailableSchedule.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()  
                }
            });
        }
        else{
            Swal.close()
        }

        if(props.fetchAvailableSchedule.fetchavailableschedule.length){
            // setAvailableSchedule(props.fetchAvailableSchedule.fetchavailableschedule[0].sort(compareDatetime).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage))

            if(coachFilter){
                
                const filterCondition = (item) => item.users.handle == coachFilter;
        
                setAvailableSchedule(props.fetchAvailableSchedule.fetchavailableschedule[0].filter(filterCondition).sort(compareDatetime).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage))

            }
            else{

        
                setAvailableSchedule(props.fetchAvailableSchedule.fetchavailableschedule[0].sort(compareDatetime).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage))
                
            }
            
            setTotalPage(Math.ceil(props.fetchAvailableSchedule.fetchavailableschedule[0].length / itemsPerPage));
        }
    
    }, [props.fetchAvailableSchedule])

    useEffect(() => {

        if(props.fetchAvailableSchedule.fetchavailableschedule.length){
            // setAvailableSchedule(props.fetchAvailableSchedule.fetchavailableschedule[0].sort(compareDatetime).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage))

            if(coachFilter){
                
                const filterCondition = (item) => item.users.handle == coachFilter;
        
                setAvailableSchedule(props.fetchAvailableSchedule.fetchavailableschedule[0].filter(filterCondition).sort(compareDatetime).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage))

            }
            else{

        
                setAvailableSchedule(props.fetchAvailableSchedule.fetchavailableschedule[0].sort(compareDatetime).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage))
                
            }
            
            // setTotalPage(Math.ceil(props.fetchAvailableSchedule.fetchavailableschedule[0].length / itemsPerPage));
        }
    
    }, [currentPage])

    const compareDatetime = (a, b) => {
        const dateA = new Date(a.time);
        const dateB = new Date(b.time);
      
        return dateA - dateB;
    };

    useEffect(() => {

        if(props.fetchRequestTutor.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        }
        else{
            Swal.close()
        }

        if(props.fetchRequestTutor.fetchrequesttutor.length){
            setRequestTutor(props.fetchRequestTutor.fetchrequesttutor[0])
        }
    
    }, [props.fetchRequestTutor])


    useEffect(() => {

        if(props.fetchRequestTutorHistory.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        }
        else{
            Swal.close()
        }

        if(props.fetchRequestTutorHistory.fetchrequesttutorhistory.length){
            setRequestTutorHistory(props.fetchRequestTutorHistory.fetchrequesttutorhistory[0])
        }
    
    }, [props.fetchRequestTutorHistory])

      const toggleRequestTutor = () => {
          setModalRequestTutor(!modalRequestTutor);
          setmodalRequestTutorData(!modalRequestTutorData);
    };

    const requestTutorOnClick = (e, data) => {
        e.preventDefault()
        setModalRequestTutor(data.modal)
        setmodalRequestTutorData(data.docs)
    }
    
    const handleRequestTutorOnClick = (e, doc, type) => {
        e.preventDefault()
        setModalRequestTutor(!modalRequestTutor);
        //Pass type of request
        doc.type = type
        dispatch(createRequestTutor({handle : props.userDetails,docs:doc}))
    }

    // useEffect(() => {

    //     if(props.createRequestTutor.loading){
    //         Swal.fire({
    //             allowOutsideClick : false,
    //             didOpen: () => {
    //                 Swal.showLoading()
                    
    //             }
    //         });
    //     }
    //     else{
    //         Swal.close()
    //     }

    //     if(props.createRequestTutor.createrequesttutor.length){
    //         setAvailableSchedule(props.createRequestTutor.createrequesttutor[0])
    //     }
    
    // }, [props.createRequestTutor])

    useEffect(() => {
        // console.log(new Date())
        // console.log(moment(new Date()).utcOffset(0, true).add( 2, 'day' ).startOf('day').format())
        dispatch(fetchRequestTutor({ studentHandle : props.userDetails.handle}))
        dispatch(fetchRequestTutorHistory({ studentHandle : props.userDetails.handle}))
        dispatch(fetchAvailableSchedule({ route:"",  currentDate : moment(time).format('YYYY-MM-DDTHH:mm:ss[Z]')}))
        
        dispatch(fetchCoaches({}))
        
    }, [])
    
    useEffect(() => {

        if(props.createRequestTutor.loading){
          Swal.fire({
            allowOutsideClick : false,
            didOpen: () => {
              Swal.showLoading()
              
            }
          });
      
        }
        else{
          if(props.createRequestTutor.createrequesttutor.length){
            Swal.fire({
              title: 'Success!',
              text: 'Successfully sent request!',
              icon : 'success',
              timer: 3000
            }
            ).then(function (result) {
                //   dispatch(fetchCoachTutorAvailability({ handle : props.userDetails.handle}))
                dispatch(getUserData({ onLogin: true }))
                dispatch(fetchRequestTutor({ studentHandle : props.userDetails.handle}))
                dispatch(fetchRequestTutorHistory({ studentHandle : props.userDetails.handle}))
                dispatch(fetchAvailableSchedule({route:"",currentDate : moment(time).utcOffset(0, true).format()}))
                dispatch(createRequestTutorReset())

               
            
            });
          }
          else if(typeof props.createRequestTutor.error !== "undefined"){
    
            Swal.fire({
              title: 'Failed!',
              text: 'Failed to send request',
              icon : 'error',
              timer: 3000
            }
            ).then(function (result) {
            //   dispatch(fetchCoachTutorAvailability({ handle : props.userDetails.handle}))
                dispatch(fetchRequestTutor({ studentHandle : props.userDetails.handle}))
                dispatch(fetchRequestTutorHistory({ studentHandle : props.userDetails.handle}))
                dispatch(fetchAvailableSchedule({ route:"", currentDate : moment(time).utcOffset(0, true).format()}))
                dispatch(createRequestTutorReset())

                dispatch(getUserData({ onLogin: true }))
            
            });
    
          }
        }
    
        
    
      }, [props.createRequestTutor])

    const handleCancelRequestOnClick = (e, doc) => {
        e.preventDefault()
        console.log(doc)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
      
                dispatch(cancelRequestTutor({handle : props.userDetails,docs:doc}))
      
            }
        })

        
    }

    useEffect(() => {

        if(props.cancelRequestTutor.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        
        }
        else{
            if(props.cancelRequestTutor.cancelrequesttutor.length){
            Swal.fire({
                title: 'Success!',
                text: 'Successfully cancelled request!',
                icon : 'success',
                timer: 3000
            }
            ).then(function (result) {
            //   dispatch(fetchCoachTutorAvailability({ handle : props.userDetails.handle}))
                dispatch(fetchRequestTutor({ studentHandle : props.userDetails.handle}))
                dispatch(fetchRequestTutorHistory({ studentHandle : props.userDetails.handle}))
                dispatch(fetchAvailableSchedule({ route:"", currentDate : moment(time).utcOffset(0, true).format()}))
                dispatch(cancelRequestTutorReset())

                dispatch(getUserData({ onLogin: true }))
            
            });
            }
            else if(typeof props.cancelRequestTutor.error !== "undefined"){

            Swal.fire({
                title: 'Failed!',
                text: 'Failed to cancel request',
                icon : 'error',
                timer: 3000
            }
            ).then(function (result) {
            //   dispatch(fetchCoachTutorAvailability({ handle : props.userDetails.handle}))
                dispatch(fetchRequestTutor({ studentHandle : props.userDetails.handle}))
                dispatch(fetchRequestTutorHistory({ studentHandle : props.userDetails.handle}))
                dispatch(fetchAvailableSchedule({ route:"", currentDate : moment(time).utcOffset(0, true).format()}))
                dispatch(cancelRequestTutorReset())

            
            });

            }
        }

    

    }, [props.cancelRequestTutor])

    const modalBuyTutoringHoursModal = props.buyTutoringHoursModalStatus.status;

    const toggleBuyTutoringHoursModal = () =>{
        dispatch(buyTutoringHours(!props.buyTutoringHoursModalStatus.status));
        
    } 

    const [coachFilter, setCoachFilter] = useState([]);
    const getCoach = (data) => {
    
        setCoachFilter(data.value)
    }

    const [coaches, setCoaches] = useState([]);

    useEffect(() => {
        
        if(props.fetchCoaches.fetchcoaches.length){

            const coachesArr = props.fetchCoaches.fetchcoaches[0];
            
            coachesArr.unshift({value:"", label : "ALL"})
            
            setCoaches(coachesArr)

        }

    }, [props.fetchCoaches])
    
    useEffect(() => {
        
       
        if(props.fetchAvailableSchedule.fetchavailableschedule.length){
            
            if(coachFilter){
                
                const filterCondition = (item) => item.users.handle == coachFilter;
        
                setAvailableSchedule(props.fetchAvailableSchedule.fetchavailableschedule[0].filter(filterCondition).sort(compareDatetime).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage))

            }
            else{

        
                setAvailableSchedule(props.fetchAvailableSchedule.fetchavailableschedule[0].sort(compareDatetime).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage))
                
            }
            
            
        }

    
    }, [coachFilter])

  return (
    <>
        <Header></Header>

        <Container className="mt--7" fluid>
            {/* <Row>
                <div className="col">
                    
                    <Card className="shadow">
                        <CardHeader className="border-1">
                            <div className='float-left'>
                                <h5 className="h3 mb-0">Schedule Tutor</h5>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <h6 className="heading-small text-muted mb-4">
                            Retrieve Available Schedule
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                        >
                                        Date <span className='text-danger'>*</span>
                                        </label>
                                        <ReactDatetime onChange={e => handleDateOnChange(e)} isValidDate={ valid } timeFormat={false} className="w-100" inputProps={{placeholder:"Select Date"}}/>
                                    </FormGroup>
                                    </Col>
                                    <Col lg="6"></Col>
                                    <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                        >
                                        Time <span className='text-danger'>*</span>
                                        </label>
                                        <ReactDatetime value={time} onChange={e => handleTimeOnChange(e)} timeConstraints={{minutes:{ min: 0 ,max:0, step: 0}}} dateFormat={false} className="w-100" inputProps={{placeholder:"Select time"}}/>
                                    </FormGroup>
                                    
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>

                        <CardFooter >

                            <Button color="primary" onClick={e => handleRetrieveAvailableScheduleOnClick(e)}  type='submit' >
                                Retrieve
                            </Button>

                        </CardFooter>
                    </Card>
                    
                </div>
            </Row> */}

            <Row className='mt-4'>
              <div className="col">
                {/* <Row>

                    <Col md="12">
                        <h2 className="mb-2 text-primary">
                        FILTERS
                        </h2>
                    </Col>

                </Row> */}

                  
                  

                  <Card className="shadow">
                      <CardHeader className="border-1">
                          <div className='float-left'>
                              <h5 className="h3 mb-0">Available Schedule </h5>
                                  <h5 className="h3 mb-0 text-yellow">{props.userDetails.tutorHours} Premium Tutoring Hours Remaining</h5>
                                   <h5 className="h3 mb-0 text-yellow">
                                      {
                                        props.userDetails.freehour && moment(props.userDetails.usedFreeHourTime).format() < weeknow 
                                        ?  <>30min/week already used {moment(props.userDetails.usedFreeHourTime).fromNow()} </>
                                
                                        :
                                        <>30 min/week free tutoring available </>  
                                      }
                                  </h5>
                          </div>

                          

                      </CardHeader>
                        <Row>
                    
                            <Col md="5">
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="12">
                                            <h2 className="mb-2 text-primary mt-3">
                                                FILTERS
                                            </h2>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                            <label className="form-control-label text-default " htmlFor="customCheck1">
                                                COACH
                                            </label>

                                            <Select 
                                                menuPortalTarget={document.body}
                                                placeholder="Select Coach" 
                                                options={coaches} 
                                                styles={customDropdownWithBorder }  
                                                onChange={getCoach}
                                                value={coaches.find((option) => option.value === coachFilter)}
                                            />

                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </div>
                            </Col>

                        </Row>

                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Coach</th>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>

                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {availableSchedule.length ? 
                            
                                
                                availableSchedule.map((val, index) => {
                                    let sfrom =  moment(new Date(val.time)).utcOffset(0, false)
                                    return <tr>
                                        <td><Media className="align-items-center">
                                    <a
                                      className="avatar rounded-circle mr-3 "
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      {(typeof val.users.profilePicture != "undefined") ?

                  
                                        <div className="card-profile-div-sm card-profile-div-md">
                                        <div className="card-profile-image">
                                          
                                            <div className="profile-picture-sm profile-picture-md" style={{backgroundImage: 'url("https://storage.googleapis.com/pre-pa-tracker.appspot.com/profile-picture/' + val.users.profilePicture+'")'}}></div>
                                          
                                        </div>
                                        </div>
                                      :
                                      <div className="rounded-circle profile-letter-all-users">{val.users.name.trim()[0]}</div> }
                                    </a>
                                    <Media>
                                      <span className="mb-0 text-sm">
                                        {val.users.name}
                                      </span>
                                    </Media>
                                  </Media></td>
                                        <td>{moment(val.date).utcOffset(0, false).format("YYYY-MM-DD")}</td>
                                        <td>{sfrom.format("h:00 A") +'-'+ sfrom.add(1,'hours').format("h:00 A")}</td>
                                        <td>
                                             <button onClick={e => requestTutorOnClick(e, {modal: true , docs : val })} className='btn btn-info'>Request Tutor</button>
                                            {
                                                // props.userDetails.tutorHours < 1 ? <a className="btn btn-primary mt-2" onClick={(e) => {e.preventDefault(); toggleBuyTutoringHoursModal()}}>GET TUTORING HOURS</a>

                                                // : ""
                                                
                                                    // <button onClick={e => handleRequestTutorOnClick(e, val)} className='btn btn-info'>Request Tutor</button>
                                            }
                                            
                                            
                                        </td>
                                    </tr>

                                })
                            
                            : 
                            <tr>
                                <td colspan="4" className='text-center'>No Available Schedule</td>
                            </tr>
                            }
                        </tbody>
                      </Table>
                      <CardFooter className="py-4">
                        <nav aria-label="...">
                            <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                            >
                            <PaginationItem className={currentPage === 1 ? 'disabled' : ''}>
                                <PaginationLink
                                href="#pablo"
                                onClick={(e) =>  { e.preventDefault(); handlePageClick(currentPage - 1)}}
                                tabIndex="-1"
                                >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                                </PaginationLink>
                            </PaginationItem>
                            {/* <PaginationItem className="active">
                                <PaginationLink
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                >
                                1
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationLink
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                >
                                2 <span className="sr-only">(current)</span>
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationLink
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                >
                                3
                                </PaginationLink>
                            </PaginationItem> */}
                            <PaginationItem className={currentPage === totalPage ? 'disabled' : ''}>
                                <PaginationLink
                                href="#pablo"
                                onClick={(e) =>  { e.preventDefault(); handlePageClick(currentPage + 1)}}
                                >
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                                </PaginationLink>
                            </PaginationItem>
                            </Pagination>
                        </nav>
                    </CardFooter>
                  </Card>
                  
              </div>
          </Row>


          <Row className='mt-4'>
              <div className="col">
                  
                  <Card className="shadow">
                      <CardHeader className="border-1">
                          <div className='float-left'>
                              <h5 className="h3 mb-0">Requests</h5>
                          </div>
                      </CardHeader>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Coach</th>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestTutor.length ? 
                            
                                
                                requestTutor.map((val, index) => {
                                   
                                     let sfrom =  moment(new Date(val.time)).utcOffset(0, false)

                                    return moment(val.time).utcOffset(0, false).format() > moment(new Date()).tz('America/New_York').format() ? <tr>
                                        <td>{val.users.name}</td>
                                        <td>{moment(val.date).utcOffset(0, false).format("YYYY-MM-DD")}</td>
                                        <td>{sfrom.format("h:00 A") +'-'+ sfrom.add(1,'hours').format("h:00 A")}</td>
                                        <td>
                                            <Badge color="" className="badge-dot mr-4">
                                                <i className={val.status == "pending" ? "bg-warning" : "bg-success"} />
                                                {val.status}
                                            </Badge>
                                        </td>
                                        <td>
                                            {val.status  == "approved"
                                                ? 
                                                
                                                <a href = {val.studentMeetingLink} target='__blank' className='btn btn-primary'>Join Zoom Meeting</a>
                                                
                                                :
                                                <>
                                                    <button onClick={e => handleCancelRequestOnClick(e, val)} className='btn btn-danger'>Cancel Request</button>
                                                </>
                                            
                                            }
                                        </td>
                                            
                                    </tr>
                                        
                                    : ""
                                })
                            
                            : 
                            <tr>
                                <td colspan="5" className='text-center'>No Requests</td>
                            </tr>
                            }
                        </tbody>
                      </Table>
                      <CardFooter >


                      </CardFooter>
                  </Card>
                  
              </div>
          </Row>

          <Row className='mt-4 mb-5'>
              <div className="col">
                  
                  <Card className="shadow">
                      <CardHeader className="border-1">
                          <div className='float-left'>
                              <h5 className="h3 mb-0">History</h5>
                          </div>
                      </CardHeader>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Coach</th>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>

                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestTutorHistory.length ? 
                            
                                
                                    requestTutorHistory.map((val, index) => {
                                     let sfrom =  moment(new Date(val.time)).utcOffset(0, false)
                                                                          
                                    return moment(val.time).utcOffset(0, false).format() < moment(new Date()).tz('America/New_York').format() || val.status.includes("canceled") || val.status.includes("disapproved") ?<tr>
                                        <td>{val.users.name}</td>
                                        <td>{moment(val.date).utcOffset(0, false).format("YYYY-MM-DD")}</td>
                                        <td>{sfrom.format("h:00 A") +'-'+ sfrom.add(1,'hours').format("h:00 A")}</td>
                                        <td>
                                            <Badge color="" className="badge-dot mr-4">
                                                <i className={val.status == "canceled" ? "bg-warning" : val.status == "disapproved" ?  "bg-danger" : "bg-success" }/>
                                                {val.status == "approved" ? "success" : val.status == "pending" ? "expired": val.status}
                                            </Badge>
                                        </td>
                                    </tr> : ""

                                })
                            
                            : 
                            <tr>
                                <td colspan="4" className='text-center'>No Available History</td>
                            </tr>
                            }
                        </tbody>
                      </Table>
                      <CardFooter >


                      </CardFooter>
                  </Card>
                  
              </div>
          </Row>

          </Container>
          
          <Modal className="modal-dialog-centered" size="lg" isOpen={modalRequestTutor} toggle={toggleRequestTutor} >
            <ModalHeader >Select Tutor Hours  </ModalHeader>
              <ModalBody >
                 
                <Row>
                    <Col sm="6">
                        <Card body>
                        <CardTitle tag="h5">
                            Premium Tutoring Hours 
                        </CardTitle>
                        <CardText>
                            Use your remaining premium tutoring hours to request a schedule
                              </CardText>
                        {
                            props.userDetails.tutorHours < 1 ? <a className="btn btn-primary mt-2" onClick={(e) => {e.preventDefault(); toggleBuyTutoringHoursModal()}}>GET TUTORING HOURS</a>
                                    : 
                            <Button onClick={e => handleRequestTutorOnClick(e, modalRequestTutorData, 'remaining_hour' ) } >
                                {props.userDetails.tutorHours} Hours Remaining
                            </Button>
                        }
                            
                       
                        </Card>
                    </Col>
                    <Col sm="6">
                        <Card body>
                        <CardTitle tag="h5">
                            30 Minutes Tutoring a Week
                        </CardTitle>
                        <CardText>
                            Only choose the 30-minute bonus session if it has already been approved.
                            These sessions should be reserved for quarterly check-ins or for general coaching when you have exhausted your premium hours.
                        </CardText>
                            {
                                 
                                
                                   
                                props.userDetails.freehour && moment(props.userDetails.usedFreeHourTime).format() < weeknow
                                         ?
                                      <Button disabled >
                                         Used {moment(props.userDetails.usedFreeHourTime).fromNow()}
                                      </Button>
                                    
                                  
                                
                            :  <Button  onClick={e => handleRequestTutorOnClick(e, modalRequestTutorData, 'free')} >
                                 30min this week 
                                      </Button>
                                      
                           
                           
                        }
                       
                        </Card>
                    </Col>
                </Row>
                </ModalBody>
                <ModalFooter>
                  
                </ModalFooter>

           </Modal>

        <BuyTutoringHoursModal modalBuyTutoringHoursModal={modalBuyTutoringHoursModal} toggleBuyTutoringHoursModal={toggleBuyTutoringHoursModal}/>
    </>
  )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchAvailableSchedule : state.fetchAvailableSchedule,
        createRequestTutor: state.createRequestTutor,
        fetchRequestTutor : state.fetchRequestTutor,
        cancelRequestTutor : state.cancelRequestTutor,
        fetchRequestTutorHistory : state.fetchRequestTutorHistory,
        buyTutoringHoursModalStatus : state.buyTutoringHoursModalStatus,
        fetchCoaches : state.fetchCoaches,
    }
      
}

export default connect(mapStateToProps)(ScheduleTutor)