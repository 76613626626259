import React,{useEffect, useState} from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
  } from "reactstrap";

import SecondaryEssaysAccordion from 'components/Accordion/SecondaryEssaysAccordion';
import { fetchResponseForSecondaryEssays } from 'redux/SecondaryEssays/SecondaryEssaysActions';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
const SecondaryEssaysPages = (props) => {
    
    const dispatch = useDispatch();
    const [indexSelectedSchoolForWriteSecondaryEssays, setIndexSelectedSchoolForWriteSecondaryEssays] = useState(0);
    const [secondaryEssays, setSecondaryEssays] = useState([]);
    useEffect(() => {
        
        setIndexSelectedSchoolForWriteSecondaryEssays(props.secondaryEssays.findIndex(obj => obj.schoolSearchId === props.selectedSchoolForWriteSecondaryEssays));
        
        dispatch(fetchResponseForSecondaryEssays({schoolSearchId:props.selectedSchoolForWriteSecondaryEssays, userId:props.userDetails.userId}))

    }, []);

    useEffect(() => {

 
        const secondaryEssaysArr = Object.entries(props.secondaryEssays[indexSelectedSchoolForWriteSecondaryEssays].secondaryEssays).sort((a, b) => {
            const yearA = parseInt(a[0].split("-")[0].trim());
            const yearB = parseInt(b[0].split("-")[0].trim());
          
            return yearB - yearA;
        }).slice(0, 1);
        
        setSecondaryEssays(secondaryEssaysArr)

        

    }, [indexSelectedSchoolForWriteSecondaryEssays]);

    const StringToHTML = ({ htmlString }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
      };


    
    return (
    <>
        <Row className="mb-5 ">
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                
                
                <CardHeader className='card-school-cover-img pt-5 pb-5'  style={{backgroundImage: `url(https://storage.googleapis.com/pre-pa-tracker.appspot.com/school-search-images/`+props.secondaryEssays[indexSelectedSchoolForWriteSecondaryEssays].medicalSchool.imageUrl+`)`}}>
                    
                    <div className="" style={{}}>
                        <div className="card-profile-image text-center">
                            <div className='d-flex justify-content-center'>
                            <div className="school-card-img margin-auto" style={{backgroundImage: `url(https://storage.googleapis.com/pre-pa-tracker.appspot.com/school-search-images/`+props.secondaryEssays[indexSelectedSchoolForWriteSecondaryEssays].medicalSchool.crestUrl+`)`, backgroundColor:"white"}}></div>
                            
                            </div>
                            <br/>
                            <h1 className="mb-0 text-white">{props.secondaryEssays[indexSelectedSchoolForWriteSecondaryEssays].medicalSchool.overview.schoolName} <span className='text-warning'>Secondary Essays</span></h1>
                        </div>
                    </div>
                    
                    
                </CardHeader>
                
                </Card>
            </Col>
        </Row>
        {
            secondaryEssays.map((values, key)=>{
                
                return <Row className="mb-5 " key={key}>
                    <Col className="order-xl-2 mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                        
                        
                        <CardHeader className='bg-default pt-4 pb-4'>
                            
                            
                            <h2 className="mb-0 text-white"><span className='text-info'>{values[0]}</span></h2>
                            
                        </CardHeader>
                        <CardBody className="pt-0 pt-md-4 ">

                            <Row className="">
                                <Col lg="12">
                                <h2 className='mb-4 text-dark'>Here are {props.secondaryEssays[indexSelectedSchoolForWriteSecondaryEssays].medicalSchool.overview.schoolName} secondary questions.</h2>
                                <StringToHTML htmlString={values[1]} />
                                </Col>                
                            </Row>
                            <div className=''>
                                <hr className="my-4" />
                                <h2 className='mb-4 text-dark'>Feel free to provide your answers here.</h2>
                                <SecondaryEssaysAccordion htmlStringEssays={values[1]} secondaryEssaysId={values[0]} schoolSearchId={props.selectedSchoolForWriteSecondaryEssays}></SecondaryEssaysAccordion>
                            </div>
                        </CardBody>
                        </Card>
                    </Col>
                </Row>

            })
        }
    </>
  )
}
const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        
        
    }
      
}
export default connect(mapStateToProps)(SecondaryEssaysPages)