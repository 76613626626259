import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { app, db } from "../../firebase";
import { collection,getDocs, query, where, onSnapshot, doc} from "firebase/firestore";

const ProMemberPostComments = (props) => {
    const dispatch = useDispatch()
    const [comments, setCommentCounts] = useState([])
    const [replies, setReplyCounts] = useState([])
 
    //Real Time Change
    useEffect(() => {
        const cc = onSnapshot(
            collection(db, "communityProMemberComments"), where("postid", "==", props.postid),
            (snapshot) => {
                let commentCount = [];
                snapshot.docs.forEach((doc) => { 
                    let comment = doc.data()
                    if (comment.postid == props.postid) {
                        commentCount.push(doc.data())
                    } 
                })
                setCommentCounts(commentCount);
            },
            (error) => {
                console.log(error)
            }
        );
        return () => {
            cc()
        }
    }, [props.postid])

     const focusOnComment = (e,id) =>{
        // document.getElementById("commentInput-"+id).focus();  
    }

    return (
         
        <>
            {
                comments ?
                    <>
                        { comments.length ? <> <a  onClick={e => focusOnComment(e, props.postid)} className="text-decoration-none text-dark ">{comments.length} Comment</a> </> : ''} 
                    </> 
                : ''
            }     
          
        </>

    )

}

const mapStateToProps = state  => {
    return {
        userDetails: state.loginAuth.userDetails,
    }  
}

export default connect(mapStateToProps)(ProMemberPostComments)
