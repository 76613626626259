import React, { useEffect, useState } from 'react'
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { useDispatch } from 'react-redux';
import { fetchWorkActivity, updateWorkActivityModal, editWorkActivity, deleteWorkActivity, deleteWorkActivityReset, updateImportantWorkActivity } from 'redux/WorkActivities/WorkActivitiesActions';
import Swal from 'sweetalert2'
import { connect } from 'react-redux';

const WorkActivitiesTable = (props) => {
    
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);

  
    useEffect(() => {
        
        const data = {
            route : "",
            userId: props.userDetails.userId
        }
       
        dispatch(fetchWorkActivity(data))
        
        
    }, [])
    
    useEffect(() => {
        
        setList(props.fetchWorkActivities.workactivities.sort((a, b) => a.isActive - b.isActive).slice(0, 5))
        
        
    }, [props.fetchWorkActivities])
        
    
    if(props.deleteWorkActivity.workactivity.length && typeof props.deleteWorkActivity.error === "undefined"){
        
        Swal.fire({
            title: 'Deleted!',
            text: 'Successfully deleted Experience!',
            icon : 'success',
            timer: 3000      
          }
        )
        dispatch(deleteWorkActivityReset());
        
        
      }

    else if(typeof props.deleteWorkActivity.error !== "undefined"){
        
        Swal.fire({
            title:'Failed!',
            text:'Failed to delete Experience!',
            icon :'error',
            timer: 3000 
            }
        )            
        dispatch(deleteWorkActivityReset());
    }
    
    
    const onClickEditWorkActivity = (e,workactivityId) => {
        e.preventDefault();
        dispatch(editWorkActivity({
            status : true,
            workactivityId : workactivityId
        }));

        dispatch(updateWorkActivityModal(!props.updateWorkActivityModalStatus.status));
    }

    const onClickDeleteWorkActivity = (e,workactivityId) => {
        e.preventDefault();
        

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteWorkActivity({
                    docId : workactivityId,
                    userId : props.userDetails.userId
                }));

            }
          })
    }
    
    const showNext = (e,item) => {
        e.preventDefault();
        
        if(list.length !== 0) {
            dispatch(fetchWorkActivity({route:"Next" , item:item.item, userId: props.userDetails.userId}))
            setList(props.fetchWorkActivities.workactivities.sort((a, b) => a.isActive - b.isActive))
            setPage(page + 1)
        } 
    };

    const showPrevious = ( e,item ) => {
        e.preventDefault();
        if(list.length !== 0) {
            dispatch(fetchWorkActivity({route:"Previous", item:item.item, userId: props.userDetails.userId}))
            setList(props.fetchWorkActivities.workactivities.sort((a, b) => a.isActive - b.isActive))
            setPage(page - 1)
        } 
    };

    const handleMostImportant = (e, workActivityId, mostImportantVal) => {
        e.preventDefault();
        

        dispatch(updateImportantWorkActivity({docId : workActivityId, mostImportant : mostImportantVal}))
    }

    useEffect(() => {
        
        setList(props.fetchWorkActivities.workactivities.sort((a, b) => a.isActive - b.isActive).slice(0, 5))
        
        
    }, [props.fetchWorkActivities])

    useEffect(() => {
        
        const data = {
            route : "",
            userId: props.userDetails.userId
        }
       
        dispatch(fetchWorkActivity(data))
        
    }, [props.updateImportantWorkActivities])

    

    return (
    <>
        
        <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
                <tr>
                    
                    <th scope="col">MOST IMPORTANT</th>
                    <th scope="col">TYPE</th>
                    <th scope="col">ISSUING ORGANIZATION</th>
                    <th scope="col">DATE</th>
                    <th scope="col">TITLE</th>
                    <th scope="col">ACTION</th>
                </tr>
            </thead>
            <tbody>
                
                
                {
                    list.map((val) => {
                                          
                    return <tr key = {val.workActivityId}>
                            <td scope="row" className='text-center'>
                                <i class={val.mostImportant ? "fas fa-star text-xl text-yellow" : "fas fa-star text-xl"} role="button" onClick={e => handleMostImportant(e,val.workActivityId, val.mostImportant)}></i> 
                            </td>
                            <td>
                                {val.experienceType}
                            </td>
                            <td>
                                {val.orgName}
                            </td>
                            <td>
                                {months[val.experienceDatesStartMonth - 1] + " " + val.experienceDatesStartYear + " to " + months[val.experienceDatesEndMonth - 1] + " " + val.experienceDatesEndYear}
                            </td>
                            <td>
                                {val.experienceDetailsTitle}
                            </td>
                            <td className="">

                                {props.adminUser.admin ?
                                    <a href="#pablo" className="btn btn-sm btn-primary" onClick={(e) => onClickEditWorkActivity(e,val.workActivityId)}><i className="fa fa-eye"></i> View</a>
                                    :
                                    <>
                                        <a href="#pablo" className="btn btn-sm btn-primary" onClick={(e) => onClickEditWorkActivity(e,val.workActivityId)}><i className="fa fa-pen"></i> Edit</a>
                                        <a href="#pablo" className="btn btn-sm btn-danger" onClick={(e) => onClickDeleteWorkActivity(e,val.workActivityId)}><i className="fa fa-trash"></i> Delete</a>
                                    </>
                                }
                            </td>
                        </tr>
                    })

                }
                    
                    
                
            </tbody>
        </Table>
        <CardFooter className="py-4">
            <nav aria-label="...">
                <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
                >
                <PaginationItem className={page === 1 ? 'disabled' : ''}>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => showPrevious(e,{ item: list[0] })}
                    tabIndex="-1"
                    >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {/* <PaginationItem className="active">
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    2 <span className="sr-only">(current)</span>
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    3
                    </PaginationLink>
                </PaginationItem> */}
                <PaginationItem className={list.length < 5 ? 'disabled' : ''}>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => showNext(e,{ item: list[list.length - 1] })}
                    >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
                </Pagination>
            </nav>
        </CardFooter>
    </>
  )
}
const mapStateToProps = state => {
    return {
        fetchWorkActivities : state.fetchWorkActivities,
        updateWorkActivityModalStatus : state.updateWorkActivityModalStatus,
        deleteWorkActivity : state.deleteWorkActivity,
        userDetails : state.loginAuth.userDetails,
        adminUser : state.adminUser,
        updateImportantWorkActivities : state.updateImportantWorkActivities
        
    }
  }


export default connect(mapStateToProps)(WorkActivitiesTable)