import React, {useEffect, useRef} from 'react'
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
} from "reactstrap";
import Header from 'components/Headers/Header'
import Plans from './Plans';
import { connect } from 'react-redux';
const Chatbot = (props) => {

    
    
    

  return (
    <>
        
        

        <Header></Header>
            <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    
                    <Card className="shadow">
                        <CardHeader className="border-1">
                            <div className='float-left'>
                                <h5 className="h3 mb-0">Chatbot</h5>
                            </div>
                        </CardHeader>
                        <Row className="pl-4 pr-4 ">
                            <Col lg='12 mt-3'>
                                <div style={props.adminUser.admin ? {position: 'relative', width: '100%',pointerEvents:"none"} :{ position: 'relative', width: '100%' }} className='mb-4' >
                                    {/* <iframe
                                        src="https://drive.google.com/file/d/1752SI5clpVomsqqc8nzqvbuHHfN0T7xF/preview"
                                        width="100%"
                                        height="1000"
                                        title="Embedded Document"
                                    ></iframe> */}
                                     <iframe
                                        src={"https://www.chatbase.co/chatbot-iframe/2a96NizDXhvKZcqFDKxGd"}
                                        width="100%"
                                        style={{ height: '100%', minHeight: '700px' }}
                                        frameBorder="0"
                                      
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    
                </div>
            </Row>
        </Container>
        <Plans></Plans>
    </>
  )
}

const mapStateToProps = state => {
    return {

      adminUser : state.adminUser
  
    }
}

export default connect(mapStateToProps)(Chatbot)