import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Table,
  Media,
  Badge,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Form,
  Col,
  FormGroup,
  CardBody,
  Input,
  ModalHeader,
  ModalFooter,
  ModalBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CustomInput,
} from "reactstrap";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
  fetchAllUsers,
  fetchUserDetails,
  updateUserDetails,
  updateUserDetailsReset,
  updateMultiUsers,
  updateMultiUsersReset,
} from "redux/ManageUsers/ManageUsersAction";
import { fetchCoaches } from "redux/ScheduleTutor/ScheduleTutorActions";
import { customDropdown } from "util/reactCustomStyles";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import { customDropdownWithBorder } from "util/reactCustomStyles";
import { app, db } from "../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";

const ManageUsers = (props) => {
  const dispatch = useDispatch();

  const [allUsers, setAllUsers] = useState([]);
  const [allUsers2, setAllUsers2] = useState([]);

  const [checkUsers, setCheckUsers] = useState([]);
  const [page, setPage] = useState(1);

  const [by, setBy] = useState("name");
  const [order, setOrder] = useState("asc");

  const subscriptionFilter = [
    { value: "", label: "ALL" },
    { value: "Free Account", label: "Free Account" },
    { value: "PRO Member", label: "PRO Member" },
    { value: "Ultimate Advising Student", label: "Ultimate Advising Student" },
  ];

  useEffect(() => {
    register("name");

    register("mcatDate");
    register("tutorHours");
    register("inputUworld");
    register("inputAamc");
    register("email");
    register("homeAddress");

    register("tpcEntryDate");
    register("mcatDate");
    register("schoolYear");
    register("university");

    register("selectedCountry");
    register("selectedCity");
    register("selectedState");
    register("postalCode");
    register("tutorHours");
    register("shadowGoal");
    register("clinicalGoal");
    register("volunteerGoal");

    register("ultimateAdvisingStudent");
    register("coach");

    register("assignedCoach");

    dispatch(
      fetchAllUsers({
        route: "",
        order: order,
        by: by,
        searchFullName: searchFullName,
        subscriptionFilter: subscription,
      })
    );
  }, []);

  useEffect(() => {
    if (props.fetchAllUsers.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      if (props.fetchAllUsers.allusers.length) {
        setAllUsers(props.fetchAllUsers.allusers);
      } else {
        setAllUsers([]);
      }
      Swal.close();
    }
  }, [props.fetchAllUsers]);

  const showNext = (e, item) => {
    e.preventDefault();

    if (allUsers.length !== 0) {
      dispatch(
        fetchAllUsers({
          route: "Next",
          item: item.item,
          order: order,
          by: by,
          subscriptionFilter: subscription,
        })
      );

      setAllUsers(props.fetchAllUsers.allusers);
      setPage(page + 1);
    }
  };

  const showPrevious = (e, item) => {
    e.preventDefault();
    if (allUsers.length !== 0) {
      dispatch(
        fetchAllUsers({
          route: "Previous",
          item: item.item,
          order: order,
          by: by,
          subscriptionFilter: subscription,
        })
      );
      setAllUsers(props.fetchAllUsers.allusers);
      setPage(page - 1);
    }
  };

  const sort = (e, sortBy) => {
    e.preventDefault();
    setBy(sortBy);
    setOrder(order == "asc" ? "desc" : "asc");

    dispatch(
      fetchAllUsers({
        route: "",
        order: order,
        by: sortBy,
        searchFullName: searchFullName,
        subscriptionFilter: subscription,
      })
    );

    setPage(1);
  };

  const {
    register,
    getValues,
    unregister,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchAllUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      let users = [];
      querySnapshot.forEach((doc) => {
        let v = doc.data();
        users.push({
          value: v.handle,
          label: v.name +  " ("+v.email+")",
          image: typeof v.profilePicture != "undefined" ? v.profilePicture : "",
        });
      });
      // users.unshift({ value: "", label: "Select Users" });
      setCheckUsers(users);
    };
    fetchAllUsers();
  }, []);

  const [modalUpdateUser, setModalUpdateUser] = useState(false);
  const [modalUpdateMultiUsers, setModalUpdateMultiUsers] = useState(false);
  const [selectedUsers, setselectedUsers] = useState([]);

  const selectUsers = (e) => {
    const values = [...e].map((opt) => opt.value);
    setselectedUsers(values);
    setValue("selectUsers", values);
  };

  useEffect(() => {
    if (props.fetchUserDetails.userdetails.length) {
      setValue("name", props.fetchUserDetails.userdetails[0].name);

      setValue("email", props.fetchUserDetails.userdetails[0].email);
      setValue(
        "homeAddress",
        typeof props.fetchUserDetails.userdetails[0].homeAddress != "undefined"
          ? props.fetchUserDetails.userdetails[0].homeAddress
          : ""
      );

      setValue(
        "tpcEntryDate",
        props.fetchUserDetails.userdetails[0].tpcEntryDate
      );
      setValue("mcatDate", props.fetchUserDetails.userdetails[0].mcatDate);
      setValue(
        "schoolYear",
        typeof props.fetchUserDetails.userdetails[0].schoolYear != "undefined"
          ? props.fetchUserDetails.userdetails[0].schoolYear
          : ""
      );
      setValue(
        "university",
        typeof props.fetchUserDetails.userdetails[0].university != "undefined"
          ? props.fetchUserDetails.userdetails[0].university
          : ""
      );

      setValue(
        "selectedCountry",
        typeof props.fetchUserDetails.userdetails[0].selectedCountry !=
          "undefined"
          ? props.fetchUserDetails.userdetails[0].selectedCountry
          : ""
      );
      setValue(
        "selectedCity",
        typeof props.fetchUserDetails.userdetails[0].selectedCity != "undefined"
          ? props.fetchUserDetails.userdetails[0].selectedCity
          : ""
      );
      setValue(
        "selectedState",
        typeof props.fetchUserDetails.userdetails[0].selectedState !=
          "undefined"
          ? props.fetchUserDetails.userdetails[0].selectedState
          : ""
      );
      setValue(
        "postalCode",
        typeof props.fetchUserDetails.userdetails[0].postalCode != "undefined"
          ? props.fetchUserDetails.userdetails[0].postalCode
          : ""
      );
      setValue("tutorHours", props.fetchUserDetails.userdetails[0].tutorHours);
      setValue("shadowGoal", props.fetchUserDetails.userdetails[0].shadowGoal);
      setValue(
        "clinicalGoal",
        props.fetchUserDetails.userdetails[0].clinicalGoal
      );
      setValue(
        "volunteerGoal",
        typeof props.fetchUserDetails.userdetails[0].volunteerGoal !=
          "undefined"
          ? props.fetchUserDetails.userdetails[0].volunteerGoal
          : 0
      );

      setValue("mcatDate", props.fetchUserDetails.userdetails[0].mcatDate);
      setValue("tutorHours", props.fetchUserDetails.userdetails[0].tutorHours);
      setValue(
        "inputUworld",
        props.fetchUserDetails.userdetails[0].subscriptions.includes("uworld")
          ? "claimed"
          : "unclaimed"
      );
      setValue(
        "inputAamc",
        props.fetchUserDetails.userdetails[0].subscriptions.includes("aamc")
          ? "claimed"
          : "unclaimed"
      );

      setValue(
        "ultimateAdvisingStudent",
        typeof props.fetchUserDetails.userdetails[0].ultimateAdvisingStudent !=
          "undefined"
          ? props.fetchUserDetails.userdetails[0].ultimateAdvisingStudent
          : false
      );
      setValue(
        "coach",
        typeof props.fetchUserDetails.userdetails[0].coach != "undefined"
          ? props.fetchUserDetails.userdetails[0].coach
          : false
      );

      setValue(
        "assignedCoach",
        typeof props.fetchUserDetails.userdetails[0].assignedCoach !=
          "undefined"
          ? props.fetchUserDetails.userdetails[0].assignedCoach
          : ""
      );
      if (
        typeof props.fetchUserDetails.userdetails[0].assignedCoach !=
        "undefined"
      ) {
        setAssignedCoach(props.fetchUserDetails.userdetails[0].assignedCoach);
      } else {
        setAssignedCoach("");
      }
    }
  }, [props.fetchUserDetails]);

  const toggleUpdateUser = () => {
    setModalUpdateUser(!modalUpdateUser);
  };

  const toggleUpdateMultiUsers = () => {
    setModalUpdateMultiUsers(!modalUpdateMultiUsers);
  };

  const editUserDetails = (e, data) => {
    e.preventDefault();
    setModalUpdateUser(data.modal);
    dispatch(fetchUserDetails({ userId: data.userId }));

    setValue("name", "");
    setValue("email", "");
    setValue("homeAddress", "");
    setValue("tpcEntryDate", "");
    setValue("mcatDate", "");
    setValue("schoolYear", "");
    setValue("university", "");
    setValue("selectedCountry", "");
    setValue("selectedCity", "");
    setValue("selectedState", "");
    setValue("postalCode", "");
    setValue("tutorHours", "");
    setValue("shadowGoal", "");
    setValue("clinicalGoal", "");
    setValue("volunteerGoal", "");
    setValue("assignedCoach", "");

    // setValue("ultimateAdvisingStudent", "");
    // setValue("coach",  "");
  };

  const updateMultipleUsers = () => {
    setValue("tutorHours", "");
    setValue("assignedCoach", "");
    setValue("selectUsers", "");
    setValue("ultimateAdvisingStudent", "");
    setModalUpdateMultiUsers(true);
  };

  const onSubmitUpdate = (data) => {
    data.subscriptions = [];

    if (data.inputAamc == "claimed") {
      data.subscriptions.push("aamc");
    }
    if (data.inputUworld == "claimed") {
      data.subscriptions.push("uworld");
    }
    dispatch(updateMultiUsers(data));
  };

  //   const Checkbox = ({ obj, onChange }) => {
  //     return (
  //       <CustomInput
  //         type="checkbox"
  //         id={`custom-checkbox-${obj.handle}`}
  //         name={obj.handle}
  //         value={obj.checked}
  //         onChange={() => onChange({ ...obj, checked: !obj.checked })}
  //       />
  //     );
  //   };

  const onSubmit = (data) => {
    data.subscriptions = [];

    if (data.inputAamc == "claimed") {
      data.subscriptions.push("aamc");
    }
    if (data.inputUworld == "claimed") {
      data.subscriptions.push("uworld");
    }
    data.handle = props.fetchUserDetails.userdetails[0].handle;

    // console.log(data)

    dispatch(updateUserDetails(data));
  };

  useEffect(() => {
    if (props.updateUserDetails.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.fire({
        title: "Success!",
        text: "Successfully updated user!",
        icon: "success",
        timer: 3000,
      }).then(() => {
        dispatch(updateUserDetailsReset());
        dispatch(
          fetchAllUsers({
            route: "",
            order: order,
            by: by,
            searchFullName: searchFullName,
            subscriptionFilter: subscription,
          })
        );
      });
    }
  }, [props.updateUserDetails]);

  const [searchFullName, setSearchFullName] = useState("");

  function handleSearchByCriteria(e) {
    e.preventDefault();

    setSearchFullName(e.target.value);

    dispatch(
      fetchAllUsers({
        route: "",
        order: order,
        by: by,
        searchFullName: searchFullName,
        subscriptionFilter: subscription,
      })
    );
  }

  function searchByCriteria(e) {
    e.preventDefault();

    setSearchFullName(e.target.value);

    // const filteredData = props.fetchAllUsers.allusers.filter((item) => {
    //   for (const key in item) {
    //     for (const key in item) {
    //       if (typeof item[key] === 'string' && item[key].toLowerCase().includes(searchValue.toLowerCase())) {
    //         return true;
    //       } else if (typeof item[key] === 'number' && item[key] === parseInt(searchValue)) {
    //         return true;
    //       } else if (Array.isArray(item[key]) && item[key].includes(searchValue)) {
    //         return true;
    //       }

    //     }
    //     return false;
    //   }
    //   return false;
    // });

    // return filteredData;
  }

  const handleViewUser = (e, userDetails) => {
    e.preventDefault();
    dispatch(getUserData({ user: userDetails }));
  };

  const [subscription, setSubscription] = useState("");

  function handleSubscriptionFilterOnChange(e) {
    setSubscription(e.value);
  }

  useEffect(() => {
    dispatch(
      fetchAllUsers({
        route: "",
        order: order,
        by: by,
        searchFullName: searchFullName,
        subscriptionFilter: subscription,
      })
    );
    dispatch(fetchCoaches({}));
  }, [subscription]);
  const handleAssignedCoach = (selectedOption) => {
    setAssignedCoach(selectedOption.value);
    setValue("assignedCoach", selectedOption.value);
  };

  const [coaches, setCoaches] = useState([]);
  const [assignedcoach, setAssignedCoach] = useState("");

  useEffect(() => {
    if (props.fetchCoaches.fetchcoaches.length) {
      const coachesArr = props.fetchCoaches.fetchcoaches[0];
      setCoaches(coachesArr);
    }
  }, [props.fetchCoaches]);

    // useEffect(() => {

    //     if(props.updateMultiUsers.loading){
    //         Swal.fire({
    //             allowOutsideClick : false,
    //             didOpen: () => {
    //                 Swal.showLoading()
                    
    //             }
    //         });
    //     }
    //     else{
    //         if(props.updateMultiUsers.multiusers.length){
    //         Swal.fire({
    //             title: 'Success!',
    //             text: 'Successfully update users!',
    //             icon : 'success',
    //             timer: 3000
    //         });
    //         }
    //         else if(typeof props.updateMultiUsers.error !== "undefined"){

    //         Swal.fire({
    //             title: 'Failed!',
    //             text: 'Failed to update users',
    //             icon : 'error',
    //             timer: 3000
    //         });

    //         }
    //     }

    

    // }, [])

  return (
    <>
      <Header></Header>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col md="4">
                    <FormGroup className="mb-4">
                      <InputGroup className="mb-0">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Search by full name"
                          type="text"
                          onChange={(e) => searchByCriteria(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => handleSearchByCriteria(e)}
                    >
                      Search
                    </button>
                  </Col>
                  <Col md="6">
                    <button
                      className="btn btn-primary float-right"
                      onClick={(e) => updateMultipleUsers(e)}
                    >
                      Update Multiple Users
                    </button>
                  </Col>

                  <Col md="12">
                    <h2 className="mb-2 text-primary">FILTERS</h2>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Select
                      placeholder="Select Subscription"
                      options={subscriptionFilter}
                      styles={customDropdownWithBorder}
                      onChange={(e) => handleSubscriptionFilterOnChange(e)}
                      menuPortalTarget={document.body}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <a
                        href="#"
                        className={by == "name" ? "text-info" : "text-primary"}
                        onClick={(e) => sort(e, "name")}
                      >
                        FULL NAME <i className="fas fa-sort"></i>
                      </a>
                    </th>

                    <th scope="col">
                      <a
                        href="#"
                        className={by == "email" ? "text-info" : "text-primary"}
                        onClick={(e) => sort(e, "email")}
                      >
                        EMAIL ADDRESS <i className="fas fa-sort"></i>
                      </a>
                    </th>

                    <th scope="col">PHONE NUMBER </th>
                    <th scope="col">SUBSCRIPTION </th>

                    <th scope="col">
                      <a
                        href="#"
                        className={
                          by == "tutorHours" ? "text-info" : "text-primary"
                        }
                        onClick={(e) => sort(e, "tutorHours")}
                      >
                        TUTORING HOURS <i className="fas fa-sort"></i>
                      </a>
                    </th>

                    <th scope="col">UWORLD</th>
                    <th scope="col">AAMC</th>
                    <th scope="col">UAS</th>
                    <th scope="col">COACH</th>

                    <th scope="col">
                      <a
                        href="#"
                        className={
                          by == "tpcEntryDate" ? "text-info" : "text-primary"
                        }
                        onClick={(e) => sort(e, "tpcEntryDate")}
                      >
                        TPC ENTRY DATE <i className="fas fa-sort"></i>
                      </a>
                    </th>

                    {/* <th scope="col">TOTAL</th> */}
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers.map((val) => {
                    return (
                      <tr key={val.email}>
                        <td scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3 "
                              
                              onClick={(e) => e.preventDefault()}
                            >
                              {typeof val.profilePicture != "undefined" ? (
                                <div className="card-profile-div-sm card-profile-div-md">
                                  <div className="card-profile-image">
                                    <div
                                      className="profile-picture-sm profile-picture-md"
                                      style={{
                                        backgroundImage:
                                          'url("https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/' +
                                          val.profilePicture +
                                          '")',
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              ) : (
                                <div className="rounded-circle profile-letter-all-users">
                                  {val.name.trim()[0]}
                                </div>
                              )}
                            </a>
                            <Media>
                              <span className="mb-0 text-sm">{val.name}</span>
                            </Media>
                          </Media>
                        </td>
                        <td>{val.email}</td>
                        <td>{val.phoneNumber}</td>
                        <td>
                          {val.ultimateAdvisingStudent == "true" ||
                          val.ultimateAdvisingStudent === true
                            ? "Ultimate Advising Student"
                            : typeof val.subsId != "undefined"
                            ? "PRO Member"
                            : "Free Account"}
                        </td>
                        <td>{val.tutorHours}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            {val.subscriptions.includes("uworld") ? (
                              <>
                                <i className="bg-info" /> Claimed
                              </>
                            ) : (
                              <>
                                <i className="bg-danger" /> Unclaimed
                              </>
                            )}
                          </Badge>
                        </td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            {val.subscriptions.includes("aamc") ? (
                              <>
                                <i className="bg-info" /> Claimed
                              </>
                            ) : (
                              <>
                                <i className="bg-danger" /> Unclaimed
                              </>
                            )}
                          </Badge>
                        </td>
                        <td>
                          {val.ultimateAdvisingStudent == "true" ||
                          val.ultimateAdvisingStudent === true
                            ? "Yes"
                            : "No"}
                        </td>
                        <td>
                          {val.coach == "true" || val.coach === true
                            ? "Yes"
                            : "No"}
                        </td>
                        <td>{val.tpcEntryDate}</td>

                        <td className="text-right">
                          <a
                            
                            className="btn btn-sm btn-primary"
                            onClick={(e) => handleViewUser(e, val)}
                          >
                            <i className="fas fa-eye"></i> Show User View
                          </a>
                          <a
                            
                            className="btn btn-sm btn-warning"
                            onClick={(e) =>
                              editUserDetails(e, {
                                modal: true,
                                userId: val.userId,
                              })
                            }
                          >
                            <i className="fa fa-pen"></i> Edit
                          </a>
                          {/* <UncontrolledDropdown>
                                    <DropdownToggle
                                        className="btn-icon-only text-light"
                                        
                                        role="button"
                                        size="sm"
                                        color=""
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <i className="fas fa-ellipsis-v" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem
                                        
                                        onClick={(e) => onClickEditMcatScore(e,val.mcatScoreId)}
                                        >
                                        Edit
                                        </DropdownItem>
                                        <DropdownItem
                                        
                                        onClick={(e) => onClickDeleteMcatScore(e,val.mcatScoreId,val.userId, val.handle)}
                                        >
                                        Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                    </UncontrolledDropdown> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                {searchFullName == "" ? (
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={page === 1 ? "disabled" : ""}>
                        <PaginationLink
                          
                          onClick={(e) =>
                            showPrevious(e, { item: allUsers[0] })
                          }
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={allUsers.length < 20 ? "disabled" : ""}
                      >
                        <PaginationLink
                          
                          onClick={(e) =>
                            showNext(e, { item: allUsers[allUsers.length - 1] })
                          }
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                ) : (
                  ""
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={modalUpdateUser}
        toggle={toggleUpdateUser}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit User</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <CardBody style={{ maxHeight: "650px", overflowY: "auto" }}>
                <h6 className="heading-small text-muted mb-4">
                  User Information
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="name">
                          Full Name
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="name"
                          name="name"
                          value={watch("name")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="email">
                          Email Address
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="email"
                          name="email"
                          value={watch("email")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="tpcEntryDate"
                        >
                          TPC Entry Date
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="tpcEntryDate"
                          name="tpcEntryDate"
                          value={watch("tpcEntryDate")}
                          type="date"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="mcatDate"
                        >
                          MCAT Date
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="mcatDate"
                          name="mcatDate"
                          value={watch("mcatDate")}
                          type="date"
                          onChange={(e) => setValue("mcatDate", e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="schoolYear"
                        >
                          School Year
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="schoolYear"
                          name="schoolYear"
                          value={watch("schoolYear")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="university"
                        >
                          University
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="university"
                          name="university"
                          value={watch("university")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Contact Information
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="homeAddress"
                        >
                          Address
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="homeAddress"
                          name="homeAddress"
                          value={watch("homeAddress")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="selectedCountry"
                        >
                          Country
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="selectedCountry"
                          name="selectedCountry"
                          value={watch("selectedCountry")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="selectedState"
                        >
                          State
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="selectedState"
                          name="selectedState"
                          value={watch("selectedState")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="selectedCity"
                        >
                          City
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="selectedCity"
                          name="selectedCity"
                          value={watch("selectedCity")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="postalCode"
                        >
                          Postal Code
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="postalCode"
                          name="postalCode"
                          value={watch("postalCode")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <hr className="my-4" />

                <h6 className="heading-small text-muted mb-4">
                  Other Information
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="inputUworld"
                        >
                          UWORLD Subscription
                        </label>
                        <select
                          id="inputUworld"
                          className="form-control form-control-alternative"
                          value={watch("inputUworld")}
                          onChange={(e) =>
                            setValue("inputUworld", e.target.value)
                          }
                        >
                          <option value="claimed">Claimed</option>
                          <option value="unclaimed">Unclaimed</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="inputAamc"
                        >
                          AAMC Subscription
                        </label>
                        <select
                          id="inputAamc"
                          className="form-control form-control-alternative"
                          value={watch("inputAamc")}
                          onChange={(e) =>
                            setValue("inputAamc", e.target.value)
                          }
                        >
                          <option value="claimed">Claimed</option>
                          <option value="unclaimed">Unclaimed</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="tutorHours"
                        >
                          Premium Tutoring Hours
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="tutorHours"
                          name="tutorHours"
                          value={watch("tutorHours")}
                          type="number"
                          onChange={(e) =>
                            setValue("tutorHours", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="shadowHours"
                        >
                          Shadow Hours
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="shadowHours"
                          name="shadowHours"
                          value={watch("shadowGoal")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="clinicalHours"
                        >
                          Clinical Hours
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="clinicalHours"
                          name="clinicalHours"
                          value={watch("clinicalGoal")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="volunteerHours"
                        >
                          Volunteer Hours
                        </label>
                        <Input
                          className="form-control-alternative is-invalid "
                          id="volunteerHours"
                          name="volunteerHours"
                          value={watch("volunteerGoal")}
                          type="text"
                          disabled="disabled"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="ultimateAdvisingStudent"
                        >
                          Ultimate Advising Student
                        </label>

                        <select
                          id="ultimateAdvisingStudent"
                          className="form-control form-control-alternative"
                          value={watch("ultimateAdvisingStudent")}
                          onChange={(e) =>
                            setValue("ultimateAdvisingStudent", e.target.value)
                          }
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="coach">
                          Coach
                        </label>
                        <select
                          id="coach"
                          className="form-control form-control-alternative"
                          value={watch("coach")}
                          onChange={(e) => setValue("coach", e.target.value)}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="assignedCoach"
                        >
                          Assigned Coach
                        </label>
                        <Select
                          placeholder="Select University"
                          options={coaches}
                          // styles={customDropdown }
                          id="assignedCoach"
                          name="assignedCoach"
                          onChange={handleAssignedCoach}
                          value={coaches.find(
                            (option) => option.value === assignedcoach
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Update
            </Button>{" "}
            <Button color="secondary" onClick={toggleUpdateUser}>
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={modalUpdateMultiUsers}
        toggle={toggleUpdateMultiUsers}
      >
        <ModalHeader toggle={toggleUpdateMultiUsers}>Update Users</ModalHeader>
        <Form onSubmit={handleSubmit(onSubmitUpdate)}>
          <ModalBody className="modal-body ">
            <Row>
              <Col>
                <FormGroup>
                  <Select
                    isMulti
                    name="selectusers"
                    options={checkUsers}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={selectUsers}
                    placeholder="Search a user"
                    formatOptionLabel={(v) => (
                      <div className="d-flex ">
                        {v.value ? (
                          v.image ? (
                            <img
                              className="rounded-circle  mr-1 "
                              style={{ width: "25px", height: "25px" }}
                              src={
                                "https://storage.googleapis.com/pre-pa-tracker.appspot.com/profile-picture/" +
                                v.image
                              }
                              alt={v.label}
                            />
                          ) : (
                            <div
                              className="rounded-circle profile-letter-all-users mr-1 "
                              style={{ width: "25px", height: "25px" }}
                            >
                              {v.label ? v.label.trim()[0] : ""}
                            </div>
                          )
                        ) : (
                          ""
                        )}

                        <span>{v.label}</span>
                      </div>
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <h6 className="heading-small text-muted">
                  Update The Information of the selected users
                </h6>
              </Col>
            </Row>

            <Row className="p-3">
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="coach">
                    Coach
                  </label>
                  <select
                    id="coach"
                    className="form-control form-control-alternative"
                    onChange={(e) => setValue("coach", e.target.value)}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </FormGroup>
              </Col> */}
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="assignedCoach">
                    Assigned Coach
                  </label>
                  <Select
                    placeholder="Select Coach"
                    options={coaches}
                    // styles={customDropdown }
                    id="assignedCoach"
                    name="assignedCoach"
                    onChange={handleAssignedCoach}
                    value={coaches.find(
                      (option) => option.value === assignedcoach
                    )}
                  />
                </FormGroup>
              </Col>
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="inputUworld">
                    UWORLD Subscription
                  </label>
                  <select
                    id="inputUworld"
                    className="form-control form-control-alternative"
                    onChange={(e) => setValue("inputUworld", e.target.value)}
                  >
                    <option value="unclaimed">Unclaimed</option>
                    <option value="claimed">Claimed</option>
                  </select>
                </FormGroup>
              </Col> */}
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="inputAamc">
                    AAMC Subscription
                  </label>
                  <select
                    id="inputAamc"
                    className="form-control form-control-alternative"
                    onChange={(e) => setValue("inputAamc", e.target.value)}
                  >
                    <option value="unclaimed">Unclaimed</option>
                    <option value="claimed">Claimed</option>
                  </select>
                </FormGroup>
              </Col> */}
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="tutorHours">
                    Premium Tutoring Hours
                  </label>
                  <Input
                    className="form-control-alternative is-invalid "
                    id="tutorHours"
                    name="tutorHours"
                    value={watch("tutorHours")}
                    placeholder="0"
                    type="number"
                    onChange={(e) => setValue("tutorHours", e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="ultimateAdvisingStudent"
                  >
                    Ultimate Advising Student
                  </label>

                  <select
                    id="ultimateAdvisingStudent"
                    name="ultimateAdvisingStudent"
                    className="form-control form-control-alternative"
                    onChange={(e) =>
                      setValue("ultimateAdvisingStudent", e.target.value)
                    }
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
            {/* <Form>
            <div
              className=""
              style={{ maxHeight: "500px", overflow: "scroll" }}
            >
              {
			  	checkUsers.length
                ? checkUsers.map((v, i) => {
                 
                    return (
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="media m-1">
                          {typeof v.profilePicture != "undefined" ? (
                            <img
                              src={
                                "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
                                v.profilePicture
                              }
                              className="mr-3 rounded-circle"
                              alt="..."
                              style={{
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          ) : (
                            <div
                              className="rounded-circle profile-letter-all-users mr-3 "
                              style={{ width: "30px", height: "30px" }}
                            >
                              {v.name ? v.name.trim()[0] : ""}
                            </div>
                          )}

                          <div className=" d-flex align-items-center ">
                            <h5 className="mt-0 mb-0">
                              {v.name ? v.name : ""}{" "}
                              {v.coach || v.coach == "true" ? (
                                <Badge
                                  className="ml-2 text-dark"
                                  color="info"
                                  pill
                                >
                                  Coach
                                </Badge>
                              ) : (
                                ""
                              )}
                            </h5>
                          </div>
                        </div>
                        <div>
                          <FormGroup>
                            <Checkbox
                              obj={v}
                              onChange={(item) => {
                                setCheckUsers(
                                  checkUsers.map((d) =>
                                    d.handle === item.handle ? item : d
                                  )
                                );
                              }}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    );
                    
                  })
                : ""
				}
            </div>
          </Form> */}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleUpdateMultiUsers}>
              Cancel
            </Button>
            <Button color="success" type="submit">
              Update
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    fetchAllUsers: state.fetchAllUsers,
    fetchUserDetails: state.fetchUserDetails,
    updateUserDetails: state.updateUserDetails,
    updateMultiUsers: state.updateMultiUsersReducer,
    fetchCoaches: state.fetchCoaches,
  };
};

export default connect(mapStateToProps)(ManageUsers);
