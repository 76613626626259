import React from 'react'
import {
    Card,
    Container,
    Row,
    Form,
    Col,
    FormGroup
} from "reactstrap";
import Header from 'components/Headers/Header';
const ThankYou = (props) => {
  return (
    <>
        <Header></Header>
            <Container className="mt--7" fluid>
            <Row>
                <Col  lg='12'>
                    <Card className="shadow pt-5 pb-5 pl-4 pr-4">
                        
                        
                        <div className='text-center '>
                            <span className='fas fa-check-circle text-success' style={{fontSize:"5em"}}></span>
                        </div>
                        <div className='text-center'>
                            <h1 className='display-1 mt-4' >Thank you!</h1>
                            <h2 className='mt-4' >Thank you for subscribing to PRO {props.location.state.plan} plan! We're delighted to have you join us. Your premium content and special offers are now unlocked!</h2>
                            <a href='prepatracker.io' className='btn btn-info mt-5 '>Go to dashboard</a>
                        </div>
                            
                        
                    </Card>
                </Col>
                <Col lg="12" className='mb-5'>
                    <Row>
                        <Col  xl="6" md="12" className='d-flex'>
                            <Card className="shadow pt-5 pb-5 pl-4 pr-4 mt-4 w-100">
                                
                                <div className='text-center'>
                                    <h1 className='display-3' >Connect With Us</h1>
                                    <a href='https://m.facebook.com/groups/premedconsultants' target="__blank"><span className='fab fa-facebook-square m-1' style={{fontSize:"4em", color:"#4267B2"}}></span></a>
                                    <a href='https://www.instagram.com/thepremedconsultants' target="__blank"><span className='fab fa-instagram-square m-1' style={{fontSize:"4em" , color:"#F56040"}}></span></a>
                                    <a href='https://www.linkedin.com/company/the-premed-consultants-inc' target="__blank"><span className='fab fa-linkedin m-1' style={{fontSize:"4em"}}></span></a>
                                    <a href='https://youtube.com/@ThePremedConsultants?si=vJh9qkjR1muXh3gg' target="__blank"><span className='fab fa-youtube-square m-1' style={{fontSize:"4em", color:"#FF0000"}}></span></a>
                                    <a href='https://x.com/tpcforpremeds?s=21&t=uRTs4A3PcfxHa859GpuAOA' target="__blank"><span className='fab fa-twitter-square m-1' style={{fontSize:"4em", color:"#1DA1F2"}}></span></a>
                                </div>
                                    
                                
                            </Card>
                        </Col>
                        <Col xl="6"  md="12" className='d-flex'>
                            <Card className="shadow pt-5 pb-5 pl-4 pr-4 mt-4 w-100">

                                <div className='text-center'>
                                    <h1 className='display-3 ' >Visit Our Website</h1>

                                    <a href='www.thepremedconsultants.com' target="__blank" className='btn btn-warning mt-3 '>Visit Website</a>
                                </div>
                                    
                                
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default ThankYou