import { 
    CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST, 
    CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS, 
    CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE, 
    CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_RESET,
    CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_REQUEST, 
    CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_SUCCESS, 
    CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_FAILURE, 
    CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_RESET,
    FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE,
    FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST,
    FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS,
    FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_FAILURE,
    FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_REQUEST,
    FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_SUCCESS,
    FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_RESET,
    DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_REQUEST,
    DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_SUCCESS, 
    DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_FAILURE,
    DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_RESET,
    DELETE_RESPONSE_FOR_SECONDARY_ESSAY_REQUEST,
    DELETE_RESPONSE_FOR_SECONDARY_ESSAY_SUCCESS, 
    DELETE_RESPONSE_FOR_SECONDARY_ESSAY_FAILURE,
    DELETE_RESPONSE_FOR_SECONDARY_ESSAY_RESET,
    UPDATE_SELECTED_SECONDARY_STATUS_REQUEST,
    UPDATE_SELECTED_SECONDARY_STATUS_SUCCESS, 
    UPDATE_SELECTED_SECONDARY_STATUS_FAILURE,
    UPDATE_SELECTED_SECONDARY_STATUS_RESET  
 } from "./SecondaryEssaysTypes";


const initialSelectSchoolForSecondaryEssaysState = {
    selectschoolforsecondaryessays: [],
    loading : false
}

export const createSelectSchoolForSecondaryEssaysReducer = (state = initialSelectSchoolForSecondaryEssaysState, action) => {
    switch(action.type){
        case CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_RESET:
            return {
                loading: false,
                selectschoolforsecondaryessays: []
            }
        case CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS:
            return {
                loading: false,
                selectschoolforsecondaryessays: [action.payload]
            }
        case CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE:
            return {
                loading : false,
                selectschoolforsecondaryessays : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialFetchSelectSchoolForSecondaryEssaysState = {
    selectschoolforsecondaryessays : [],
    loading: false,

}

export const fetchSelectSchoolForSecondaryEssaysReducer = (state = initialFetchSelectSchoolForSecondaryEssaysState, action) => {
    switch (action.type) {
        case FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS:
            return {
                loading: false,
                selectschoolforsecondaryessays: action.payload
            }
        case FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE:
            return {
                loading: false,
                selectschoolforsecondaryessays: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialAnswersForSecondaryEssaysState = {
    answersforsecondaryessays: [],
    loading : false
}

export const createAnswersForSecondaryEssaysReducer = (state = initialAnswersForSecondaryEssaysState, action) => {
    switch(action.type){
        case CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_RESET:
            return {
                loading: false,
                answersforsecondaryessays: []
            }
        case CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_SUCCESS:
            return {
                loading: false,
                answersforsecondaryessays: [action.payload]
            }
        case CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_FAILURE:
            return {
                loading : false,
                answersforsecondaryessays : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialFetchResponseForSecondaryEssaysState = {
    responseforsecondaryessays : [],
    loading: false,

}

export const fetchResponseForSecondaryEssaysReducer = (state = initialFetchResponseForSecondaryEssaysState, action) => {
    switch (action.type) {
        case FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_RESET:
            return {
                loading: false,
                responseforsecondaryessays: []
            }
        case FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_SUCCESS:
            return {
                loading: false,
                responseforsecondaryessays: action.payload
            }
        case FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_FAILURE:
            return {
                loading: false,
                responseforsecondaryessays: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialDeleteSelectedSchoolForSecondaryEssayState = {
    loading: false,
    selectschoolforsecondaryessays: [],
}

export const deleteSelectedSchoolForSecondaryEssayReducer = (state = initialDeleteSelectedSchoolForSecondaryEssayState, action) => {
    switch (action.type) {
        case DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_RESET:
            return {
                loading: false,
                selectschoolforsecondaryessays: [],
            }
        case DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_SUCCESS:
            return {
                loading: false,
                selectschoolforsecondaryessays: [action.payload]
            }
        case DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_FAILURE:
            return {
                loading: false,
                selectschoolforsecondaryessays: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialDeleteResponseForSecondaryEssayState = {
    loading: false,
    responseforsecondaryessays: [],
}
export const deleteResponseForSecondaryEssayReducer = (state = initialDeleteResponseForSecondaryEssayState, action) => {
    switch (action.type) {
        case DELETE_RESPONSE_FOR_SECONDARY_ESSAY_RESET:
            return {
                loading: false,
                responseforsecondaryessays: [],
            }
        case DELETE_RESPONSE_FOR_SECONDARY_ESSAY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_RESPONSE_FOR_SECONDARY_ESSAY_SUCCESS:
            return {
                loading: false,
                responseforsecondaryessays: [action.payload]
            }
        case DELETE_RESPONSE_FOR_SECONDARY_ESSAY_FAILURE:
            return {
                loading: false,
                responseforsecondaryessays: [],
                error: action.payload
            }
        default: return state;
    }
}



const initialSecondaryStatusState = {
    loading: false,
    secondarystatus: [],
}

export const updateSecondaryStatusReducer  = (state = initialSecondaryStatusState, action) => {
    switch (action.type) {
        case UPDATE_SELECTED_SECONDARY_STATUS_RESET:
            return {
                loading: false,
                secondarystatus: [],
            }
        case UPDATE_SELECTED_SECONDARY_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_SELECTED_SECONDARY_STATUS_SUCCESS:
            return {
                loading: false,
                secondarystatus: [action.payload]
            }
        case UPDATE_SELECTED_SECONDARY_STATUS_FAILURE:
            return {
                loading: false,
                secondarystatus: [],
                error: action.payload
            }
        default:
            return state;
    }
}
