export const OPEN_MCAT_SCORE_MODAL = 'OPEN_MCAT_SCORE_MODAL'
export const CLOSE_MCAT_SCORE_MODAL = 'CLOSE_MCAT_SCORE_MODAL'

export const CREATE_MCAT_SCORE_REQUEST = 'CREATE_MCAT_SCORE_REQUEST';
export const CREATE_MCAT_SCORE_SUCCESS = 'CREATE_MCAT_SCORE_SUCCESS';
export const CREATE_MCAT_SCORE_FAILURE = 'CREATE_MCAT_SCORE_FAILURE';
export const CREATE_MCAT_SCORE_RESET = 'CREATE_MCAT_SCORE_RESET';

export const FETCH_MCAT_SCORES_REQUEST = 'FETCH_MCAT_SCORES_REQUEST';
export const FETCH_MCAT_SCORES_SUCCESS = 'FETCH_MCAT_SCORES_SUCCESS';
export const FETCH_MCAT_SCORES_FAILURE = 'FETCH_MCAT_SCORES_FAILURE';

export const UPDATE_MCAT_SCORE_REQUEST = 'UPDATE_MCAT_SCORE_REQUEST';
export const UPDATE_MCAT_SCORE_SUCCESS = 'UPDATE_MCAT_SCORE_SUCCESS';
export const UPDATE_MCAT_SCORE_FAILURE = 'UPDATE_MCAT_SCORE_FAILURE';
export const UPDATE_MCAT_SCORE_RESET = 'UPDATE_MCAT_SCORE_RESET';

export const EDIT_MCAT_SCORE = 'EDIT_MCAT_SCORE';
export const NEW_MCAT_SCORE = 'NEW_MCAT_SCORE';


export const DELETE_MCAT_SCORE_REQUEST = 'DELETE_MCAT_SCORE_REQUEST';
export const DELETE_MCAT_SCORE_SUCCESS = 'DELETE_MCAT_SCORE_SUCCESS';
export const DELETE_MCAT_SCORE_FAILURE = 'DELETE_MCAT_SCORE_FAILURE';
export const DELETE_MCAT_SCORE_RESET = 'DELETE_MCAT_SCORE_RESET';