import { 

    FETCH_STUDENT_REQUEST_TUTOR_FAILURE,
    FETCH_STUDENT_REQUEST_TUTOR_REQUEST,
    FETCH_STUDENT_REQUEST_TUTOR_RESET,
    FETCH_STUDENT_REQUEST_TUTOR_SUCCESS,

    APPROVE_STUDENT_REQUEST_TUTOR_FAILURE,
    APPROVE_STUDENT_REQUEST_TUTOR_REQUEST,
    APPROVE_STUDENT_REQUEST_TUTOR_RESET,
    APPROVE_STUDENT_REQUEST_TUTOR_SUCCESS,

    DISAPPROVE_STUDENT_REQUEST_TUTOR_FAILURE,
    DISAPPROVE_STUDENT_REQUEST_TUTOR_REQUEST,
    DISAPPROVE_STUDENT_REQUEST_TUTOR_RESET,
    DISAPPROVE_STUDENT_REQUEST_TUTOR_SUCCESS,

    FETCH_STUDENT_REQUEST_TUTOR_HISTORY_FAILURE,
    FETCH_STUDENT_REQUEST_TUTOR_HISTORY_REQUEST,
    FETCH_STUDENT_REQUEST_TUTOR_HISTORY_RESET,
    FETCH_STUDENT_REQUEST_TUTOR_HISTORY_SUCCESS,

    OPEN_UTV_MODAL,
    CLOSE_UTV_MODAL,
    EDIT_TUTOR_REQUEST,

    CREATE_STR_UPLOAD_REQUEST ,
    CREATE_STR_UPLOAD_SUCCESS,
    CREATE_STR_UPLOAD_FAILURE,
    CREATE_STR_UPLOAD_RESET

} from "./ManageTutorRequestsTypes"

const initialFetchStudentRequestTutorState = {
    fetchstudentrequesttutor: [],
    loading : false
}

export const fetchStudentRequestTutorReducer = (state = initialFetchStudentRequestTutorState, action) => {
    switch(action.type){
        case FETCH_STUDENT_REQUEST_TUTOR_RESET:
            return {
                loading: false,
                fetchstudentrequesttutor: []
            }
        case FETCH_STUDENT_REQUEST_TUTOR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_STUDENT_REQUEST_TUTOR_SUCCESS:
            return {
                loading: false,
                fetchstudentrequesttutor: [action.payload]
            }
        case FETCH_STUDENT_REQUEST_TUTOR_FAILURE:
            return {
                loading : false,
                fetchstudentrequesttutor: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialApproveStudentRequestTutorState = {
    approvestudentrequesttutor: [],
    loading : false
}

export const approveStudentRequestTutorReducer = (state = initialApproveStudentRequestTutorState, action) => {
    switch(action.type){
        case APPROVE_STUDENT_REQUEST_TUTOR_RESET:
            return {
                loading: false,
                approvestudentrequesttutor: []
            }
        case APPROVE_STUDENT_REQUEST_TUTOR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case APPROVE_STUDENT_REQUEST_TUTOR_SUCCESS:
            return {
                loading: false,
                approvestudentrequesttutor: [action.payload]
            }
        case APPROVE_STUDENT_REQUEST_TUTOR_FAILURE:
            return {
                loading : false,
                approvestudentrequesttutor: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialDisapproveStudentRequestTutorState = {
    disapprovestudentrequesttutor: [],
    loading : false
}

export const disapproveStudentRequestTutorReducer = (state = initialDisapproveStudentRequestTutorState, action) => {
    switch(action.type){
        case DISAPPROVE_STUDENT_REQUEST_TUTOR_RESET:
            return {
                loading: false,
                disapprovestudentrequesttutor: []
            }
        case DISAPPROVE_STUDENT_REQUEST_TUTOR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DISAPPROVE_STUDENT_REQUEST_TUTOR_SUCCESS:
            return {
                loading: false,
                disapprovestudentrequesttutor: [action.payload]
            }
        case DISAPPROVE_STUDENT_REQUEST_TUTOR_FAILURE:
            return {
                loading : false,
                disapprovestudentrequesttutor: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialFetchStudentRequestTutorHistoryState = {
    fetchstudentrequesttutorhistory: [],
    loading : false
}

export const fetchStudentRequestTutorHistoryReducer = (state = initialFetchStudentRequestTutorHistoryState, action) => {
    switch(action.type){
        case FETCH_STUDENT_REQUEST_TUTOR_HISTORY_RESET:
            return {
                loading: false,
                fetchstudentrequesttutorhistory: []
            }
        case FETCH_STUDENT_REQUEST_TUTOR_HISTORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_STUDENT_REQUEST_TUTOR_HISTORY_SUCCESS:
            return {
                loading: false,
                fetchstudentrequesttutorhistory: [action.payload]
            }
        case FETCH_STUDENT_REQUEST_TUTOR_HISTORY_FAILURE:
            return {
                loading : false,
                fetchstudentrequesttutorhistory: [],
                error: action.payload
            }
        default: return state;
    }
}
const initialModalUploadTutorVideoFileState = {
    status: false
}
export const updateModalUploadTutorVideoFileStatusReducer = (state = initialModalUploadTutorVideoFileState, action) => {
    switch (action.type) {
        case OPEN_UTV_MODAL:
            return {
                status : true
            }
        case CLOSE_UTV_MODAL:
            return {
                status : false
            }
        default: return state;
    }
}

const initialEditTutorRequest = {
    status: false,
    meetingId : ''
}

export const editTutorRequestReducer = (state = initialEditTutorRequest, action) => {
    switch (action.type) {
        case EDIT_TUTOR_REQUEST:
            return {
                status: action.payload.status,
                meetingId: action.payload.meetingId
            }
        default: return state;
    }
}



const initialStrUploadState = {
    strUpload: [],
    loading : false,
    done : false
}

export const createStrUploadReducer = (state = initialStrUploadState, action) => {
    switch(action.type){
        case CREATE_STR_UPLOAD_RESET:
            return {
                ...state,
                done : false
            }
        case CREATE_STR_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_STR_UPLOAD_SUCCESS:
            return {
                loading: false,
                strUpload: [action.payload],
                done : true
            }
        case CREATE_STR_UPLOAD_FAILURE:
            return {
                loading : false,
                strUpload : [],
                error: action.payload,
                done : true
            }
        default: return state;
    }
}