
import { CREATE_SIGNUP_USER_REQUEST, CREATE_SIGNUP_USER_RESET, CREATE_SIGNUP_USER_SUCCESS, CREATE_SIGNUP_USER_FAILURE } from "./RegisterTypes"

import axios from "axios";

const signupUserRequest = () => {
    return {
        type: CREATE_SIGNUP_USER_REQUEST,
    }
}

export const signupUser = (userData) => (dispatch) => {

    dispatch(signupUserRequest());
    axios.post('/signup', userData)
        .then(res => {
            dispatch({
                type: CREATE_SIGNUP_USER_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: CREATE_SIGNUP_USER_FAILURE,
                payload: err.response.data
            })
        })
}


export const signupUserReset = () => {
    return {
        type: CREATE_SIGNUP_USER_RESET,
    }
}