import { 
    FETCH_SSO_TOKEN_FAILURE,
    FETCH_SSO_TOKEN_REQUEST,
    FETCH_SSO_TOKEN_SUCCESS,
 } from "./IdeasTypes";
import axios from "axios";
const fetchSSOTokenRequest = () => {
    return {
        type: FETCH_SSO_TOKEN_REQUEST,
    }
}

const fetchSSOTokenSuccess = success => {
    return {
        type: FETCH_SSO_TOKEN_SUCCESS,
        payload: success
    }
}

const fetchSSOTokenFailure = error => {
    return {
        type: FETCH_SSO_TOKEN_FAILURE,
        payload : error
    }
}

export const fetchSSOToken = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchSSOTokenRequest());
        
        
        axios.post('/createCannyToken' , data)
        .then(response => {
            const ssoToken = response.data
            dispatch(fetchSSOTokenSuccess(ssoToken))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchSSOTokenFailure(errorMsg))
        })
        
    }

}