
import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_RESET, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from "./ResetPasswordTypes"

import axios from "axios";

const resetPasswordRequest = () => {
    return {
        type: RESET_PASSWORD_REQUEST,
    }
}

export const resetPassword = (userData) => (dispatch) => {

    dispatch(resetPasswordRequest());
    axios.post('/resetPassword', userData)
        .then(res => {
            dispatch({
                type: RESET_PASSWORD_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: RESET_PASSWORD_FAILURE,
                payload: err.response.data
            })
        })
}


export const resetPasswordReset = () => {
    return {
        type: RESET_PASSWORD_RESET,
    }
}