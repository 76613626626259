import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { app, db } from "../../firebase";
import {Badge} from "reactstrap";
import { collection,getDocs, query, where, onSnapshot, doc} from "firebase/firestore";

const ProMemberCommentLikes = (props) => {
    const dispatch = useDispatch()
    const [likes, setlikeCounts] = useState([])

    //Real Time Change
    useEffect(() => {
        const countlikes = onSnapshot(
            collection(db, "communityProMemberLikeComment"), where("commentid", "==", props.commentid),
            (snapshot) => {
                let likesCount = [];
                snapshot.docs.forEach((doc) => {
                    let likeDoc = doc.data()
                    likesCount.push(doc.data())
                })
                setlikeCounts(likesCount);
            },
            (error) => {
                console.log(error)
            }
        );
        return () => {
            countlikes()
        }
     
    }, [props.commentid])

    return (
        <>
            {     
                likes.length ?
                    <div className=" pr-2">
                        <Badge color="primary" style={{ borderRadius: '20rem', backgroundColor: '#2643e9', padding: '0.35rem' }} pill><i className="fas fa-solid fa-heart text-white "></i> </Badge>  { likes.length }
                    </div>
                : ''
            }     
        </>
    )
}

const mapStateToProps = state  => {
    return {
        userDetails: state.loginAuth.userDetails,
    }  
}

export default connect(mapStateToProps)(ProMemberCommentLikes)
