import React from 'react'
import { AddressElement } from '@stripe/react-stripe-js';
const BillingDetailsForm = () => {

    const options = {
        mode: "billing",
        fields : {
            phone : "always"
        },
        
    
    }
    return (
        <AddressElement options={options} />
    )
}

export default BillingDetailsForm