//User reducer Types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED' 
export const SET_LOGIN_AUTHENTICATED_ERRORS = "SET_LOGIN_AUTHENTICATED_ERRORS"
export const CLEAR_LOGIN_AUTHENTICATED_ERRORS = "CLEAR_LOGIN_AUTHENTICATED_ERRORS"
export const AUTHENTICATED_REQUEST = "AUTHENTICATED_REQUEST"

export const SET_ADMIN = "SET_ADMIN"


export const RENEW_TOKEN_SUCCESS = "RENEW_TOKEN_SUCCESS"
export const RENEW_TOKEN_FAILURE = "RENEW_TOKEN_FAILURE"
export const RENEW_TOKEN_REQUEST = "RENEW_TOKEN_REQUEST"
