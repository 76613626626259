import React, { useState, useEffect } from 'react'
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    FormGroup,
    Input
} from "reactstrap";
import Header from 'components/Headers/Header'
import Swal from 'sweetalert2'
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { createReportedIssue, createReportedIssueReset } from 'redux/Support/SupportActions';
const Support = (props) => {

    const dispatch = useDispatch();

    const [item, setItems] = useState({ 
        filename : '',
        secondaryEssaysId : '',
        downloadUrl: '',
        title: '', 
        body: '', 
        schoolSearchId : '',
        userId : '',
        file : '',
        docId: '', 
        collapsed: true, 
        updatedAt: '', 
    });

    const handleBodyChange = (event, index) => {
        const updatedItems = { ...item, body: event.target.value};
        
        setItems(updatedItems);

       
    };

    const handleTitleChange = (event) => {
        const updatedItems = { ...item, title: event.target.value};
        
        setItems(updatedItems);
    };

    const handleFileChange = (event) => {
        const updatedItems = { ...item, file: event.target.files[0], filename: event.target.files[0].name };


        // Check the file size
        const fileSizeInMB = updatedItems.file.size / (1024 * 1024);
        const maxSizeInMB = 10;
        if (fileSizeInMB > maxSizeInMB) {
            Swal.fire({
                title: 'Failed!',
                text: 'File size exceeds the maximum limit of 10MB.',
                icon : 'error',
                timer: 3000
                
                }
            )
            return;
        }
    
        // Check if the file type is video
        const allowedTypes = ['video/mp4', 'video/mpeg', 'video/quicktime', 'image/jpeg', 
        'image/png', 'image/jpg',];
        if (!allowedTypes.includes(updatedItems.file.type)) {
            

            Swal.fire({
                title: 'Failed!',
                text: 'Only image and video files are allowed.',
                icon : 'error',
                timer: 3000
                
                }
            )

            return;
        }        

        setItems(updatedItems);
    };

    const fileInputRef = React.useRef(null);

    

    const onClickRemoveFile = (e) => {
        e.preventDefault();

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                const updatedItems = { ...item, file: "", filename : "" };

                fileInputRef.current.value = '';

                setItems(updatedItems);
        
            }
        })


    }

    const onClickSaveResponse = async(e) => {
        e.preventDefault();
        
        if(item.title == "" || item.body == "" || item.filename == "" ){
            Swal.fire({
                title:'Failed!',
                text:'Please fill all the inputs!',
                icon :'error',
                timer: 3000
                
                }
            )

            return false
        }
        
        const formData = new FormData();
        formData.append('title', item.title);
        formData.append('body', item.body);
        formData.append('file', item.file);
        formData.append('filename', item.filename);
        formData.append('userId', props.userDetails.userId);
        
        dispatch(createReportedIssue(formData))
   
    };

    useEffect(() => {

        if(props.createReportedIssue.loading){

            Swal.fire({
              allowOutsideClick : false,
              didOpen: () => {
                Swal.showLoading()
                
              }
            });
        
        }
        else{

            if(props.createReportedIssue.reportedissue.length && typeof props.createReportedIssue.error === "undefined"){
                Swal.fire({
                    title: 'Success',
                    text: 'Successfully submitted your concern!',
                    icon : 'success',
                    timer: 3000
                    
                    }
                ).then(() => {
                    
                    const updatedItems = { ...item, file: "", filename : "", title : "", body : "" };

                    fileInputRef.current.value = '';

                    setItems(updatedItems);

                    dispatch(createReportedIssueReset())

                })
            }
            else if(typeof props.createReportedIssue.error !== "undefined"){
                Swal.fire({
                    title:'Failed!',
                    text:'Failed to submit your concern!',
                    icon :'error',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    
                    dispatch(createReportedIssueReset());
                })
            }
        }
    }, [props.createReportedIssue])
    
  return (
    <>
        <Header></Header>
            <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    
                    <Card className="shadow">
                        <CardHeader className="border-1">
                            <div className='float-left'>
                                <h5 className="h3 mb-0">Report Bugs</h5>
                            </div>
                        </CardHeader>
                        <Row className="pl-4 pr-4 ">
                            <Col lg='12 mt-3'>
                                <FormGroup>
                                    
                                    <span className='font-weight-bold'>Title<span className='text-danger'> *</span> :</span>
                                    
                                    <Input
                                        className="form-control"
                                        id="inputCourseName"
                                        name="inputCourseName"
                                        value={item.title}
                                        onChange={(event) => handleTitleChange(event)}
                                        type="text"
                                    />
                                        
                                
                                    {/* { errors.inputCourseName && <small className="text-danger">{errors.inputCourseName.message}</small> } */}
                                </FormGroup>
                                <span className='font-weight-bold'>Upload Image or Video<span className='text-danger'> *</span> :</span>
                                <div className="custom-file text-center mb-4">
                                    
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        accept="image/*,video/*"
                                        ref={fileInputRef}
                                        onChange={(event) => handleFileChange(event)}
                                    />
                                    <label className="custom-file-label" >{item.file ? <>Selected image or video file: <span className='text-primary'>{item.file.name}</span></> : "Select image or video file (max 10mb)"}</label>
                                </div>

                                { item.filename ?
                                    <Row>
                                        <Col lg="3"></Col>
                                        <Col lg="6 text-center"> 
                                            {/* <div className='player-wrapper'>
                                                <ReactPlayer
                                                url={'https://storage.googleapis.com/tpcdashboard.appspot.com/video-response-secondary-essays/' + item.filename}
                                                className='react-player'
                                                controls
                                                width='100%'
                                                height='100%'
                                                />
                                            </div> */}
                                            {/* <a href={"https://storage.googleapis.com/tpcdashboard.appspot.com/video-response-secondary-essays/" + item.filename} download={item.filename}>
                                            <i className="fas fa-download"></i> Download
                                            </a> */}
                                            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                            <a href="" className='text-danger' onClick={(e) => onClickRemoveFile(e)}>
                                            <i className="fas fa-trash-alt"></i> Remove
                                            </a>
                                            
                                        </Col>
                                        <Col lg="3"></Col>
                                
                                    </Row>
                                    : ""
                                }
                                <br />

                                <span className='font-weight-bold'>Description <span className='text-danger'> *</span> :</span>
                                <textarea
                                    rows="5"
                                    className="form-control "
                                    value={item.body}
                                    placeholder='Type your answer here...'
                                    onChange={(event) => handleBodyChange(event)}
                                />

                                <button className="btn btn-primary mt-4 mb-4" disabled={props.adminUser.admin ? "true" : ""} onClick={e => onClickSaveResponse(e)}>
                                    Submit
                                </button>

                            </Col>
                        </Row>
                    </Card>
                    
                </div>
            </Row>
        </Container>
        
    </>
  )
}
const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        createReportedIssue : state.createReportedIssue,
        adminUser : state.adminUser

    }
      
}

export default connect(mapStateToProps)(Support)