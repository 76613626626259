import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import { ImageGrid } from "react-fb-image-video-grid";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
  CardText,
  Button,
  Badge,
  Row,
  Col,
  Input,
  Image,
  Form,
  CardImg,
  UncontrolledDropdown,
} from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { app, db } from "../../firebase";
import {
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import ProMemberActionPost from "components/Dropdown/ProMemberActionPost";
import LikeTheProMemberPost from "components/Community/LikeTheProMemberPost";
import SaveProMemberPost from "components/Community/SaveProMemberPost";
import ProMemberPostLikes from "components/Community/ProMemberPostLikes";
import ProMemberComments from "components/Community/ProMemberComments";
import InputCommentProMember from "components/Community/InputCommentProMember";
import ProMemberPostComments from "components/Community/ProMemberPostComments";

const ProMemberPost = (props) => {
  const [userArr, setUserInfo] = useState({});
  const [imgs, setImgs] = useState([]);
  const [inputcmnt, setinputcmnt] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const focusOnComment = (e, id) => {
    if (inputcmnt) {
      setinputcmnt(false);
    } else {
      setinputcmnt(true);
    }
  };
  useEffect(() => {
    fetchUser();
    return () => {
      setUserInfo({});
    };
  }, []);

  const fetchUser = async () => {
    const userCollection = collection(db, "users");
    const getUser = query(
      userCollection,
      where("handle", "==", props.item.userHandle)
    );
    const querySnapshot = await getDocs(getUser);

    querySnapshot.forEach((doc) => {
      let user = doc.data();
      setUserInfo(user);
    });
  };

  useEffect(() => {
    setImgs(props.item.fileContent);
  }, [props.item.fileContent]);

  useEffect(() => {
    let isLoading = true;
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("Time is up!");
      }, 1000);
    }).then((value) => {
      if (isLoading) {
        setLoading(false);
      }
    });
    // Clean-up:
    return () => {
      isLoading = false;
    };
  }, []);

  return (
    <>
      {props.item ? (
        <Card className="shadow-lg  bg-white rounded mb-5">
          <CardBody className="p-0 pb-3">
            {props.item.category ? (
              <Badge
                className="ml-1"
                color="primary"
                style={{
                  position: "absolute",
                  top: "-15px",
                  left: 0,
                }}
              >
                {props.item.category}
              </Badge>
            ) : (
              ""
            )}

            <div className="d-flex justify-content-between post-header m-2">
              <div className="media mt-2 ml-1">
                {props.item.anonymous == 1 ? (
                  <div className="rounded-circle profile-letter-all-users mr-2">
                    {typeof userArr.anonymousName != "undefined"
                      ? userArr.anonymousName.trim()[0]
                      : "A"}
                  </div>
                ) : typeof userArr.profilePicture != "undefined" ? (
                  <img
                    src={
                      "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
                      userArr.profilePicture
                    }
                    className="mr-3 rounded-circle"
                    alt="..."
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                ) : (
                  <div className="rounded-circle profile-letter-all-users mr-2">
                    {typeof userArr.name != "undefined"
                      ? userArr.name.trim()[0]
                      : ""}
                  </div>
                )}

                <div className="media-body">
                  <h5 className="mt-0 mb-0">
                    {props.item.anonymous == 1
                      ? typeof userArr.anonymousName != "undefined"
                        ? userArr.anonymousName
                        : "Anonymous"
                      : userArr.name
                      ? userArr.name
                      : ""}{" "}
                    {props.item.anonymous == 1 ? (
                      ""
                    ) : userArr.coach || userArr.coach == "true" ? (
                      <Badge className="ml-2 text-dark" color="info" pill>
                        Coach
                      </Badge>
                    ) : (
                      ""
                    )}
                  </h5>
                  <small className="text-muted">
                    {moment(props.item.createdAt).fromNow()}
                  </small>
                </div>
              </div>
              <div className="mr-2">
                {userArr.handle == props.userDetails.handle ? (
                  <ProMemberActionPost
                    key={props.item.postid}
                    item={props.item}
                    postid={props.item.postid}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="m-3 post-body">
              <p className="text-break">{props.item.textContent}</p>
            </div>
            <div>
              {imgs.length >= 2 ? (
                <ImageGrid>
                  {imgs
                    .filter((arg, i) => (i + 1 <= imgs.length ? true : false))
                    .map((a, i) =>
                      a.file.mimeType == "video/mp4" ? (
                        <video
                          controls
                          width="200"
                          height="150"
                          style={{ backgroundColor: "#000" }}
                          key={i}
                        >
                          <source
                            type={a.file.mimeType}
                            src={
                              "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                              a.name
                            }
                          />
                        </video>
                      ) : (
                        <img
                          src={
                            "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                            a.name
                          }
                          alt={i}
                          key={Math.random(i)}
                          width="200"
                          height="150"
                          style={{ border: "1px solid #ddd" }}
                        />
                      )
                    )}
                </ImageGrid>
              ) : imgs.length == 1 ? (
                imgs[0].file.mimeType == "video/mp4" ? (
                  <ImageGrid>
                    <video controls key={0}>
                      <source
                        type={imgs[0].file.mimeType}
                        src={
                          "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                          imgs[0].name
                        }
                      />
                    </video>
                  </ImageGrid>
                ) : (
                  <ImageGrid>
                    <img
                      className="img-fluid"
                      alt={imgs[0].name}
                      src={
                        "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                        imgs[0].name
                      }
                      key={0}
                    />
                  </ImageGrid>
                )
              ) : (
                ""
              )}
            </div>
            <div
              className="d-flex align-items-center justify-content-between px-1 py-1 m-2"
              style={{
                fontSize: "14px",
              }}
            >
              <div className="font-weight-bold ">
                <ProMemberPostLikes
                  key={props.item.postid}
                  postid={props.item.postid}
                />
              </div>
              <div className="font-weight-bold ">
                <ProMemberPostComments
                  key={props.item.postid}
                  postid={props.item.postid}
                />
              </div>
            </div>
            <div className="border-top  mb-2"></div>
            <div className="d-flex align-items-center justify-content-between p-2">
              <LikeTheProMemberPost
                key={"lp-" + props.item.postid}
                item={props.item}
              />
              <Button
                color=""
                className=""
                onClick={(e) => focusOnComment(e, props.item.postid)}
              >
                <i className="fa fa-regular fa-comment font-weight-normal d-md-none d-lg-none "></i>
                <span className="d-none d-md-block d-lg-block">
                  <i className="fa fa-regular fa-comment font-weight-normal "></i>{" "}
                  Comment
                </span>
              </Button>

              <SaveProMemberPost
                key={"sp-" + props.item.postid}
                item={props.item}
              />

              {/* <Button color="" className="" >
                                <i className="fa fa-regular fa-bookmark font-weight-normal d-md-none d-lg-none"></i>     
                                <span className="d-none d-md-block d-lg-block"><i className="fa fa-regular fa-bookmark font-weight-normal "></i> Save</span>
                            </Button>  */}
            </div>
            <div className="border-top  mb-2"></div>
            {inputcmnt ? (
              <InputCommentProMember
                key={"ctp-" + props.item.postid}
                item={props.item}
              />
            ) : (
              ""
            )}
            <ProMemberComments item={props.item} />
            <div className=" mt-2"></div>
          </CardBody>
        </Card>
      ) : (
        <Col>
          <Card className="shadow-lg  bg-white rounded mb-5">
            <CardBody>
              <div
                className=""
                style={{
                  fontSize: "14px",
                }}
              >
                <div className="media">
                  <img
                    src="https://picsum.photos/id/135/318/180?grayscale&blur=10"
                    className="mr-3 rounded-circle placeholder placeholder-glow"
                    alt="..."
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                  <div className="media-body">
                    <div className="m-0  placeholder-glow ">
                      <span className="col-lg-6 placeholder"></span>
                    </div>
                    <div className="m-0  placeholder-glow ">
                      <span className="col-lg-6 placeholder"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="  px-1 py-1 mt-3"
                style={{
                  fontSize: "14px",
                }}
              >
                <div className="font-weight-bold ">
                  <span className="mt-0  placeholder-glow ">
                    <span className="col-lg-6 placeholder"></span>
                  </span>
                  <span className="placeholder-glow ">
                    <span className="col-lg-6 placeholder"></span>
                  </span>
                </div>
                <div className="font-weight-bold ">
                  <span className="mt-0  placeholder-glow ">
                    <span className="col-lg-12 placeholder"></span>
                  </span>
                </div>
              </div>

              {/* Comment */}
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
  };
};

export default connect(mapStateToProps)(ProMemberPost);
