import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import ClientLayout from 'layouts/Client'
const  ClientRoute = ({component:Component, authenticated, admin, ...rest}) => {
    return (
        <Route {...rest}
            render = {(props) => {
                
                if (authenticated === true ){
                    if (admin === true ){
                        
                        return <Redirect to = "/admin/dashboard"/>
                    } else {
                        
                        return <ClientLayout><Component {...props}/></ClientLayout>
                    }
                } else {
                    return <Redirect to = "/auth/login"/>
                }
            } 
            }
        />
)}
const mapStateToProps = (state) => ({
    authenticated: state.loginAuth.authenticated,
    admin: state.loginAuth.admin
})


export default connect(mapStateToProps)(ClientRoute)
