import axios from "axios";
import { 
    OPEN_COURSE_MODAL,
    CLOSE_COURSE_MODAL,
    CREATE_COURSE_REQUEST, 
    CREATE_COURSE_SUCCESS, 
    CREATE_COURSE_FAILURE,
    CREATE_COURSE_RESET,
    FETCH_COURSES_REQUEST, 
    FETCH_COURSES_SUCCESS, 
    FETCH_COURSES_FAILURE,
    EDIT_COURSE,
    NEW_COURSE,
    UPDATE_COURSE_REQUEST, 
    UPDATE_COURSE_SUCCESS, 
    UPDATE_COURSE_FAILURE,
    UPDATE_COURSE_RESET,
    DELETE_COURSE_REQUEST,
    DELETE_COURSE_SUCCESS, 
    DELETE_COURSE_FAILURE,
    DELETE_COURSE_RESET  } from "./CoursesTypes";

import { fetchGrade } from "redux/Grades/GradesActions";

export const createCourseReset = () => {
    return {
        type: CREATE_COURSE_RESET,
    }
}

const createCourseRequest = () => {
    return {
        type: CREATE_COURSE_REQUEST,
    }
}

const createCourseSuccess = success => {
    return {
        type: CREATE_COURSE_SUCCESS,
        payload: success
    }
}

const createCourseFailure = error => {
    return {
        type: CREATE_COURSE_FAILURE,
        payload : error
    }
}


export const createCourse = (data) => {

    return (dispatch) => {

        dispatch(createCourseRequest());
        
        
        


        if(data['inputBCPM'] == "Yes"){
            data['inputBCPM'] = true;
        }   
        else{
            data['inputBCPM'] = false;
        }

        if(data['inputQuarter'] == "Yes"){
            data['inputQuarter'] = true;
        }   
        else{
            data['inputQuarter'] = false;
        }

        axios.post('/storeCourse', data)
        .then(response => {
            const course = response.data
            dispatch(createCourseSuccess(course))
            dispatch(fetchCourse({route: "", userId: data['userId'], handle: data['handle']}))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createCourseFailure(errorMsg))
        })
        
    }

}




const fetchCourseRequest = () => {
    return {
        type: FETCH_COURSES_REQUEST,
    }
}

const fetchCourseSuccess = success => {
    return {
        type: FETCH_COURSES_SUCCESS,
        payload: success
    }
}

const fetchCourseFailure = error => {
    return {
        type: FETCH_COURSES_FAILURE,
        payload : error
    }
}

export const fetchCourse = (data) => {

    const fetchCourseRoute = data.route;
    
    return (dispatch) => {
        
        dispatch(fetchCourseRequest());
        
        
        axios.post('/getCourses' + fetchCourseRoute , data)
        .then(response => {
            const course = response.data
            dispatch(fetchCourseSuccess(course))
            
            dispatch(fetchGrade({handle : data.handle}));
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchCourseFailure(errorMsg))
        })
        
    }

}


export const updateCourseReset = () => {
    return {
        type: UPDATE_COURSE_RESET,
    }
}

const updateCourseRequest = () => {
    return {
        type: UPDATE_COURSE_REQUEST,
    }
}

const updateCourseSuccess = success => {
    return {
        type: UPDATE_COURSE_SUCCESS,
        payload: success
    }
}

const updateCourseFailure = error => {
    return {
        type: UPDATE_COURSE_FAILURE,
        payload : error
    }
}

export const updateCourse = (data) => {

    return (dispatch) => {

        dispatch(updateCourseRequest());
        
       
       


        if(data['inputBCPM'] == "Yes"){
            data['inputBCPM'] = true;
        }   
        else{
            data['inputBCPM'] = false;
        }

        if(data['inputQuarter'] == "Yes"){
            data['inputQuarter'] = true;
        }   
        else{
            data['inputQuarter'] = false;
        }
        
        axios.post('/updateCourse', data)
        .then(response => {
            const course = response.data
            dispatch(updateCourseSuccess(course))
            dispatch(fetchCourse({route: "",userId: data['userId'], handle: data['handle']}))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateCourseFailure(errorMsg))
        })
        
    }

}



const openCourseModal = status => {
    return {
        type: OPEN_COURSE_MODAL,
        payload : status
    }
}

const closeCourseModal = status => {
    return {
        type: CLOSE_COURSE_MODAL,
        payload : status
    }
}

export const updateCourseModal = (data) => {

    return (dispatch) => {
        if (data) {

            dispatch(openCourseModal(data))

        } 
        else{

            dispatch(closeCourseModal(data))

        } 
    }

}

export const editCourse = data => {
    
    return {
        type: EDIT_COURSE,
        payload : data
    }
}

export const newCourse = data => {
    
    return {
        type: NEW_COURSE,
        payload : data
    }
}



export const deleteCourseReset = () => {
    return {
        type: DELETE_COURSE_RESET,
    }
}

const deleteCourseRequest = () => {
    return {
        type: DELETE_COURSE_REQUEST,
    }
}

const deleteCourseSuccess = success => {
    return {
        type: DELETE_COURSE_SUCCESS,
        payload: success
    }
}

const deleteCourseFailure = error => {
    return {
        type: DELETE_COURSE_FAILURE,
        payload : error
    }
}

export const deleteCourse = (data) => {

    return (dispatch) => {

        dispatch(deleteCourseRequest());
        
        axios.post('/deleteCourse', data)
        .then(response => {
            const course = response.data
            dispatch(deleteCourseSuccess(course))
            dispatch(fetchCourse({route: "", userId: data['userId'], handle: data['handle']}))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteCourseFailure(errorMsg))
        })
        
    }

}