import React, {useEffect, useState} from 'react'
import { Button, Modal, ModalBody, ModalFooter, Card, CardHeader, Row, Col, FormGroup, Input, CardBody, Form, Progress, Table  } from 'reactstrap';



import {useForm} from 'react-hook-form';
import Swal from 'sweetalert2'

import { createMcatScore, createMcatScoreReset, editMcatScore, newMcatScore,updateMcatScore, updateMcatScoreReset } from 'redux/McatScores/McatScoresActions';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import Test from 'components/Options/McatScores/Test';
import { testMcatScore } from 'util/DataList';
import { aamcCarsScore,aamcChemScore, aamcBioChemScore, aamcTotalScore,aamcPsychSocScore} from 'util/AamcScoreConversion';

import  Select  from 'react-select';
import { customDropdownWithoutDotColor } from 'util/reactCustomStyles';


const McatScoresModal = (props) => {

  const dispatch = useDispatch();

  const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();

  let toggleAddMcatScores = props.toggleAddMcatScores;
  let modalAddMcatScores = props.modalAddMcatScores;

  const messageRequired = "This field is required.";
  


  const [mcatScoreId, setMcatScoreId] = useState('');

  const [chemPhysScore, setChemPhysScore] = useState(0);
  const [carsScore, setCarsScore] = useState(0);
  const [bioBiochemScore, setbioBiochemScore] = useState(0);
  const [psychSocScore, setPsychSocScore] = useState(0);

  
  useEffect(() => {
    register("inputTest", { required: messageRequired });
    register("inputDate", { required: messageRequired });
    register("inputChemPhys", { required: messageRequired, min:{value:118, message:"Must be greater than or equal to 118"}, max:{value:132, message:"Must be less than or equal to 132"} });
    register("inputCars", { required: messageRequired, min:{value:118, message:"Must be greater than or equal to 118"}, max:{value:132, message:"Must be less than or equal to 132"} });
    register("inputBioBiochem", { required: messageRequired, min:{value:118, message:"Must be greater than or equal to 118"}, max:{value:132, message:"Must be less than or equal to 132"}});
    register("inputPsychSoc", { required: messageRequired, min:{value:118, message:"Must be greater than or equal to 118"}, max:{value:132, message:"Must be less than or equal to 132"} });
    // premedReqs.map((value,index)=>{
    //   register("inputRequirement." + index, { required: messageRequired })
    // });


    // setValue("inputChemPhys", 118)
    // setValue("inputCars", 118)
    // setValue("inputBioBiochem", 118)
    // setValue("inputPsychSoc", 118)

    if(props.editMcatScore.status){
          
      const mcatScoreData  = findMcatScore(props.fetchMcatScores.mcatscores,props.editMcatScore.mcatScoreId);

      setMcatScoreId(props.editMcatScore.mcatScoreId);


      if(testMcatScore.includes(mcatScoreData.label)){
        setValue("inputTest", mcatScoreData.label)
        unregister("inputTestOther");
      }
      else{
        
        register("inputTestOther", { required: messageRequired});
        setValue("inputTest","Other");
        setValue("inputTestOther", mcatScoreData.label);
      }
     
      
      setValue("inputDate", mcatScoreData.date)
      setValue("inputChemPhys", mcatScoreData.chemPhys)
      setValue("inputCars", mcatScoreData.cars)
      setValue("inputBioBiochem", mcatScoreData.bioBiochem)
      setValue("inputPsychSoc", mcatScoreData.psychSoc)
      

      setChemPhysScore(mcatScoreData.chemPhys);
      setCarsScore(mcatScoreData.cars);
      setbioBiochemScore(mcatScoreData.bioBiochem);
      setPsychSocScore(mcatScoreData.psychSoc);
      
      dispatch(editMcatScore({
          status : false,
          mcatScoreId : ""
      }));

    }
    if(props.newMcatScore.status){
      setMcatScoreId('');
      setValue("inputTestOther", "")
      setValue("inputTest", "")
      setValue("inputDate", "")
      setValue("inputChemPhys", "")
      setValue("inputCars","")
      setValue("inputBioBiochem", "")
      setValue("inputPsychSoc","")
      setChemPhysScore(0);
      setCarsScore(0);
      setbioBiochemScore(0);
      setPsychSocScore(0);
      dispatch(newMcatScore({status : false}));
    }
    
  })

  if(props.createMcatScores.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.createMcatScores.mcatscore.length && typeof props.createMcatScores.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully added new Mcat Score!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(createMcatScoreReset());
        
      }

      else if(typeof props.createMcatScores.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to add new Mcat Score!',
            icon :'error',
            timer: 3000
            
          }
        )     
        dispatch(createMcatScoreReset());       
        
      }

    }


    if(props.updateMcatScores.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.updateMcatScores.mcatscore.length && typeof props.updateMcatScores.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully updated Mcat Score!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(updateMcatScoreReset());
        
        
      }

      else if(typeof props.updateMcatScores.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to update Mcat Score!',
            icon :'error',
            timer: 3000
          }
        )            

        dispatch(updateMcatScoreReset());
        
      }

      

    }



  const findMcatScore = (arr,mcatScoreId) => {
      
    return arr.find(x => x.mcatScoreId === mcatScoreId);
  }

  const onSubmit = (data) => {


    data.inputHandle = props.userDetails.handle;
    data.handle = props.userDetails.handle;
    data.userId = props.userDetails.userId;

    if(mcatScoreId){
      data['docId'] = mcatScoreId;
      dispatch(updateMcatScore(data));
    }
    else{
      dispatch(createMcatScore(data));
    }
    
  }

  const handleInputChemPhys = (e) =>{
    setValue("inputChemPhys", e.target.value)
    if(e.target.value >= 1 && e.target.value  <= 59){
      setChemPhysScore(e.target.value);
    }
    else{
      setChemPhysScore(0);
    }
    
  }

  const handleInputCars = (e) =>{
    setValue("inputCars", e.target.value)
    if(e.target.value >= 1 && e.target.value  <= 53){
      setCarsScore(e.target.value)
    }
    else{
      setCarsScore(0);
    }
  }

  const handleInputBioBiochem = (e) =>{
    setValue("inputBioBiochem", e.target.value)
    if(e.target.value >= 1 && e.target.value  <= 59){
      setbioBiochemScore(e.target.value)
    }
    else{
      setbioBiochemScore(0);
    }
  }

  const handleInputPsychSoc = (e) =>{
    setValue("inputPsychSoc", e.target.value)

    if(e.target.value >= 1 && e.target.value  <= 59){
      setPsychSocScore(e.target.value)
    }
    else{
      setPsychSocScore(0);
    }
    
  }

  const handleInputTest = (e) => {

    setValue("inputTest", e.value)

    if(e.value == 'Other'){
      register("inputTestOther", { required: messageRequired});
    }
    else{
      unregister("inputTestOther");
    }
    
  }
  
  return (
    <div>
        
        <Modal className="modal-dialog-centered" size="lg" isOpen={modalAddMcatScores} toggle={toggleAddMcatScores} >
          <Form  onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className='modal-body p-0'>
          <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{mcatScoreId ? "Edit" : "Add" } Mcat Scores</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                
                  <h6 className="heading-small text-muted mb-4">
                    MCAT Score information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputTest"
                          >
                            Name of test <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            placeholder='Select name of test'
                            className="form-control-alternative"
                            id="inputTest"
                            name="inputTest"
                            value={watch("inputTest")}
                            onChange={e => handleInputTest(e) }
                            type="select"
                          >
                            <Test></Test>
                          </Input>  */}

                          <Select 
                             placeholder='Select name of test'
                            options={testMcatScore} 
                            styles={customDropdownWithoutDotColor }  
                            onChange={(e) => handleInputTest(e)}
                            menuPortalTarget={document.body}
                            value={testMcatScore.find((option) => option.value === watch("inputTest"))}
                          />

                          
                        { errors.inputTest && <small className="text-danger">{errors.inputTest.message}</small> }
                        </FormGroup>
                        
                      </Col>
                      <Col lg="6" className={getValues("inputTest") == "Other" ? "" : "d-none"}>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputTestOther"
                          >
                          <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputTestOther"
                            name="inputTestOther"
                            value={watch("inputTestOther")}
                            onChange={e => setValue("inputTestOther", e.target.value) }
                            type="text"
                          />
                            
                          
                          
                        { errors.inputTestOther && <small className="text-danger">{errors.inputTestOther.message}</small> }
                        </FormGroup>
                        
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputDate"
                          >
                            Date <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative is-invalid"
                            id="inputDate"
                            name="inputDate"
                            value={watch("inputDate")}
                            onChange={e => setValue("inputDate", e.target.value)}
                            type="date"
                          />
                          { errors.inputDate && <small className="text-danger">{errors.inputDate.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputChemPhys"
                          >
                            CHEMISTRY/PHYSICS <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputChemPhys"
                            name="inputChemPhys"
                            value={watch("inputChemPhys")}
                            onChange={e => handleInputChemPhys(e)}
                            type="number"
                          >
                          </Input>
                          { errors.inputChemPhys && <small className="text-danger">{errors.inputChemPhys.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputCars"
                          >
                            CARS <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputCars"
                            name="inputCars"
                            value={watch("inputCars")}
                            onChange={e => handleInputCars(e)}
                            type="number"
                          />
                          
                          { errors.inputCars && <small className="text-danger">{errors.inputCars.message}</small> }
                        </FormGroup>
                      </Col>
                    
                     
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputBioBiochem"
                          >
                            BIOLOGY/BIOCHEM <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputBioBiochem"
                            name="inputBioBiochem"
                            value={watch("inputBioBiochem")}
                            onChange={e => handleInputBioBiochem(e)}
                            type="number"
                            
                          />
                          { errors.inputBioBiochem && <small className="text-danger">{errors.inputBioBiochem.message}</small> }
                        </FormGroup>
                      </Col>
                      
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputPsychSoc"
                          >
                            PSYCHOLOGY/SOCIOLOGY <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputPsychSoc"
                            type="text"
                            name="inputPsychSoc"
                            value={watch("inputPsychSoc")}
                            onChange={e => handleInputPsychSoc(e)}
                          />
                         { errors.inputPsychSoc && <small className="text-danger">{errors.inputPsychSoc.message}</small> } 
                        </FormGroup>
                      </Col>

                    </Row>
                  </div>
                  
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
          {props.adminUser.admin ? "" :
              <>
            <Button color="primary" type='submit'>
            {mcatScoreId ? "Update" : "Save" } 
            </Button>{' '}
            <Button color="secondary" onClick={toggleAddMcatScores}>
              Cancel
            </Button>
            </>}
          </ModalFooter>
          </Form>
        </Modal>
      </div>
  )
}

const mapStateToProps = state => {
  return {
    createMcatScores : state.createMcatScores,
    updateMcatScores : state.updateMcatScores,
    editMcatScore : state.editMcatScore,
    fetchMcatScores : state.fetchMcatScores,
    newMcatScore : state.newMcatScore,
    userDetails : state.loginAuth.userDetails,
    adminUser : state.adminUser
  }
}

export default connect(mapStateToProps)(McatScoresModal)