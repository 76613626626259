/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import {useHistory} from 'react-router-dom'
import { useEffect,useState } from "react";
import {set, useForm} from 'react-hook-form';
import { signupUser,signupUserReset } from "redux/UserAuthentication/RegisterActions";
import Swal from 'sweetalert2'
import { loginUser } from "redux/UserAuthentication/LoginActions";
const Register = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [confirmPasswordMatch, setConfirmPasswordMatch] = useState(false);
  const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();
  
  const messageRequired = "This field is required.";

  useEffect(() => {

    register("inputUsername", { required: messageRequired
     });
    register("inputFullname", { required: messageRequired });
    register("inputPhoneNumber", { required: messageRequired });
    register("inputPassword", { 
      required: messageRequired,
      pattern: {
        // value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          value : "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$",
            message: "Minimum eight characters, at least one letter, one number and one special character."
        } ,
       
      });
    register("inputConfirmPassword", { required: messageRequired });

    register("inputEmail", { 
        required: messageRequired, 
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address."
        } 
    });  

    
    

  }, [])

  if(props.signupUser.loading){
    Swal.fire({
      allowOutsideClick : false,
      didOpen: () => {
        Swal.showLoading()
        
      }
    });

  }
  else{
    
    
    if(props.signupUser.signupuser.length && typeof props.signupUser.error === "undefined"){
      Swal.fire({
          title: 'Success!',
          text: 'Registered successfully!',
          icon : 'success',
          timer: 3000
          
        }
      ).then(function (result) {
          
        dispatch(loginUser({email : getValues('inputEmail'), password : getValues('inputConfirmPassword')}));

        // history.push("/auth/login");
      
      });

      dispatch(signupUserReset());
      
    }

    else if(typeof props.signupUser.error !== "undefined"){

      if(props.signupUser.error.hasOwnProperty('email')){
        Swal.fire({
          title:'Failed!',
          text:'Email is already in use!',
          icon :'error',
          timer: 3000
          
        })

        
      }
      else if(props.signupUser.error.hasOwnProperty('handle')){
        Swal.fire({
          title:'Failed!',
          text:'Username is already in use!',
          icon :'error',
          timer: 3000
          
        })

        
      }
      else if(props.signupUser.error.hasOwnProperty('general')){
        Swal.fire({
          title:'Failed!',
          text:'Something went wrong, Please try again!',
          icon :'error',
          timer: 3000
          
        })

        
      }
      else{
        Swal.fire({
          title:'Failed!',
          text:'Something went wrong, Please try again!',
          icon :'error',
          timer: 3000
          
        })
      }
          
      dispatch(signupUserReset());       
      
    }

  }

  const formatDate = (date) => {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }
  const onSubmit = (data) => {

    

    
    if(data.inputPassword == data.inputConfirmPassword){
      
      data['name'] = data.inputFullname;
      data['email'] = data.inputEmail;
      data['password'] = data.inputPassword;
      data['confirmPassword'] = data.inputConfirmPassword;
      data['handle'] = data.inputUsername;
      data['tpcEntryDate'] = formatDate(new Date());
      data['subscriptions'] = [];
      data['tutorHours'] = 5;
      data['phoneNumber'] = data.inputPhoneNumber;
      data['admin'] = false;
      
      dispatch(signupUser(data));
    }
    else{
       setConfirmPasswordMatch(true);
    }
    // data.inputHandle = props.userDetails.handle;
    // data.handle = props.userDetails.handle;
    // data.userId = props.userDetails.userId;

    // if(mcatScoreId){
    //   data['docId'] = mcatScoreId;
    //   dispatch(updateMcatScore(data));
    // }
    // else{
    //   dispatch(createMcatScore(data));
    // }
    
  }

  
  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          {/* <CardHeader className="bg-transparent pb-5"> */}
            {/* <div className="text-muted text-center mt-2 mb-4">
              <small>Sign up with</small>
            </div>
            <div className="text-center">
              <Button
                className="btn-neutral btn-icon mr-4"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/github.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div> */}
          {/* </CardHeader> */}
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign up</small>
            </div>
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              {/* <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Username"
                    className="form-control-alternative"
                    id="inputUsername"
                    name="inputUsername"
                    
                    onChange={e => setValue("inputUsername", e.target.value) }
                    type="text"
                  />
                  
                </InputGroup>
                { errors.inputUsername && <small className="text-danger">{errors.inputUsername.message}</small> }                
              </FormGroup> */}
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Full Name"
                    className="form-control-alternative"
                    id="inputFullname"
                    name="inputFullname"
                    
                    onChange={e => setValue("inputFullname", e.target.value) }
                    type="text"
                  />
                  
                </InputGroup>
                { errors.inputFullname && <small className="text-danger">{errors.inputFullname.message}</small> }
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    className="form-control-alternative"
                    id="inputEmail"
                    name="inputEmail"
                    
                    onChange={e => {setValue("inputEmail", e.target.value); setValue("inputUsername", e.target.value)} }
                    type="email"
                  />
                  
                </InputGroup>
                { errors.inputEmail && <small className="text-danger">{errors.inputEmail.message}</small> }
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-phone" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Phone number"
                    className="form-control-alternative"
                    id="inputPhoneNumber"
                    name="inputPhoneNumber"
                    
                    onChange={e => {setValue("inputPhoneNumber", e.target.value);} }
                    type="text"
                  />
                  
                </InputGroup>
                { errors.inputPhoneNumber && <small className="text-danger">{errors.inputPhoneNumber.message}</small> }
              </FormGroup>


              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    className="form-control-alternative"
                    id="inputPassword"
                    name="inputPassword"
                    
                    onChange={e => setValue("inputPassword", e.target.value) }
                    type="password"
                  />
                  
                </InputGroup>

                { errors.inputPassword && <small className="text-danger">{errors.inputPassword.message}</small> }
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm Password"
                    className="form-control-alternative"
                    id="inputConfirmPassword"
                    name="inputConfirmPassword"
                    
                    onChange={e => setValue("inputConfirmPassword", e.target.value) }
                    type="password"
                  />
                  
                </InputGroup>

                { errors.inputConfirmPassword && <small className="text-danger">{errors.inputConfirmPassword.message}</small> }
                {confirmPasswordMatch && <small className="text-danger">Passwords do not match.</small> }
              </FormGroup>
              {/* <div className="text-muted font-italic">
                <small>
                  password strength:{" "}
                  <span className="text-success font-weight-700">strong</span>
                </small>
              </div> */}
              {/* <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row> */}
              <div className="text-center">
                <Button className="mt-4" color="primary" type='submit'>
                  Create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const mapStateToProps = state => {
  return {
    signupUser : state.signupUser,
  }
}

export default connect(mapStateToProps)(Register);
