import React, { useRef, useState, useEffect } from 'react';

import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink, 
} from "reactstrap";
import Header from 'components/Headers/Header';
import { connect } from 'react-redux';
import ManagePosts from 'components/Community/ManagePosts';
import ManageComments from 'components/Community/ManageComments';
import ManageReplies from 'components/Community/ManageReplies';
import classnames from 'classnames';
const CommunitySettings = (props) => {
    //Tabs
    const tabs = ['Posts', 'Comments', 'Replies'];
    const [activeTab, setActiveTab] = useState('Posts');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (  
        <>
       <Header></Header>
            <Container className="mt--7" fluid>
                <Row>
                    <Col>
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                              
                                <Nav  pills >
                                    { tabs.length > 0 ?
                                    tabs.map((c) => {
                                        return (
                                            <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === c })}
                                                    onClick={() => { toggle(c); }}
                                                    style={{ cursor: 'pointer' }}
                                            >
                                                {c}
                                            </NavLink>
                                        </NavItem>
                                        )
                                    }) : ''
                                    }
                                </Nav>
                               
                            </CardHeader>
                            <CardBody>
                                <TabContent activeTab={activeTab}>
                                    {
                                        
                                        tabs.map((c) => { 
                                            return(
                                                <TabPane tabId={c}>
                                                    <Row className="pl-4 pr-4 mt-3">
                                                        <Col lg='12' className='mt-3 d-flex align-items-center justify-content-center' >
                                                            <Row className="" style={{ width: '100%' }}>
                                                                <Col lg="12">
                                                                    {
                                                                        c == 'Posts' ?
                                                                            <ManagePosts />
                                                                        : c == 'Comments' ?
                                                                            <ManageComments />
                                                                        :  <ManageReplies />
                                                                      
                                                                    }
                                                                </Col>                         
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            )      
                                        }) 
                                    }
                                
                                </TabContent>
                               
                            </CardBody>
                        </Card>
                       
                    </Col> 
                </Row>
            </Container>

        </>
    )
  
}

const mapStateToProps = state  => {
    return {
       
    }
      
}

export default connect(mapStateToProps)(CommunitySettings)

