import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header'
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  CardFooter,
  Button,
  Table,
  Badge
} from "reactstrap";
import moment from 'moment';
import ReactDatetime from "react-datetime";
import { connect, useDispatch } from 'react-redux';
import { createCoachTutorAvailability, createCoachTutorAvailabilityReset, fetchCoachTutorAvailability, deleteCoachTutorAvailability, deleteCoachTutorAvailabilityReset, fetchHistoryCoachTutorAvailability } from 'redux/ManageCoachTutorAvailability/ManageCoachTutorAvailabilityActions';
import Swal from 'sweetalert2'
const ManageCoachTutorAvailability = (props) => {

  const dispatch = useDispatch()


  let twoDaysBefore = moment().add( 1, 'day' );
  let valid = function( current ){
      return current.isAfter( twoDaysBefore );
  };
  

  const [date, setDate] = useState();
  const [timeFrom, setTimeFrom] = useState();
  const [timeTo, setTimeTo] = useState();
  const [description, setDescription] = useState("");

  const handleDateOnChange = (e) => {
    const paramDate = e.toJSON();

    

    const intervalDuration = 60 * 60 * 1000;
    const rangeTo = new Date(paramDate)
    rangeTo.setTime(rangeTo.getTime() + intervalDuration);
    setDate(new Date(paramDate))
    setTimeFrom(new Date(paramDate))
    setTimeTo(rangeTo)

  }

  const handleTimeFromOnChange = (e) => {
    const paramDate = e.toJSON();
    setTimeFrom(new Date(paramDate))

  }

  const handleTimeToOnChange = (e) => {
    const paramDate = e.toJSON();
    setTimeTo(new Date(paramDate))

  }

  const handleCoachTutorAvailabilityOnClick = (e) => {
    e.preventDefault();

    const data = {
      inputDate : moment(date).utcOffset(0, true).format(),
      inputTimeFrom : moment(timeFrom).utcOffset(0, true).format(),
      inputTimeTo : moment(timeTo).utcOffset(0, true).format(),
      inputDescription : description,
      email : props.userDetails.email,
      handle : props.userDetails.handle
    }
    

    if(data.inputDate == "" || data.inputTimeFrom == "" || data.inputTimeTo == ""){
      Swal.fire({
          title: 'Failed!',
          text: 'Fill all the inputs.',
          icon : 'error',
          timer: 3000
        }
      )
    }
    else{
      
      if(data.inputTimeFrom > data.inputTimeTo){
        Swal.fire({
            title: 'Failed!',
            text: 'Time To must be later than or equal to Time From',
            icon : 'error',
            timer: 3000
          }
        )
      }
      else{
        dispatch(createCoachTutorAvailability(data))
      }
    }

    
  }

  useEffect(() => {

    if(props.createCoachTutorAvailability.loading){
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });
  
    }
    else{
      if(props.createCoachTutorAvailability.createcoachtutoravailability.length){
        Swal.fire({
          title: 'Success!',
          text: 'Successfully saved tutor availability!',
          icon : 'success',
          timer: 3000
        }
        ).then(function (result) {
          dispatch(fetchCoachTutorAvailability({ handle : props.userDetails.handle, currentDate : moment(new Date()).add( 1, 'day' ).utcOffset(0, true).format()}))
          dispatch(fetchHistoryCoachTutorAvailability({ handle : props.userDetails.handle, currentDate : moment(new Date()).add( 1, 'day' ).utcOffset(0, true).format()}))
          dispatch(createCoachTutorAvailabilityReset())
        
        });
      }
      else if(typeof props.createCoachTutorAvailability.error !== "undefined"){

        Swal.fire({
          title: 'Failed!',
          text: 'Failed to save tutor availability',
          icon : 'error',
          timer: 3000
        }
        ).then(function (result) {
          dispatch(fetchCoachTutorAvailability({ handle : props.userDetails.handle, currentDate : moment(new Date()).add( 1, 'day' ).utcOffset(0, true).format()}))
          dispatch(fetchHistoryCoachTutorAvailability({ handle : props.userDetails.handle, currentDate : moment(new Date()).add( 1, 'day' ).utcOffset(0, true).format()}))
          dispatch(createCoachTutorAvailabilityReset())
        
        });

      }
    }

    

  }, [props.createCoachTutorAvailability])

  
  const [availableSchedule, setAvailableSchedule] = useState([]);
  const [historyAvailableSchedule, setHistoryAvailableSchedule] = useState([]);

  useEffect(() => {
    if(props.fetchCoachTutorAvailability.loading){
      Swal.fire({
          allowOutsideClick : false,
          didOpen: () => {
              Swal.showLoading()
              
          }
      });
    }
    else{
        Swal.close()
    }

    if(props.fetchCoachTutorAvailability.fetchcoachtutoravailability.length){
      setAvailableSchedule(props.fetchCoachTutorAvailability.fetchcoachtutoravailability[0])
    }
    
  }, [props.fetchCoachTutorAvailability])

  useEffect(() => {

    if(props.fetchHistoryCoachTutorAvailability.loading){
      Swal.fire({
          allowOutsideClick : false,
          didOpen: () => {
              Swal.showLoading()
              
          }
      });
    }
    else{
        Swal.close()
    }

    if(props.fetchHistoryCoachTutorAvailability.fetchhistorycoachtutoravailability.length){
      setHistoryAvailableSchedule(props.fetchHistoryCoachTutorAvailability.fetchhistorycoachtutoravailability[0])
    }
    
  }, [props.fetchHistoryCoachTutorAvailability])

  


  const handleDeleteCoachTutorAvailabilityOnClick = (e, docId) => {
    e.preventDefault()

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        dispatch(deleteCoachTutorAvailability({docId:docId}))

      }
    })

    

  }

  useEffect(() => {

    if(props.deleteCoachTutorAvailability.loading){
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });
  
    }
    else{
      if(props.deleteCoachTutorAvailability.deletecoachtutoravailability.length){
        Swal.fire({
          title: 'Success!',
          text: 'Successfully deleted tutor availability!',
          icon : 'success',
          timer: 3000
        }
        ).then(function (result) {
          dispatch(fetchCoachTutorAvailability({ handle : props.userDetails.handle, currentDate : moment(new Date()).add( 1, 'day' ).utcOffset(0, true).format()}))
          dispatch(fetchHistoryCoachTutorAvailability({ handle : props.userDetails.handle, currentDate : moment(new Date()).add( 1, 'day' ).utcOffset(0, true).format()}))
          dispatch(deleteCoachTutorAvailabilityReset())
        
        });
      }
      else if(typeof props.deleteCoachTutorAvailability.error !== "undefined"){

        Swal.fire({
          title: 'Failed!',
          text: 'Failed to delete tutor availability',
          icon : 'error',
          timer: 3000
        }
        ).then(function (result) {
          dispatch(fetchCoachTutorAvailability({ handle : props.userDetails.handle, currentDate : moment(new Date()).add( 1, 'day' ).utcOffset(0, true).format()}))
          dispatch(fetchHistoryCoachTutorAvailability({ handle : props.userDetails.handle, currentDate : moment(new Date()).add( 1, 'day' ).utcOffset(0, true).format()}))
          dispatch(deleteCoachTutorAvailabilityReset())
        
        });

      }
    }

    

  }, [props.deleteCoachTutorAvailability])
  

  useEffect(() => {
    dispatch(fetchCoachTutorAvailability({ handle : props.userDetails.handle, currentDate : moment(new Date()).add( 1, 'day' ).utcOffset(0, true).format()}))

    dispatch(fetchHistoryCoachTutorAvailability({ handle : props.userDetails.handle, currentDate : moment(new Date()).add( 1, 'day' ).utcOffset(0, true).format()}))
  }, [])
  
  
  return (
    <>
      <Header></Header>

      <Container className="mt--7" fluid>
          <Row>
              <div className="col">
                  
                  <Card className="shadow">
                      <CardHeader className="border-1">
                          <div className='float-left'>
                              <h5 className="h3 mb-0">Manage Coach Tutor Availability</h5>
                          </div>
                      </CardHeader>
                      <CardBody>

                        <h6 className="heading-small text-muted mb-4">
                          Set Availability
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Date <span className='text-danger'>*</span>
                                </label>
                                <ReactDatetime onChange={e => handleDateOnChange(e)} isValidDate={ valid } timeFormat={false} className="w-100" inputProps={{placeholder:"Select Date"}}/>
                              </FormGroup>
                            </Col>
                            <Col lg="6"></Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  From <span className='text-danger'>*</span>
                                </label>
                                <ReactDatetime value={timeFrom} onChange={e => handleTimeFromOnChange(e)} timeConstraints={{minutes:{ min: 0 ,max:0, step: 0}}} dateFormat={false} className="w-100" inputProps={{placeholder:"Select time"}}/>
                              </FormGroup>
                              
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  To <span className='text-danger'>*</span>
                                </label>
                                <ReactDatetime value={timeTo} onChange={e => handleTimeToOnChange(e)} timeConstraints={{minutes:{ min: 0 ,max:0, step: 0}}} dateFormat={false} className="w-100" inputProps={{placeholder:"Select time"}}/>
                              </FormGroup>
                              
                            </Col>

                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Description for student
                                </label>
                                <Input
                                  id="inputNotesForStudents"
                                  name="inputExperienceDescription"
                                  
                                  placeholder="Write a description..."
                                  rows="5"
                                  type="textarea"
                                  onChange={e => setDescription(e.target.value)}
                                />
                              </FormGroup>
                              
                            </Col>

                          </Row>
                        </div>

                            <Col lg='12 mt-3'>
                            
                              
                            </Col>

                      </CardBody>
                      <CardFooter >

                        <Button color="primary" onClick={e => handleCoachTutorAvailabilityOnClick(e)}  type='submit' >
                              Save
                        </Button>

                      </CardFooter>
                  </Card>
                  
              </div>
          </Row>

          <Row className='mt-4'>
              <div className="col">
                  
                  <Card className="shadow">
                      <CardHeader className="border-1">
                          <div className='float-left'>
                              <h5 className="h3 mb-0">Available Schedule</h5>
                          </div>
                      </CardHeader>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>

                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                          {availableSchedule.length ?

                            
                              
                              availableSchedule.map((val, index) => {
                                let sfrom =  moment(new Date(val.time)).utcOffset(0, false)
                                return <tr>
                                  <td>{moment(val.date).utcOffset(0, false).format("YYYY-MM-DD")}</td>
                                  <td>{  sfrom.format("h:00 A") +'-'+ sfrom.add(1,'hours').format("h:00 A") }</td>
                                  <td><button onClick={e => handleDeleteCoachTutorAvailabilityOnClick(e, val.docId)} className='btn btn-danger'>Delete</button></td>
                                </tr>

                              })
                            
                              :
                              <tr>
                              <td colspan="3" className='text-center'>No Available Schedule</td>
                          </tr>
                          }

                            
                        </tbody>
                      </Table>
                      <CardFooter >


                      </CardFooter>
                  </Card>
                  
              </div>
          </Row>
          <Row className='mt-4 mb-5'>
              <div className="col">
                  
                  <Card className="shadow">
                      <CardHeader className="border-1">
                          <div className='float-left'>
                              <h5 className="h3 mb-0">History</h5>
                          </div>
                      </CardHeader>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                          { historyAvailableSchedule.length ? 
                            
                              
                              historyAvailableSchedule.map((val, index) => {
                                 let sfrom =  moment(new Date(val.time)).utcOffset(0, false)
                                return   <tr>
                                  <td>{moment(val.date).utcOffset(0, false).format("YYYY-MM-DD")}</td>
                                  <td>{sfrom.format("h:00 A") +'-'+ sfrom.add(1,'hours').format("h:00 A") }</td>
                                  <td>
                                    <Badge color="" className="badge-dot mr-4">
                                      <i className={val.status == "available" ? "bg-warning" : "bg-success"} />
                                                {val.status == "available" ? "expired" : "success"}
                                    </Badge>
                                  </td>
                                </tr>
                             
                              })
                            

                            :
                            <tr>
                                <td colspan="5" className='text-center'>No Available History</td>
                            </tr>
                          }

                            
                        </tbody>
                      </Table>
                      <CardFooter >


                      </CardFooter>
                  </Card>
                  
              </div>
          </Row>
      </Container>
    </>
  )
}


const mapStateToProps = state  => {
  return {
      userDetails : state.loginAuth.userDetails,
      createCoachTutorAvailability : state.createCoachTutorAvailability,
      fetchCoachTutorAvailability : state.fetchCoachTutorAvailability,
      fetchHistoryCoachTutorAvailability : state.fetchHistoryCoachTutorAvailability,
      deleteCoachTutorAvailability : state.deleteCoachTutorAvailability
  }
    
}

export default connect(mapStateToProps)(ManageCoachTutorAvailability)