import { 
    CREATE_CUSTOMER_FAILURE,
    CREATE_CUSTOMER_REQUEST,
    CREATE_CUSTOMER_RESET,
    CREATE_CUSTOMER_SUCCESS,
    CREATE_SUBSCRIPTION_FAILURE,
    CREATE_SUBSCRIPTION_REQUEST,
    CREATE_SUBSCRIPTION_RESET,
    CREATE_SUBSCRIPTION_SUCCESS,

    CANCEL_SUBSCRIPTION_FAILURE,
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_RESET,
    CANCEL_SUBSCRIPTION_SUCCESS,

    UPDATE_SUBSCRIPTION_FAILURE,
    UPDATE_SUBSCRIPTION_REQUEST,
    UPDATE_SUBSCRIPTION_RESET,
    UPDATE_SUBSCRIPTION_SUCCESS,

    RESUME_SUBSCRIPTION_FAILURE,
    RESUME_SUBSCRIPTION_REQUEST,
    RESUME_SUBSCRIPTION_RESET,
    RESUME_SUBSCRIPTION_SUCCESS,

    FETCH_DETAILS_SUBSCRIPTION_FAILURE,
    FETCH_DETAILS_SUBSCRIPTION_REQUEST,
    FETCH_DETAILS_SUBSCRIPTION_RESET,
    FETCH_DETAILS_SUBSCRIPTION_SUCCESS,

    OPEN_MANAGE_SUBSCRIPTION_MODAL,
    CLOSE_MANAGE_SUBSCRIPTION_MODAL,

    OPEN_BUY_TUTORING_HOURS_MODAL,
    CLOSE_BUY_TUTORING_HOURS_MODAL,

    CREATE_TUTORING_HOURS_PAYMENT_FAILURE,
    CREATE_TUTORING_HOURS_PAYMENT_REQUEST,
    CREATE_TUTORING_HOURS_PAYMENT_RESET,
    CREATE_TUTORING_HOURS_PAYMENT_SUCCESS,

    UPDATE_TUTORING_HOURS_AVAILABLE_FAILURE,
    UPDATE_TUTORING_HOURS_AVAILABLE_REQUEST,
    UPDATE_TUTORING_HOURS_AVAILABLE_RESET,
    UPDATE_TUTORING_HOURS_AVAILABLE_SUCCESS

} from "./PaymentsTypes";

import axios from "axios";

export const createCustomerReset = () => {
    return {
        type: CREATE_CUSTOMER_RESET,
    }
}

const createCustomerRequest = () => {
    return {
        type: CREATE_CUSTOMER_REQUEST,
    }
}

const createCustomerSuccess = success => {
    return {
        type: CREATE_CUSTOMER_SUCCESS,
        payload: success
    }
}

const createCustomerFailure = error => {
    return {
        type: CREATE_CUSTOMER_FAILURE,
        payload : error
    }
}

export const createCustomer = (data) => {

    return (dispatch) => {

        dispatch(createCustomerRequest());
        
        
        
        axios.post('/createCustomer', data)
        .then(response => {
            const createcustomer = response.data
            dispatch(createCustomerSuccess(createcustomer))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createCustomerFailure(errorMsg))
        })
        
    }

}


export const createSubscriptionReset = () => {
    return {
        type: CREATE_SUBSCRIPTION_RESET,
    }
}

const createSubscriptionRequest = () => {
    return {
        type: CREATE_SUBSCRIPTION_REQUEST,
    }
}

const createSubscriptionSuccess = success => {
    return {
        type: CREATE_SUBSCRIPTION_SUCCESS,
        payload: success
    }
}

const createSubscriptionFailure = error => {
    return {
        type: CREATE_SUBSCRIPTION_FAILURE,
        payload : error
    }
}

export const createSubscription = (data) => {

    return (dispatch) => {

        dispatch(createSubscriptionRequest());
        
        
        
        axios.post('/createSubscription', data)
        .then(response => {
            const createsubcription = response.data
            dispatch(createSubscriptionSuccess(createsubcription))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createSubscriptionFailure(errorMsg))
        })
        
    }

}


export const cancelSubscriptionReset = () => {
    return {
        type: CANCEL_SUBSCRIPTION_RESET,
    }
}

const cancelSubscriptionRequest = () => {
    return {
        type: CANCEL_SUBSCRIPTION_REQUEST,
    }
}

const cancelSubscriptionSuccess = success => {
    return {
        type: CANCEL_SUBSCRIPTION_SUCCESS,
        payload: success
    }
}

const cancelSubscriptionFailure = error => {
    return {
        type: CANCEL_SUBSCRIPTION_FAILURE,
        payload : error
    }
}

export const cancelSubscription = (data) => {

    return (dispatch) => {

        dispatch(cancelSubscriptionRequest());
        
        
        
        axios.post('/cancelSubscription', data)
        .then(response => {
            const cancelsubcription = response.data
            dispatch(cancelSubscriptionSuccess(cancelsubcription))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(cancelSubscriptionFailure(errorMsg))
        })
        
    }

}

export const updateSubscriptionReset = () => {
    return {
        type: UPDATE_SUBSCRIPTION_RESET,
    }
}

const updateSubscriptionRequest = () => {
    return {
        type: UPDATE_SUBSCRIPTION_REQUEST,
    }
}

const updateSubscriptionSuccess = success => {
    return {
        type: UPDATE_SUBSCRIPTION_SUCCESS,
        payload: success
    }
}

const updateSubscriptionFailure = error => {
    return {
        type: UPDATE_SUBSCRIPTION_FAILURE,
        payload : error
    }
}

export const updateSubscription = (data) => {

    return (dispatch) => {

        dispatch(updateSubscriptionRequest());
        
        
        
        axios.post('/updateSubscription', data)
        .then(response => {
            const updatesubcription = response.data
            dispatch(updateSubscriptionSuccess(updatesubcription))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateSubscriptionFailure(errorMsg))
        })
        
    }

}

const openManageSubscriptionModal = status => {
    return {
        type: OPEN_MANAGE_SUBSCRIPTION_MODAL,
        payload : status
    }
}

const closeManageSubscriptionModal = status => {
    return {
        type: CLOSE_MANAGE_SUBSCRIPTION_MODAL,
        payload : status
    }
}


export const manageSubscription = (data) => {

    return (dispatch) => {
        if (data) {

            dispatch(openManageSubscriptionModal(data))

        } 
        else{

            dispatch(closeManageSubscriptionModal(data))

        } 
    }

}


export const resumeSubscriptionReset = () => {
    return {
        type: RESUME_SUBSCRIPTION_RESET,
    }
}

const resumeSubscriptionRequest = () => {
    return {
        type: RESUME_SUBSCRIPTION_REQUEST,
    }
}

const resumeSubscriptionSuccess = success => {
    return {
        type: RESUME_SUBSCRIPTION_SUCCESS,
        payload: success
    }
}

const resumeSubscriptionFailure = error => {
    return {
        type: RESUME_SUBSCRIPTION_FAILURE,
        payload : error
    }
}

export const resumeSubscription = (data) => {

    return (dispatch) => {

        dispatch(resumeSubscriptionRequest());
        
        
        
        axios.post('/resumeSubscription', data)
        .then(response => {
            const resumesubcription = response.data
            dispatch(resumeSubscriptionSuccess(resumesubcription))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(resumeSubscriptionFailure(errorMsg))
        })
        
    }

}


export const fetchDetailsSubscriptionReset = () => {
    return {
        type: FETCH_DETAILS_SUBSCRIPTION_RESET,
    }
}

const fetchDetailsSubscriptionRequest = () => {
    return {
        type: FETCH_DETAILS_SUBSCRIPTION_REQUEST,
    }
}

const fetchDetailsSubscriptionSuccess = success => {
    return {
        type: FETCH_DETAILS_SUBSCRIPTION_SUCCESS,
        payload: success
    }
}

const fetchDetailsSubscriptionFailure = error => {
    return {
        type: FETCH_DETAILS_SUBSCRIPTION_FAILURE,
        payload : error
    }
}

export const fetchDetailsSubscription = (data) => {

    return (dispatch) => {

        dispatch(fetchDetailsSubscriptionRequest());
        
        axios.post('/fetchDetailsSubscription', data)
        .then(response => {
            const fetchDetailsSubscription = response.data
            dispatch(fetchDetailsSubscriptionSuccess(fetchDetailsSubscription))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchDetailsSubscriptionFailure(errorMsg))
        })
        
    }

}


const openBuyTutoringHoursModal = status => {
    return {
        type: OPEN_BUY_TUTORING_HOURS_MODAL,
        payload : status
    }
}

const closeBuyTutoringHoursModal = status => {
    return {
        type: CLOSE_BUY_TUTORING_HOURS_MODAL,
        payload : status
    }
}


export const buyTutoringHours = (data) => {

    return (dispatch) => {
        if (data) {

            dispatch(openBuyTutoringHoursModal(data))

        } 
        else{

            dispatch(closeBuyTutoringHoursModal(data))

        } 
    }

}



export const createTutoringHoursPaymentReset = () => {
    return {
        type: CREATE_TUTORING_HOURS_PAYMENT_RESET,
    }
}

const createTutoringHoursPaymentRequest = () => {
    return {
        type: CREATE_TUTORING_HOURS_PAYMENT_REQUEST,
    }
}

const createTutoringHoursPaymentSuccess = success => {
    return {
        type: CREATE_TUTORING_HOURS_PAYMENT_SUCCESS,
        payload: success
    }
}

const createTutoringHoursPaymentFailure = error => {
    return {
        type: CREATE_TUTORING_HOURS_PAYMENT_FAILURE,
        payload : error
    }
}

export const createTutoringHoursPayment = (data) => {

    return (dispatch) => {

        dispatch(createTutoringHoursPaymentRequest());
        
        
        
        axios.post('/createTutoringHoursPayment', data)
        .then(response => {
            const createtutoringhourspayment = response.data
            dispatch(createTutoringHoursPaymentSuccess(createtutoringhourspayment))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createTutoringHoursPaymentFailure(errorMsg))
        })
        
    }

}



export const updateTutoringHoursAvailableReset = () => {
    return {
        type: UPDATE_TUTORING_HOURS_AVAILABLE_RESET,
    }
}

const updateTutoringHoursAvailableRequest = () => {
    return {
        type: UPDATE_TUTORING_HOURS_AVAILABLE_REQUEST,
    }
}

const updateTutoringHoursAvailableSuccess = success => {
    return {
        type: UPDATE_TUTORING_HOURS_AVAILABLE_SUCCESS,
        payload: success
    }
}

const updateTutoringHoursAvailableFailure = error => {
    return {
        type: UPDATE_TUTORING_HOURS_AVAILABLE_FAILURE,
        payload : error
    }
}

export const updateTutoringHoursAvailable = (data) => {

    return (dispatch) => {

        dispatch(updateTutoringHoursAvailableRequest());
        
        
        
        axios.post('/updateTutoringHoursAvailable', data)
        .then(response => {
            const updateTutoringHoursAvailable = response.data
            dispatch(updateTutoringHoursAvailableSuccess(updateTutoringHoursAvailable))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateTutoringHoursAvailableFailure(errorMsg))
        })
        
    }

}


