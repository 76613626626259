import { 
    CREATE_CUSTOMER_FAILURE,
    CREATE_CUSTOMER_REQUEST,
    CREATE_CUSTOMER_RESET,
    CREATE_CUSTOMER_SUCCESS,
    CREATE_SUBSCRIPTION_FAILURE,
    CREATE_SUBSCRIPTION_REQUEST,
    CREATE_SUBSCRIPTION_RESET,
    CREATE_SUBSCRIPTION_SUCCESS,

    CANCEL_SUBSCRIPTION_FAILURE,
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_RESET,
    CANCEL_SUBSCRIPTION_SUCCESS,

    UPDATE_SUBSCRIPTION_FAILURE,
    UPDATE_SUBSCRIPTION_REQUEST,
    UPDATE_SUBSCRIPTION_RESET,
    UPDATE_SUBSCRIPTION_SUCCESS,

    RESUME_SUBSCRIPTION_FAILURE,
    RESUME_SUBSCRIPTION_REQUEST,
    RESUME_SUBSCRIPTION_RESET,
    RESUME_SUBSCRIPTION_SUCCESS,

    FETCH_DETAILS_SUBSCRIPTION_FAILURE,
    FETCH_DETAILS_SUBSCRIPTION_REQUEST,
    FETCH_DETAILS_SUBSCRIPTION_RESET,
    FETCH_DETAILS_SUBSCRIPTION_SUCCESS,

    OPEN_MANAGE_SUBSCRIPTION_MODAL,
    CLOSE_MANAGE_SUBSCRIPTION_MODAL,

    OPEN_BUY_TUTORING_HOURS_MODAL,
    CLOSE_BUY_TUTORING_HOURS_MODAL,

    CREATE_TUTORING_HOURS_PAYMENT_FAILURE,
    CREATE_TUTORING_HOURS_PAYMENT_REQUEST,
    CREATE_TUTORING_HOURS_PAYMENT_RESET,
    CREATE_TUTORING_HOURS_PAYMENT_SUCCESS,

    UPDATE_TUTORING_HOURS_AVAILABLE_FAILURE,
    UPDATE_TUTORING_HOURS_AVAILABLE_REQUEST,
    UPDATE_TUTORING_HOURS_AVAILABLE_RESET,
    UPDATE_TUTORING_HOURS_AVAILABLE_SUCCESS

} from "./PaymentsTypes";


const initialCreateCustomerState = {
    createcustomer: [],
    loading : false
}

export const createCustomerReducer = (state = initialCreateCustomerState, action) => {
    switch(action.type){
        case CREATE_CUSTOMER_RESET:
            return {
                loading: false,
                createcustomer: []
            }
        case CREATE_CUSTOMER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_CUSTOMER_SUCCESS:
            return {
                loading: false,
                createcustomer: [action.payload]
            }
        case CREATE_CUSTOMER_FAILURE:
            return {
                loading : false,
                createcustomer : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialCreateSubscriptionState = {
    createsubscription: [],
    loading : false
}

export const createSubscriptionReducer = (state = initialCreateSubscriptionState, action) => {
    switch(action.type){
        case CREATE_SUBSCRIPTION_RESET:
            return {
                loading: false,
                createsubscription: []
            }
        case CREATE_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_SUBSCRIPTION_SUCCESS:
            return {
                loading: false,
                createsubscription: [action.payload]
            }
        case CREATE_SUBSCRIPTION_FAILURE:
            return {
                loading : false,
                createsubscription : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialCancelSubscriptionState = {
    cancelsubscription: [],
    loading : false
}

export const cancelSubscriptionReducer = (state = initialCancelSubscriptionState, action) => {
    switch(action.type){
        case CANCEL_SUBSCRIPTION_RESET:
            return {
                loading: false,
                cancelsubscription: []
            }
        case CANCEL_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                loading: false,
                cancelsubscription: [action.payload]
            }
        case CANCEL_SUBSCRIPTION_FAILURE:
            return {
                loading : false,
                cancelsubscription : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialUpdateSubscriptionState = {
    updatesubscription: [],
    loading : false
}

export const updateSubscriptionReducer = (state = initialUpdateSubscriptionState, action) => {
    switch(action.type){
        case UPDATE_SUBSCRIPTION_RESET:
            return {
                loading: false,
                updatesubscription: []
            }
        case UPDATE_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_SUBSCRIPTION_SUCCESS:
            return {
                loading: false,
                updatesubscription: [action.payload]
            }
        case UPDATE_SUBSCRIPTION_FAILURE:
            return {
                loading : false,
                updatesubscription : [],
                error: action.payload
            }
        default: return state;
    }
}



const initialModalManageSubscriptionState = {
    status: false
}


export const manageSubscriptionModalStatusReducer = (state = initialModalManageSubscriptionState, action) => {
    switch (action.type) {
        case OPEN_MANAGE_SUBSCRIPTION_MODAL:
            return {
                status : true
            }
        case CLOSE_MANAGE_SUBSCRIPTION_MODAL:
            return {
                status : false
            }
        default: return state;
    }
}

const initialResumeSubscriptionState = {
    resumesubscription: [],
    loading : false
}

export const resumeSubscriptionReducer = (state = initialResumeSubscriptionState, action) => {
    switch(action.type){
        case RESUME_SUBSCRIPTION_RESET:
            return {
                loading: false,
                resumesubscription: []
            }
        case RESUME_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case RESUME_SUBSCRIPTION_SUCCESS:
            return {
                loading: false,
                resumesubscription: [action.payload]
            }
        case RESUME_SUBSCRIPTION_FAILURE:
            return {
                loading : false,
                resumesubscription : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialFetchDetailsSubscriptionState = {
    loading: false,
    fetchdetailssubscription: [],
}

export const fetchDetailsSubscriptionReducer = (state = initialFetchDetailsSubscriptionState, action) => {
    switch (action.type) {
        case FETCH_DETAILS_SUBSCRIPTION_RESET:
            return {
                loading: false,
                fetchdetailssubscription: [],
            }
        case FETCH_DETAILS_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_DETAILS_SUBSCRIPTION_SUCCESS:
            return {
                loading: false,
                fetchdetailssubscription: [action.payload]
            }
        case FETCH_DETAILS_SUBSCRIPTION_FAILURE:
            return {
                loading: false,
                fetchdetailssubscription: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialModalBuyTutoringHoursState = {
    status: false
}


export const buyTutoringHoursModalStatusReducer = (state = initialModalBuyTutoringHoursState, action) => {
    switch (action.type) {
        case OPEN_BUY_TUTORING_HOURS_MODAL:
            return {
                status : true
            }
        case CLOSE_BUY_TUTORING_HOURS_MODAL:
            return {
                status : false
            }
        default: return state;
    }
}


const initialcreateTutoringHoursPaymentState = {
    createtutoringhourspayment: [],
    loading : false
}

export const createTutoringHoursPaymentReducer = (state = initialcreateTutoringHoursPaymentState, action) => {
    switch(action.type){
        case CREATE_TUTORING_HOURS_PAYMENT_RESET:
            return {
                loading: false,
                createtutoringhourspayment: []
            }
        case CREATE_TUTORING_HOURS_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_TUTORING_HOURS_PAYMENT_SUCCESS:
            return {
                loading: false,
                createtutoringhourspayment: [action.payload]
            }
        case CREATE_TUTORING_HOURS_PAYMENT_FAILURE:
            return {
                loading : false,
                createtutoringhourspayment : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialUpdateTutoringHoursAvailableState = {
    updatetutoringhoursavailable: [],
    loading : false
}

export const updateTutoringHoursAvailableReducer = (state = initialUpdateTutoringHoursAvailableState, action) => {
    switch(action.type){
        case UPDATE_TUTORING_HOURS_AVAILABLE_RESET:
            return {
                loading: false,
                updatetutoringhoursavailable: []
            }
        case UPDATE_TUTORING_HOURS_AVAILABLE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_TUTORING_HOURS_AVAILABLE_SUCCESS:
            return {
                loading: false,
                updatetutoringhoursavailable: [action.payload]
            }
        case UPDATE_TUTORING_HOURS_AVAILABLE_FAILURE:
            return {
                loading : false,
                updatetutoringhoursavailable : [],
                error: action.payload
            }
        default: return state;
    }
}