
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCBSAgrdhRDxji66qR9dZMq6gLGFK4Ve28",
  authDomain: "pre-pa-tracker.firebaseapp.com",
  projectId: "pre-pa-tracker",
  storageBucket: "pre-pa-tracker.appspot.com",
  messagingSenderId: "531184024533",
  appId: "1:531184024533:web:4e5b3bdf4375821d69db5c",
  measurementId: "G-SGWW347M9K"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);




