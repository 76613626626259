
import { CREATE_SIGNUP_USER_RESET, CREATE_SIGNUP_USER_REQUEST, CREATE_SIGNUP_USER_SUCCESS, CREATE_SIGNUP_USER_FAILURE } from "./RegisterTypes"


const initialSignupUserState = {
    loading: false,
    signupuser: [],
}

export const signupUserReducer  = (state = initialSignupUserState, action) => {
    switch (action.type) {
        case CREATE_SIGNUP_USER_RESET:
            return {
                loading: false,
                signupuser: [],
            }
        case CREATE_SIGNUP_USER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_SIGNUP_USER_SUCCESS:
            return {
                loading: false,
                signupuser: [action.payload]
            }
        case CREATE_SIGNUP_USER_FAILURE:
            return {
                loading: false,
                signupuser: [],
                error: action.payload
            }
        default:
            return state;
    }
}