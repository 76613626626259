import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";

import { fetchCourse, updateCourseModal, editCourse, deleteCourse, deleteCourseReset } from 'redux/Courses/CoursesActions';
import Swal from 'sweetalert2'
import { connect } from 'react-redux';
const CoursesTable = (props) => {

    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if(props.userDetails.userId){
            const data = {
                route : "",
                userId : props.userDetails.userId,
                handle:props.userDetails.handle
            }
            dispatch(fetchCourse(data))
        }
    }, [props.userDetails])
    
    useEffect(() => {
        
        setList(props.fetchCourses.courses.slice(0, 5))
        
        
    }, [props.fetchCourses])

    if(props.deleteCourse.course.length && typeof props.deleteCourse.error === "undefined"){
        
        Swal.fire({
            title: 'Deleted!',
            text: 'Successfully deleted Course!',
            icon : 'success',
            timer: 3000      
          }
        )
        dispatch(deleteCourseReset());
        
        
      }

    else if(typeof props.deleteCourse.error !== "undefined"){
        
        Swal.fire({
            title:'Failed!',
            text:'Failed to delete Course!',
            icon :'error',
            timer: 3000 
            }
        )            
        dispatch(deleteCourseReset());
    }

    const onClickEditCourse = (e,courseId) => {
        e.preventDefault();
        dispatch(editCourse({
            status : true,
            courseId : courseId
        }));

        dispatch(updateCourseModal(!props.updateCourseModalStatus.status));
    }

    const onClickDeleteCourse = (e,courseId, userId, handle) => {
        e.preventDefault();
        

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCourse({
                    docId : courseId,
                    userId : userId,
                    handle : handle
                }));

            }
          })
    }

    const showNext = (e,item) => {
        e.preventDefault();
        
        if(list.length !== 0) {
            dispatch(fetchCourse({route:"Next" , item:item.item, userId:props.userDetails.userId, handle:props.userDetails.handle}))
            setList(props.fetchCourses.courses)
            setPage(page + 1)
        } 
    };

    const showPrevious = ( e,item ) => {
        e.preventDefault();
        if(list.length !== 0) {
            dispatch(fetchCourse({route:"Previous", item:item.item, userId:props.userDetails.userId, handle:props.userDetails.handle}))
            setList(props.fetchCourses.courses)
            setPage(page - 1)
        } 
    };
    
  return (
    <>
        <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
                <tr>
                    <th scope="col">COURSE NAME</th>
                    <th scope="col">COURSE NUMBER</th>
                    <th scope="col">TERM</th>
                    <th scope="col">GRADE</th>
                    <th scope="col">CREDITS</th>
                    <th scope="col">SCIENCE GPA?</th>
                    <th scope="col">SCHOOL YEAR</th>
                    <th scope="col">ACTION</th>
                </tr>
            </thead>
            <tbody>
                
                
                {
                    list.map((val) => {
                    
                    return <tr key = {val.courseId}>
                            <td scope="row">
                                {val.courseName}
                            </td>
                            <td>
                                {val.courseNumber}
                            </td>
                            <td>
                                {val.term}
                            </td>
                            <td>
                                {val.grade}
                            </td>
                            <td>
                                {val.credits}
                            </td>
                            <td>
                                {(val.bcpm) ? "Yes" : "No"}
                            </td>
                            <td>
                                 {val.section}
                            </td>
                            <td className="">
                                {props.adminUser.admin ?
                                    <a href="#pablo" className="btn btn-sm btn-primary" onClick={(e) => onClickEditCourse(e,val.courseId)}><i className="fa fa-eye"></i> View</a>
                                :
                                    <>
                                        <a href="#pablo" className="btn btn-sm btn-primary" onClick={(e) => onClickEditCourse(e,val.courseId)}><i className="fa fa-pen"></i> Edit</a>
                                        <a href="#pablo" className="btn btn-sm btn-danger" onClick={(e) => onClickDeleteCourse(e,val.courseId,val.userId, val.handle)}><i className="fa fa-trash"></i> Delete</a>
                                    </>
                                }
                                {/* <UncontrolledDropdown>
                                <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => onClickEditCourse(e,val.courseId)}
                                    >
                                    {props.adminUser.admin ? "View" : "Edit"}
                                    </DropdownItem>
                                    <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => onClickDeleteCourse(e,val.courseId,val.userId, val.handle)}
                                    >
                                    Delete
                                    </DropdownItem>
                                </DropdownMenu>
                                </UncontrolledDropdown> */}
                            </td>
                        </tr>
                    })

                }
                    
                    
                
            </tbody>
        </Table>
        <CardFooter className="py-4">
            <nav aria-label="...">
                <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
                >
                <PaginationItem className={page === 1 ? 'disabled' : ''}>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => showPrevious(e,{ item: list[0] })}
                    tabIndex="-1"
                    >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {/* <PaginationItem className="active">
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    2 <span className="sr-only">(current)</span>
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                    3
                    </PaginationLink>
                </PaginationItem> */}
                <PaginationItem className={list.length < 5 ? 'disabled' : ''}>
                    <PaginationLink
                    href="#pablo"
                    onClick={(e) => showNext(e,{ item: list[list.length - 1] })}
                    >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
                </Pagination>
            </nav>
        </CardFooter>
    </>
  )
}

const mapStateToProps = state => {
    return {
        fetchCourses : state.fetchCourses,
        updateCourseModalStatus : state.updateCourseModalStatus,
        deleteCourse : state.deleteCourse,
        userDetails : state.loginAuth.userDetails,
        adminUser : state.adminUser
    }
  }

export default connect(mapStateToProps)(CoursesTable)