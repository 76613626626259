import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import AdminLayout from 'layouts/Admin'
const  AdminRoute = ({component:Component, authenticated, admin, ...rest}) => {
    
    return (
        <Route {...rest}
            render = {(props) => {
                if (authenticated === true ){
                    if (admin === true ){
                        
                        return <AdminLayout><Component {...props}/></AdminLayout>
                    } else {
                        
                        return <Redirect to = "/client/dashboard"/>
                    }
                } else {
                    return <Redirect to = "/auth/login"/>
                }
            }
            }
        />
)}
const mapStateToProps = (state) => ({
    authenticated: state.loginAuth.authenticated,
    admin: state.loginAuth.admin
})

export default connect(mapStateToProps)(AdminRoute)
