import axios from "axios";
import { 
    OPEN_WORK_ACTIVITY_MODAL,
    CLOSE_WORK_ACTIVITY_MODAL,
    CREATE_WORK_ACTIVITY_REQUEST,
    CREATE_WORK_ACTIVITY_SUCCESS, 
    CREATE_WORK_ACTIVITY_FAILURE,
    CREATE_WORK_ACTIVITY_RESET,
    FETCH_WORK_ACTIVITIES_REQUEST,
    FETCH_WORK_ACTIVITIES_SUCCESS, 
    FETCH_WORK_ACTIVITIES_FAILURE,
    UPDATE_WORK_ACTIVITY_REQUEST,
    UPDATE_WORK_ACTIVITY_SUCCESS, 
    UPDATE_WORK_ACTIVITY_FAILURE,
    UPDATE_WORK_ACTIVITY_RESET,
    DELETE_WORK_ACTIVITY_REQUEST,
    DELETE_WORK_ACTIVITY_SUCCESS, 
    DELETE_WORK_ACTIVITY_FAILURE,
    DELETE_WORK_ACTIVITY_RESET,
    EDIT_WORK_ACTIVITY,

    UPDATE_IMPORTANT_WORK_ACTIVITY_REQUEST,
    UPDATE_IMPORTANT_WORK_ACTIVITY_SUCCESS, 
    UPDATE_IMPORTANT_WORK_ACTIVITY_FAILURE,
    UPDATE_IMPORTANT_WORK_ACTIVITY_RESET,
    NEW_WORK_ACTIVITY } from "./WorkActivitiesTypes"

export const createWorkActivityReset = () => {
    return {
        type: CREATE_WORK_ACTIVITY_RESET,
    }
}

const createWorkActivityRequest = () => {
    return {
        type: CREATE_WORK_ACTIVITY_REQUEST,
    }
}

const createWorkActivitySuccess = success => {
    return {
        type: CREATE_WORK_ACTIVITY_SUCCESS,
        payload: success
    }
}

const createWorkActivityFailure = error => {
    return {
        type: CREATE_WORK_ACTIVITY_FAILURE,
        payload : error
    }
}

export const createWorkActivity = (data) => {

    return (dispatch) => {

        dispatch(createWorkActivityRequest());
        
        
        
        axios.post('/storeWorkActivity', data)
        .then(response => {
            const workactivity = response.data
            dispatch(createWorkActivitySuccess(workactivity))
            dispatch(fetchWorkActivity({route: "", userId : data.userId}))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createWorkActivityFailure(errorMsg))
        })
        
    }

}




const fetchWorkActivityRequest = () => {
    return {
        type: FETCH_WORK_ACTIVITIES_REQUEST,
    }
}

const fetchWorkActivitySuccess = success => {
    return {
        type: FETCH_WORK_ACTIVITIES_SUCCESS,
        payload: success
    }
}

const fetchWorkActivityFailure = error => {
    return {
        type: FETCH_WORK_ACTIVITIES_FAILURE,
        payload : error
    }
}

export const fetchWorkActivity = (data) => {

    const fetchWorkActivityRoute = data.route;
    
    return (dispatch) => {
        
        dispatch(fetchWorkActivityRequest());
        
        axios.post('/getWorkActivities' + fetchWorkActivityRoute , data)
        .then(response => {
            const workactivity = response.data
            dispatch(fetchWorkActivitySuccess(workactivity))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchWorkActivityFailure(errorMsg))
        })
        
    }

}


export const updateWorkActivityReset = () => {
    return {
        type: UPDATE_WORK_ACTIVITY_RESET,
    }
}

const updateWorkActivityRequest = () => {
    return {
        type: UPDATE_WORK_ACTIVITY_REQUEST,
    }
}

const updateWorkActivitySuccess = success => {
    return {
        type: UPDATE_WORK_ACTIVITY_SUCCESS,
        payload: success
    }
}

const updateWorkActivityFailure = error => {
    return {
        type: UPDATE_WORK_ACTIVITY_FAILURE,
        payload : error
    }
}

export const updateWorkActivity = (data) => {

    return (dispatch) => {

        dispatch(updateWorkActivityRequest());
        
        
        
        axios.post('/updateWorkActivity', data)
        .then(response => {
            const workactivity = response.data
            dispatch(updateWorkActivitySuccess(workactivity))
            dispatch(fetchWorkActivity({route: "", userId : data.userId}))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateWorkActivityFailure(errorMsg))
        })
        
    }

}



const openWorkActivityModal = status => {
    return {
        type: OPEN_WORK_ACTIVITY_MODAL,
        payload : status
    }
}

const closeWorkActivityModal = status => {
    return {
        type: CLOSE_WORK_ACTIVITY_MODAL,
        payload : status
    }
}

export const updateWorkActivityModal = (data) => {

    return (dispatch) => {
        if (data) {

            dispatch(openWorkActivityModal(data))

        } 
        else{

            dispatch(closeWorkActivityModal(data))

        } 
    }

}

export const editWorkActivity = data => {
    
    return {
        type: EDIT_WORK_ACTIVITY,
        payload : data
    }
}

export const newWorkActivity = data => {
    
    return {
        type: NEW_WORK_ACTIVITY,
        payload : data
    }
}



export const deleteWorkActivityReset = () => {
    return {
        type: DELETE_WORK_ACTIVITY_RESET,
    }
}

const deleteWorkActivityRequest = () => {
    return {
        type: DELETE_WORK_ACTIVITY_REQUEST,
    }
}

const deleteWorkActivitySuccess = success => {
    return {
        type: DELETE_WORK_ACTIVITY_SUCCESS,
        payload: success
    }
}

const deleteWorkActivityFailure = error => {
    return {
        type: DELETE_WORK_ACTIVITY_FAILURE,
        payload : error
    }
}

export const deleteWorkActivity = (data) => {

    return (dispatch) => {

        dispatch(deleteWorkActivityRequest());
        
        axios.post('/deleteWorkActivity', data)
        .then(response => {
            const workactivity = response.data
            dispatch(deleteWorkActivitySuccess(workactivity))
            dispatch(fetchWorkActivity({route: "", userId : data.userId}))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteWorkActivityFailure(errorMsg))
        })
        
    }

}


export const updateImportantWorkActivityReset = () => {
    return {
        type: UPDATE_IMPORTANT_WORK_ACTIVITY_RESET,
    }
}

const updateImportantWorkActivityRequest = () => {
    return {
        type: UPDATE_IMPORTANT_WORK_ACTIVITY_REQUEST,
    }
}

const updateImportantWorkActivitySuccess = success => {
    return {
        type: UPDATE_IMPORTANT_WORK_ACTIVITY_SUCCESS,
        payload: success
    }
}

const updateImportantWorkActivityFailure = error => {
    return {
        type: UPDATE_IMPORTANT_WORK_ACTIVITY_FAILURE,
        payload : error
    }
}

export const updateImportantWorkActivity = (data) => {

    return (dispatch) => {

        dispatch(updateImportantWorkActivityRequest());
        
        
        
        axios.post('/updateImportantWorkActivity', data)
        .then(response => {
            const workactivity = response.data
            dispatch(updateImportantWorkActivitySuccess(workactivity))
            // dispatch(fetchWorkActivity({route: "", userId : data.userId}))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateImportantWorkActivityFailure(errorMsg))
        })
        
    }

}
