import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { app, db } from "../../firebase";
import { collection,getDocs,getDoc, query, where, onSnapshot, doc} from "firebase/firestore";
import Post from 'components/Community/Post';
import {
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    ListGroupItem,
   
} from "reactstrap";
const SavedPost = (props) => {
    
    const [savedPostArr, setSavePost] = useState([]);
    const [postId, setPostId] = useState(props.item.postid);
    const [postItem, setPostITem] = useState([]);

     //Modal Behaviour 
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);

    useEffect(() => {
        const fetchSavePost = async () => {
            const docRef = doc(db, "communityPosts", props.item.postid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setSavePost(docSnap.data())
                // console.log("Document data:", docSnap.data());
            } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
            }
        }
        fetchSavePost()

    }, [])

    const truncate = (str,n) => {
        return (str.length > n) ? str.slice(0, n-1) + ' ...' : str;
    }

       //On Click View Post
    const onClickViewPost = async (e, postid, item) => {
        const docRef = doc(db, "communityPosts", postid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let postitem = docSnap.data();
            postitem['postid'] = postid
            setPostITem(postitem)
        } else {
        // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }
        e.preventDefault();
        setPostId(postid)
        setBackdrop(true);
        setModal(true)
        
    
    }

    return (
         
        <>
            {
                savedPostArr ?
                    <>
                        {
                            savedPostArr.textContent
                                ?
                                <ListGroupItem
                                    onClick={e => onClickViewPost(e, postId, savedPostArr)}
                                    tag="a"
                                    style={{ cursor:'pointer' }}
                                >
                                  
                                    {truncate(savedPostArr.textContent, 45)}
                                </ListGroupItem>
                            : <span>{postId}</span>
                        }
                    </> 
                : ''
            }     

            <Modal isOpen={modal} toggle={modalToggle}  backdrop={backdrop} size="lg">
                <ModalHeader toggle={modalToggle}>{ '#'+postId }</ModalHeader>
                <ModalBody>
                    {
                        postItem ?
                            <Post key={postId} item={postItem} />
                        : ''
                  }
                  
                </ModalBody>
            </Modal>
          
        </>

    )

}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
    }  
}

export default connect(mapStateToProps)(SavedPost)
